.more{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.more-page-mobile-view-master{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 365px;
}


/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.search-input{
    border: none;
    outline: none;
    border-bottom: 1px solid #AEAEAE;
    height: 20px;
    width: 320px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    padding: 10px;
    padding-left: 0;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 128.91%;
}

.search-input::placeholder{
    padding: 5px;
    padding-bottom: 15px;

    
    color: #AEAEAE;
}

.more-page-master-container{
    display: flex;
    justify-content: space-between;

    border: 2px solid #FF5E95;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 20px;

    margin: 0px 10px;
    margin-top: 30px;

    max-width: 366px;
}

.more-page-mobile-view-left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    height: 78%;

    font-family: "DM Sans";
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 20px;
}

.more-page-mobile-view-left-service{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    height: 100%;

    font-family: "DM Sans";
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 20px;
}

.more-page-mobile-view-left-head{
    color: rgba(88, 88, 88, 1);
    font-size: 20px;
}

.more-page-mobile-view-left-content{
    color: rgba(132, 132, 132, 1);
    margin-top: 15px;
}


.more-page-mobile-view-right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 10px;
    align-items: center;
}

.more-mobile-view-icon-1{
    width: 120px;
}

.more-mobile-view-arw{
    width: 100px;
}

.more-containers{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    margin-left: auto;
    margin-right: auto;
    
}

.container-row{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: space-between;
}

.single-container{
    width: 135px;
    height: 135px;

    margin: 0px 10px;

    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon-name{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;

    color: #767676;
    margin-top: 10px;

    padding-left: 15px;
    padding-right: 15px;
}

.more-footer-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 50px;
}

.container-link{
    outline: none;
    text-decoration: none;
    border: none;
}

