.navBar_AppLink {
  border: 2px solid #ff5e95;
  border-radius: 5px;
  text-decoration: none;
  color: #ff5e95;
  padding: 3px 6px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 22px; */
}

.arrowIcon{
  color: white;
}

@media screen and (min-width: 500px){
  .item1{
    display: flex;
    justify-content: start;
    width: 33%;
    padding-left: 20px;
  }
  
  .item2{
    /* display: flex;
    justify-content: center;
    width: 33%; */
    position:relative;
  }
  
  .item3{
    display: flex;
    justify-content: end;
    width: 33%;
    padding-right: 20px;
  }
}


