.home-page-mobile-view-form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "DM Sans";
    border: 2px solid #D3D3D3;
    border-radius: 10px;
    margin-top: 20px;
    position: relative;
}
.home-page-form-bg-1{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
}
.home-page-form-bg-2{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
}
.home-page-form-top-right{
    position: absolute;
    top:-16px;
    right:-16px;
  }
  .home-page-form-bottom-left{
    position: absolute;
    left: -16px;
    bottom: -16px;
  }

.grooming-page-mobile-view-form-container{
    display: flex;
    flex-direction: column;

    align-items: center;
    font-family: "DM Sans";

    border: 2px solid #D3D3D3;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-top: 20px;
    position: relative;

    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    min-width: 335px;
}


.grooming-page-mobile-view-services-container{
    padding: 10px 10px;
}

.mobile-view-grom-form-i{
    font-size: 13px;
}


.grooming-page-mobile-view-form-section-1{
    text-align: center;
    font-size: 20px;
    color: rgba(88, 88, 88, 1);
    padding: 10px 10px;
    width: 100%;
    background-color: rgba(247, 248, 249, 1);
    border-radius: 10px 10px 0px 0px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.grooming-page-mobile-view-form-section-1 div{
    font-size: 22px;
}
.home-page-mobile-view-form-1{
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 94, 149, 1);

    padding: 10px 15px;
    border-radius: 10px 10px 0px 0px;
    font-size: 20px;

    width: 100%;
}   

.home-page-mobile-view-form-2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 20px;

    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    min-width: 340px;
    font-size: 16px;
    color: rgba(88, 88, 88, 1);
}

.home-page-mobile-view-form-2-date-row{
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    margin-top: 10px;
}
.home-page-mobile-view-form-2-date-row div{
    width: 100%;
}

.home-page-mobile-view-form-2-date-row input{
    width: 100%;
}

.walking-section-form-input-phone-mobile{
    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    padding-left: 10px;
}

.walking-section-form-input-phone-mobile::placeholder, .walking-section-form-input-address-mobile::placeholder, .walking-section-form-input-date-mobile::placeholder{
    color: rgba(131, 145, 161, 0.5);
    padding-left: 10px;
}

.walking-section-form-input-address-mobile{
    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    padding-left: 10px;
}

.home-page-mobile-view-form-2-address{
    margin-top: 10px;
    width: 100%;
}

.walking-section-form-input-date-mobile{
    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 5px;
    height: 35px;
    color: rgba(88, 88, 88, 1);
}

.mobile-view-home-page-dropdown-container{
    background-color: rgba(164, 164, 164, 0.8);
    font-family: "DM Sans";
    font-size: 19px;
    width: 100%;
    padding: 5px 10px;
    border-radius: 0px 0px 10px 10px;
    border-bottom: 1px solid rgba(211, 211, 211, 1);
    position: absolute;
    top: 46px;
}

.mobile-view-home-page-dropdown-container{
    background-color: rgba(164, 164, 164, 0.8);
    font-family: "DM Sans";
    font-size: 19px;
    width: 100%;
    padding: 5px 10px;
    border-radius: 0px 0px 10px 10px;
    border-bottom: 1px solid rgba(211, 211, 211, 1);
    position: absolute;
    top: 46px;
}

.home-page-form-mobile-view-dropdown-arrow{
    position: relative;
}

.home-page-form-mobile-view-dropdown-arrow-img{
    position: absolute;
    right: -7px;
    bottom: -11px;
}

.grooming-page-form-mobile-view-dropdown-arrow-img{
    position: absolute;
    right: -7px;
    bottom: -10px;
}

.training-page-form-mobile-view-dropdown-arrow-img{
    position: absolute;
    right: -7px;
    bottom: -11px;
}

.walking-page-form-mobile-view-dropdown-arrow-img{
    position: absolute;
    right: -7px;
    bottom: -10px;
}

.mobile-view-home-page-dropdown-point{
    border-radius: 10px;
    padding-top: 10px;
    width: 100%;
    padding-bottom: 5px;
    background-color: white;
    margin: 5px 0px;
    padding-left: 10px;
    color: black;
}

.mobile-view-home-page-dropdown-point-grom{
    border-radius: 10px;
    padding-top: 10px;
    width: 100%;
    padding-bottom: 5px;
    background-color: white;
    margin: 5px 0px;
    padding-left: 10px;
    color: black;
}

.home-page-mobile-view-form-2-phone-small-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: rgba(88, 88, 88, 1);
}

.home-page-walking-form-mobile-feedback{
    color: red;
    font-size: 12px;
}

.home-page-mobile-view-form-2-phone{
    width: 100%;
}

.home-page-mobile-view-form-phone{
    width: 100%;
    margin-top: 10px;
}

.home-page-mobile-view-form-3-phone{
    width: 100%;
    margin-top: 10px;
}


.training-page-mobile-view-form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "DM Sans";
    margin-top: 20px;
    border: 2px solid #D3D3D3;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    position: relative;
}
.training-page-mobile-view-form-header{
    background-color: #F7F8F9;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    font-size: 20px;
    padding: 10px 15px;
    text-align: center;
    color: rgba(88, 88, 88, 1);
}

.walking-page-mobile-view-form-header{
    background-color: #F7F8F9;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    padding: 10px 15px;
    text-align: center;
}

.walking-page-mobile-view-form-header-head{
    color: rgba(88, 88, 88, 1);
    font-size: 20px;

}

.walking-page-mobile-view-form-header-subhead{
    color: rgba(132, 132, 132, 1);
    font-size: 10px;
}

.training-page-mobile-view-form-phone-container{
    color: rgba(88, 88, 88, 1);
    padding: 10px 20px;
}

.training-page-mobile-view-form-phone-container-1{
    color: rgba(88, 88, 88, 1);
    padding: 10px 0px;
}

.training-page-mobile-view-input{
    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 5px;
    width: 100%;
    height: 40px;
    margin-top: 5px;
    padding-left: 10px;
    color: rgba(88, 88, 88, 1);
}

.training-page-mobile-view-input::placeholder{
    color: rgba(211, 212, 214, 0.5);
    padding-left: 0px;
    /* background-color: red; */
}

.training-page-mobile-view-form-footer{
    font-size: 14px;
    color: rgba(255, 94, 149, 1);
    text-align: center;
    margin-top: 10px;
}

.training-page-mobile-view-phone-check-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.training-page-mobile-view-form-button{
    display: flex;
    align-items: center;
    justify-content: center;
}

.pet-name-container {
    display: flex;
    align-items: center;
}
.pet-name-container img {
    width: 15px;
    height: 15px;
    margin-left: 2px;
}
.grooming-page-mobile-view-service-form{
    font-family: "DM Sans";
    color: #585858;
    margin-top: 10px;
    position: relative;
}

.grooming-page-mobile-2-view-service-form{
    font-family: "DM Sans";
    color: #585858;
    position: relative;
    width: 100%;
    margin-top: 10px;
}

.grooming-page-desktop-view-service-form{
    font-family: "DM Sans";
    color: rgba(88, 88, 88, 1);
    margin-top: 10px;
    position: relative;
    width: 100%;
}

.grooming-popup-page-desktop-view-service-form{
    font-family: "DM Sans";
    color: rgba(88, 88, 88, 1);
    margin-top: 10px;
    position: relative;
    width: 100%;
    margin-top: 40px;
}

.groming-page-mobile-view-services-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 5px;
    padding: 5px 10px;
    color: rgb(88, 88, 88);
    width: 100%;
}

.groming-page-desktop-view-services-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 10px;
    padding: 7px 10px;
    color: rgb(88, 88, 88);
}

.groming-page-desktop-view-services-container:hover{
    cursor: pointer;
}


.grooming-page-mobile-view-service-drop-container{
    position: absolute;
    border: 1px solid rgba(232, 236, 244, 1);
    background-color: rgba(164, 164, 164, 0.8);
    z-index: 100;
    padding: 5px 10px;
    border-radius: 0px 0px 10px 10px
}
.grooming-popup-page-mobile-view-service-drop-container{
    position: absolute;
    border: 1px solid rgba(232, 236, 244, 1);
    background-color: rgba(164, 164, 164, 0.8);
    z-index: 100;
    padding: 5px 10px;
    border-radius: 0px 0px 10px 10px;
    width: 100%;
}

.grooming-page-mobile-view-drop-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: black;
    margin: 5px 0px;
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px 5px;
}

.grooming-page-desktop-view-drop-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: black;
    margin: 5px 0px;
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px 5px;
}

.grooming-page-desktop-view-drop-item:hover{
    cursor: pointer;
}

.grooming-page-mobile-view-drop-item-price{
    margin-left: 5px;
}

.grooming-page-desktop-page-demo-image{
    width: 300px;
    border-radius: 10px;
    
}

.grooming-page-desktop-page-demo-image:hover{
    box-shadow:  10px 0px 25px rgba(201, 201, 201, 0.7);
    width: 310px;
}

.grooming-page-desktop-view-grooming-form-pet-image{
    margin-top: 10px;
}

.grooming-page-mobile-view-booking-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.grooming-page-mobile-view-book-disc{
    color: rgba(255, 35, 35, 1);
    font-size:12px;
    text-align: center;
    margin-top: 10px;
    font-weight: 700;
}

.grooming-page-mobile-view-service-form-footer{
    color:  #FF5E95;
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
}

.boarding-page-mobile-view-date-container{
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
    flex-direction: column;
    gap:20px;
}

.boarding-page-mobile-view-date-container div{
    width:93%;
    margin-left:6px;
}
.boarding-page-mobile-view-date-container input{
    width:100%;
}

.home-page-mobile-view-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "DM Sans";
    border: 2px solid #D3D3D3;
    border-radius: 10px;
    margin-top: 20px;
    position: relative;
}

.home-page-form-bg-1 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.home-page-form-bg-2 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
}

.home-page-form-top-right {
    position: absolute;
    top: -16px;
    right: -16px;
}

.home-page-form-bottom-left {
    position: absolute;
    left: -16px;
    bottom: -16px;
}

.grooming-page-mobile-view-form-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    font-family: "DM Sans";

    border: 2px solid #D3D3D3;
    border-radius: 10px;

    margin-top: 20px;
    position: relative;

    background-color: rgba(255, 255, 255, 1);
    width: 95%;
    min-width: 335px;
}

.grooming-page-mobile-view-form-container-home-page{
    display: flex;
    flex-direction: column;

    align-items: center;
    font-family: "DM Sans";
    position: relative;

    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    min-width: 335px;
}

.grooming-page-mobile-view-form-container-home-page {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    font-family: "DM Sans";

    position: relative;

    background-color: rgba(255, 255, 255, 1);
    /* width: 95%; */
    width: 100%;
    min-width: 335px;
}

.grooming-page-mobile-view-services-container {
    padding: 10px;
    width: 100%;
}

.mobile-view-grom-form-i {
    font-size: 13px;
}

.grooming-page-mobile-view-packages-margin {
    /* margin-top: 90px; */
    padding-top: 50px;
}

.grooming-page-mobile-view-form-section-1 {
    font-weight: 700;
    font-size: 7.2vw;
    color: #727272;
    text-align: center;
    width: 100%;
    padding: 10px 20px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.grooming-page-mobile-view-form-section-1 p {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    text-align: center;
    line-height: 10px;
}

/* ------------------newly added styles starts here------------------ */

.grooming-page-mobile-view-form-section-1 span {
    font-size: 7.2vw;
    color: #FF5E95;
    text-transform: uppercase;
}

.grooming-page-mobile-view-services-container .select-service {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.grooming-page-mobile-view-services-container .select-service span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
}

.grooming-page-mobile-view-services-container .select-service .options-box {
    background-color: #F7F8F9;
    border: 1px solid #E8ECF4;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.8s ease-in-out;
    width: 100%;
}

.select-service .options-box .option .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #5E5E5E;
    padding: 5px 0;
}

.heading .left {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: "1rem";
    gap: 10px;
}

.heading .right {
    /* width: 30%; */
    /* padding-right: 2rem; */
    
}

.selection-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #E7E7E7;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.selection-circle .dot {
    display: inline-block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: #FF5E95;

}

.select-service .options-box .option .desc {
    color: #8391A1;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    margin-top: -15px;
}

.grooming-page-mobile-view-services-container .dateandtime {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
}

.dateandtime .select-date {
    width: 100%;
}

.dateandtime .select-date input {
    /* padding: 0 10px; */
    width: 100%;
    height: 56px;
    padding:  8px;
    color: #8391A1;
    outline: none;
    border: none;
    background: #F7F8F9;
    border: 1px solid #E8ECF4;
    border-radius: 8px;
}

.dateandtime .select-slot {
    width: 100%;
}

.dateandtime .select-slot select {
    width: 100%;
    height: 56px;
    padding: 0 8px;
    color: #8391A1;
    outline: none;
    border: none;
    background: #F7F8F9;
    border: 1px solid #E8ECF4;
    border-radius: 8px;
}

.second-form-section {
    width: 85%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.second-form-section ::placeholder {
    padding-left: 0px;
}

.second-form-section .name {
    width: 100%;
}

.name .label span,
.phone .label span,
.enterotp span,
.address .label span {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 125%;
    color: #727272;
}

.name input[type="text"],
.phone input[type="number"],
.address input[type="text"] {
    width: 100%;
    padding: 10px;
    height: 56px;
    margin-top: 5px;
    background-color: #F7F8F9;
    outline: none;
    border: 1px solid #E8ECF4;
    border-radius: 8px;
}

.phone .number-box input[type="number"] {
    width: 60%;
}

.phone .number-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.number-box button,
.enterotp button {
    outline: none;
    height: 56px;
    background: #F7F8F9;
    border: 1px solid rgba(131, 145, 161, 0.5);
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 125%;
    padding: 8px 10px;
    color: #727272;
}

.enterotp input[type="number"] {
    width: 40%;
    margin-right: 10px;
}

.button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.button-group button {
    width: 100%;
    height: 56px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    border-radius: 8px;
    outline: none;
    border: 2px solid #DEC269;
}

.button-group .next {
    margin: 20px 0 ;
    /* color: #ffff; */
    /* background: rgb(222,194,105); */
    /* background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    #DEC269; */
    background: linear-gradient( 132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100% ), rgb(222,194,105);
}

.button-group .pay-now {
    /* color: #ffff; */
    background: rgb(222,194,105);
}
.pay-now-disabled{
    color: #ffff;
    background: rgb(226, 226, 226);
}
.button-group .pay-later {
    color: rgb(222,194,105);
    background-color: #ffff;
}

.order-placed-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    font-family: "DM Sans";
    border: 2px solid #D3D3D3;
    border-radius: 10px;
    margin-top: 20px;
    position: relative;
    background-color: #ffffff;
    width: 95vw;
}

.order-placed-container-home-page {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    font-family: "DM Sans";
    position: relative;
    background-color: #ffffff;
    width: 95vw;
}

.grooming-form-tagline-text-mobile-view{
    color: black;
    font-size: 13px;
}   

.order-placed-container-home-page .container {
    width: 90%;
}

.order-placed-container-home-page .first-box {
    background-color: #F5F7FB;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 20px;
}

.order-placed-container .container {
    width: 90%;
}

.order-placed-container .first-box {
    background-color: #F5F7FB;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 20px;
}

.first-box span {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #3F414E;
}

.first-box .middle {
    width: 80%;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: #616161;
}

.second-box {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffff;
}

.second-box .left {
    display: flex;
    flex-direction: column;
    width: 50%;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #3F414E;
}

.second-box .right {
    height: 108px;
    width: 108px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #d9d9d980;
    border-radius: 20px;
}

.right span {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    width: 61px;
    height: 24px;
    text-align: center;
    color: #727272;
}

.second-box .right img {
    display: block;
    width: 60px;
    height: 60px;
}

.third-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #ffff;
    gap: 5px;
}

.tick img {
    display: block;
    width: 52px;
    height: 52px;
}

.third-box p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    /* or 133% */

    text-align: center;

    color: #3F414E;
}

.third-box .download {
    border: 2px solid #DEC269;
    border-radius: 8px;
    width: 331px;
    height: 56px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    text-align: center;
    color: #DEC269;
    background-color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.third-box .button-group {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.button-group a {
    width: 50%;
}

.button-group img {
    display: inline-block;
    width: 135.97px;
    height: 40.06px;
}

.form-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 50px 0;
}

.form-footer img {
    display: block;
    width: 170px;
    height: 50px;
}

.form-footer .copyright {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    color: #5D5D5D;
}

/* ------------------newly added styles ends here------------------ */

.grooming-page-mobile-view-form-2-phone-small-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: rgba(88, 88, 88, 1);
}

.home-page-mobile-view-form-1 {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 94, 149, 1);

    padding: 10px 15px;
    border-radius: 10px 10px 0px 0px;
    font-size: 20px;

    width: 100%;
}

.home-page-mobile-view-form-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 20px;

    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    min-width: 340px;
    font-size: 16px;
    color: rgba(88, 88, 88, 1);
}

.home-page-mobile-view-form-2-date-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    margin-top: 10px;
}

.home-page-mobile-view-form-2-date-row div {
    width: 100%;
}

.home-page-mobile-view-form-2-date-row input {
    width: 100%;
}

.walking-section-form-input-phone-mobile {
    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    padding-left: 10px;
}

.walking-section-form-input-phone-mobile::placeholder,
.walking-section-form-input-address-mobile::placeholder,
.walking-section-form-input-date-mobile::placeholder {
    color: rgba(131, 145, 161, 0.5);
    padding-left: 10px;
}

.walking-section-form-input-address-mobile {
    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    padding-left: 10px;
}

.home-page-mobile-view-form-2-address {
    margin-top: 10px;
    width: 100%;
}

.walking-section-form-input-date-mobile {
    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 5px;
    height: 35px;
    color: rgba(88, 88, 88, 1);
}

.mobile-view-home-page-dropdown-container {
    background-color: rgba(164, 164, 164, 0.8);
    font-family: "DM Sans";
    font-size: 19px;
    width: 100%;
    padding: 5px 10px;
    border-radius: 0px 0px 10px 10px;
    border-bottom: 1px solid rgba(211, 211, 211, 1);
    position: absolute;
    top: 46px;
}

.mobile-view-home-page-dropdown-container {
    background-color: rgba(164, 164, 164, 0.8);
    font-family: "DM Sans";
    font-size: 19px;
    width: 100%;
    padding: 5px 10px;
    border-radius: 0px 0px 10px 10px;
    border-bottom: 1px solid rgba(211, 211, 211, 1);
    position: absolute;
    top: 46px;
}

.home-page-form-mobile-view-dropdown-arrow {
    position: relative;
}

.home-page-form-mobile-view-dropdown-arrow-img {
    position: absolute;
    right: -7px;
    bottom: -11px;
}

.grooming-page-form-mobile-view-dropdown-arrow-img {
    position: absolute;
    right: -7px;
    bottom: -10px;
}

.training-page-form-mobile-view-dropdown-arrow-img {
    position: absolute;
    right: -7px;
    bottom: -11px;
}

.walking-page-form-mobile-view-dropdown-arrow-img {
    position: absolute;
    right: -7px;
    bottom: -10px;
}

.mobile-view-home-page-dropdown-point {
    border-radius: 10px;
    padding-top: 10px;
    width: 100%;
    padding-bottom: 5px;
    background-color: white;
    margin: 5px 0px;
    padding-left: 10px;
    color: black;
}

.mobile-view-home-page-dropdown-point-grom {
    border-radius: 10px;
    padding-top: 10px;
    width: 100%;
    padding-bottom: 5px;
    background-color: white;
    margin: 5px 0px;
    padding-left: 10px;
    color: black;
}

.home-page-mobile-view-form-2-phone-small-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: rgba(88, 88, 88, 1);
}

.home-page-walking-form-mobile-feedback {
    color: red;
    font-size: 12px;
}

.home-page-mobile-view-form-2-phone {
    width: 100%;
}

.home-page-mobile-view-form-phone {
    width: 100%;
    margin-top: 10px;
}

.home-page-mobile-view-form-3-phone {
    width: 100%;
    margin-top: 10px;
}


.training-page-mobile-view-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "DM Sans";
    margin-top: 20px;
    border: 2px solid #D3D3D3;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    position: relative;
}

.training-page-mobile-view-form-header {
    background-color: #F7F8F9;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    font-size: 20px;
    padding: 10px 15px;
    text-align: center;
    color: rgba(88, 88, 88, 1);
}

.walking-page-mobile-view-form-header {
    background-color: #F7F8F9;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    padding: 10px 15px;
    text-align: center;
}

.walking-page-mobile-view-form-header-head {
    color: rgba(88, 88, 88, 1);
    font-size: 20px;

}

.walking-page-mobile-view-form-header-subhead {
    color: rgba(132, 132, 132, 1);
    font-size: 10px;
}

.training-page-mobile-view-form-phone-container {
    color: rgba(88, 88, 88, 1);
    padding: 10px 20px;
}

.training-page-mobile-view-form-phone-container-1 {
    color: rgba(88, 88, 88, 1);
    padding: 10px 0px;
}

.training-page-mobile-view-input {
    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 5px;
    width: 100%;
    height: 40px;
    margin-top: 5px;
    padding-left: 10px;
    color: rgba(88, 88, 88, 1);
}

.training-page-mobile-view-input::placeholder {
    color: rgba(131, 145, 161, 0.5);
    padding-left: 0px;
}

.training-page-mobile-view-form-footer {
    font-size: 14px;
    color: rgba(255, 94, 149, 1);
    text-align: center;
    margin-top: 10px;
}

.training-page-mobile-view-phone-check-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.training-page-mobile-view-form-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.grooming-page-mobile-view-service-form {
    font-family: "DM Sans";
    color: #585858;
    margin-top: 10px;
    position: relative;
}

.grooming-page-mobile-2-view-service-form {
    font-family: "DM Sans";
    color: #585858;
    position: relative;
    width: 100%;
    margin-top: 10px;
}

.grooming-page-desktop-view-service-form {
    font-family: "DM Sans";
    color: rgba(88, 88, 88, 1);
    margin-top: 10px;
    position: relative;
    width: 100%;
}

.grooming-popup-page-desktop-view-service-form {
    font-family: "DM Sans";
    color: rgba(88, 88, 88, 1);
    margin-top: 10px;
    position: relative;
    width: 100%;
    margin-top: 40px;
}

.groming-page-mobile-view-services-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 5px;
    padding: 5px 10px;
    color: rgb(88, 88, 88);
    width: 100%;
}

.groming-page-desktop-view-services-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 10px;
    padding: 7px 10px;
    color: rgb(88, 88, 88);
}

.groming-page-desktop-view-services-container:hover {
    cursor: pointer;
}


.grooming-page-mobile-view-service-drop-container {
    position: absolute;
    border: 1px solid rgba(232, 236, 244, 1);
    background-color: rgba(164, 164, 164, 0.8);
    z-index: 100;
    padding: 5px 10px;
    border-radius: 0px 0px 10px 10px
}

.grooming-popup-page-mobile-view-service-drop-container {
    position: absolute;
    border: 1px solid rgba(232, 236, 244, 1);
    background-color: rgba(164, 164, 164, 0.8);
    z-index: 100;
    padding: 5px 10px;
    border-radius: 0px 0px 10px 10px;
    width: 100%;
}

.grooming-page-mobile-view-drop-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: black;
    margin: 5px 0px;
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px 5px;
}

.grooming-page-desktop-view-drop-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: black;
    margin: 5px 0px;
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px 5px;
}

.grooming-page-desktop-view-drop-item:hover {
    cursor: pointer;
}

.grooming-page-mobile-view-drop-item-price {
    margin-left: 5px;
}

.grooming-page-desktop-page-demo-image {
    width: 300px;
    border-radius: 10px;

}

.grooming-page-desktop-page-demo-image:hover {
    box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
    width: 310px;
}

.grooming-page-desktop-view-grooming-form-pet-image {
    margin-top: 10px;
}

.grooming-page-mobile-view-booking-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grooming-page-mobile-view-book-disc {
    color: rgba(255, 35, 35, 1);
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
    font-weight: 700;
}

.grooming-page-mobile-view-service-form-footer {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 125%;
    text-align: center;
    color: #727272;
    width: 80%;
}

.boarding-page-mobile-view-date-container {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
    flex-direction: column;
    gap: 20px;
}

.boarding-page-mobile-view-date-container div {
    width: 93%;
    margin-left: 6px;
}

.boarding-page-mobile-view-date-container input {
    width: 100%;
}