.terms-master{
    font-family: "DM Sans";
    color: rgba(132, 132, 132, 1);
    font-size: 14px;
}

.terms-section-1{
    padding: 0px 20px;
}

.terms-page-mobile-view-head{
    color: rgba(88, 88, 88, 1);
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 18px;
}

.terms-content{
    margin: 15px 0px;
    font-weight: 400;
}

.terms-content-1{
    margin: 15px 20px;
    font-weight: 400;
}

.terms-content-sub{
    font-size: 16px;
    color: rgba(88, 88, 88, 1);
}