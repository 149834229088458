.order-summary-desktop-master-container{
    padding: 40px 100px;
    
    font-family: "DM Sans";

    height: 100vh;
    display: flex;
    flex-direction: column;
}

.order-summary-desktop-petmojo-head-image{
    width: 200px;
}

.order-summary-desktop-grey-container{
    background-color: rgba(245, 247, 251, 1);

    border-radius: 20px;

    display: flex;
    justify-content: space-between;
    padding: 40px 40px;
    align-items: center;
    font-size: 18px;

    color: rgba(63, 65, 78, 1);
}

.order-summary-desktop-grey-dog-image{
    width: 300px;
}

.order-summary-desktop-grey-left-order-heading{
    font-size: 32px;
    color: rgba(97, 97, 97, 1);
    font-weight: 700;

    margin-top: 10px;
}

.order-summary-desktop-grey-left{
    height: 100%;
}

.order-summary-desktop-grey-left-order-details-container{
    margin-top: 20px;
}

.order-summary-desktop-white-container{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    /* align-items: center; */

    font-size: 18px;
    color: rgba(63, 65, 78, 1);

    /* padding: 0px 100px; */
}

.order-summary-desktop-white-container-right{
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    
}
.order-summary-desktop-white-container-left{
    padding-left: 2rem;
    padding-top: 2rem;
}
.order-summary-desktop-white-container-left-address{
    width: 250px;
    margin-top: 20px;
}

.order-summary-desktop-white-container-left-download-button{
    background: #DEC269;
    padding: 10px 20px;
    text-align: center;
    color: white;

    font-weight: bold;
    border-radius: 10px;

    margin-top: 20px;

    background: linear-gradient( 132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100% ), rgb(222,194,105);
  transition: 300ms all;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;
    animation-name: shine;
    animation-fill-mode: forwards;
    color: black;
}

.order-summary-desktop-white-container-left-download-button:hover{
    cursor: pointer;
}

.tick-image-desktop-summary{
    width: 50px;
}

.order-summary-desktop-master-container-bottom{
    margin-top: 25px;
    padding: 20px 90px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* height: 100%; */
}

.order-summary-mobile-master-container{
    /* padding: 20px 20px; */
    font-family: "DM Sans";

    color:rgba(114, 114, 114, 1);
    font-size: 15px;

    font-weight: 100;
}

.order-summary-mobile-grey-container{
    background-color: rgba(245, 247, 251, 1);
    border-radius: 10px;
    padding: 30px 40px;
}

.order-summary-mobile-grey-heading{
    font-size: 20px;
    font-weight: 700;
    color: rgba(97, 97, 97, 1);
    margin-top: 10px;
}

.order-summary-mobile-grey-container-details-container{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.order-summary-dog-image{
    width: 100px;
}

.order-summary-mobile-white-container{
    margin-top: 20px;
    color: rgba(63, 65, 78, 1);
    font-weight: 700;

    padding: 0px 10px;
}

.billing-address-ad{
    margin-top: 10px;
    color: rgba(63, 65, 78, 1);
    width: 170px;
    font-weight: 100;
}

.order-confirmation-mobile-summary-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    margin-top: 20px;
}

.order-confirmation-mobile-summary-image{
    width: 40px;
}

.order-summary-mobile-download-button{
    background-color: #DEC269;
    border-radius: 5px;
    padding: 10px 20px;
    text-align: center;
    color: white;
    margin-top: 20px;
    font-weight: 700;
    font-size: 17px;
}

.order-summary-mobile-footer-logo{
    width: 150px;
}

.order-summary-mobile-footer-container{
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 50px;
}

.order-summary-mobile-footer-text{
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
}

.copy-icon-desktop{
    width: 20px;
    margin-left: 10px;
}

.copy-icon-desktop:hover{
    cursor: pointer;
    transform: scale(1.2);
}

.copy-icon-mobile{
    width: 17px;
    margin-left: 10px;
}

.copy-popup-text{
    font-size: 14px;
    margin-left: 5px;
}

.lowerSum{
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
}
.leftLowerSum{
flex: 0.30;
}
.midLowerSum{
    flex: 0.30;
    
}
.rightLowerSum{
    flex: 0.4;
}