@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    margin: 0;
    width: 100vw;
    height: 100vh;
}
