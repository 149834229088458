.training {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: relative;

  position: absolute;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 1);
}

.training-desktop {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: relative;
  /* position: absolute; */

  position: absolute;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

}

.socials-desktop{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: relative;
  /* position: absolute; */

  position: absolute;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.training-btn {
  display: flex;
  justify-content: left;
  align-items: left;
  margin-left: 25px;
  padding-left: 20px;
}

.train-btn {
  margin: 0;
  padding: 0;
}

.intro-picture {
  display: flex;
  justify-content: center;
  text-align: center;
}


.foot-note {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.foot-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 25px; */
  color: #575757;
  padding: 10px;
  padding-left: 5px;
}

.foot-icon {
  padding: 10px;
  padding-right: 5px;
  vertical-align: middle;
}

.foot-icon-landing {
  padding-right: 5px;
  vertical-align: top;

}

.total-sessions {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  text-align: center;

  color: #242424;

  margin-bottom: -20px;
}

.bording-page-mobile-training-section2{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bording-page-mobile-training-section3{
  margin-top: 20px;
}

.boarding-page-mobile-standard-sub-heading{
  font-size: 18px;
  text-align: center;
}

.boarding-page-mobile-standard-container-mobile{
  position: absolute;
  top: 70px;

  font-size: 18px;

  padding: 0px 40px;
  text-align: center;
}

.boarding-page-mobile-standard-container{
  margin-top: 0px;
  position: relative;
}

.boarding-page-mobile-standard-container-img{
  transform: scale(0.8);
}

.bording-page-mobile-training-section2-head{
  font-size: 25px;
  font-weight: 500;
}

.bording-page-mobile-training-section2-head2{
  font-size: 17px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tracking-text {
  margin-bottom: 5px;
  padding-bottom: 0;

  padding: 0 !important;
}
.tracking-container {
  display: flex;
  flex-direction: row;
}

.tracking-info {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.tracking-points {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #5e5e5e;
}

ul {
  padding-left: 20px;
  margin-left: 10px;
}

.tracking-icon {
  width: 200px;
  height: 290px;
}

@media only screen and (max-width: 400px) {
  .pet-trainer-meet-icon {
    width: 100%;
  }
  .services-icon {
    width: 100%;
    height: 100%;
  }
  .privacy-image {
    width: 100%;
    height: 100%;
  }
  .youtube-video {
    width: 100%;
  }
  /* .training-popup{
      width: 100%;
    } */

  .training-intro {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .pet-trainer-meet-icon {
    width: 100%;
  }
  .services-icon {
    width: 100%;
    height: 100%;
  }
  .youtube-video {
    width: 100%;
  }
  .privacy-image {
    width: 100%;
    height: 100%;
  }
  /* .training-popup{
      width: 100%;
    } */
  .training-intro {
    width: 95%;
    margin-left: 40px;
  }
}


.subtext-text{
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 600;

  text-align: center;
  color: #5e5e5e;

  margin-top: 5px;
}

.trainer-meet {
  position: relative;
  min-height: 420px;
  display: flex;

  margin-left: auto;
  margin-right: auto;
  margin-top: 15%;
}


.meet-content {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pet-trainer-meet-icon {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.meet-heading {
  position: absolute;
  top: 40px;
  min-width: 304px;
  max-width: 364px;
  min-height: 99px;

  background: rgba(255, 94, 149, 0.5);
  backdrop-filter: blur(25px);
  border-radius: 10px;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.meet-heading2 {
  position: absolute;
  top: 40px;
  min-width: 304px;
  max-width: 364px;
  min-height: 99px;

  background: rgba(255, 94, 149, 0.5);
  backdrop-filter: blur(25px);
  border-radius: 10px;

  margin-left: 5%;
  margin-right: 5%;
  left: 0;
  right: 0;
  text-align: center;
}

.meet-title2-subhead{
  color: white;
  font-family: "DM Sans";

  display: flex;
  justify-content: end;

  padding-right: 10%;
}

.meet-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 22px;
  /* identical to box height, or 81% */

  text-align: center;

  color: #ffffff;
  padding: 20px;
  padding-bottom: 10px;
}

.meet-title2{
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 22px;
  /* identical to box height, or 81% */

  text-align: center;

  color: #ffffff;
  padding: 20px;
  padding-bottom: 10px;
}

.meet-subtitle {
  position: absolute;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  text-align: center;

  color: #ffffff;
  right: 25px;
}

.meet-info {
  position: absolute;
  top: 170px;
  min-width: 304px;
  max-width: 364px;

  background: rgba(255, 94, 149, 0.5);
  backdrop-filter: blur(25px);
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  color: #ffffff;

  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;

  margin-bottom: 10px;
}

.meet-info2 {
  position: absolute;
  top: 170px;
  min-width: 304px;
  max-width: 364px;

  background: rgba(255, 94, 149, 0.5);
  backdrop-filter: blur(25px);
  border-radius: 10px;
  margin-left: 5%;
  margin-right: 5%;
  left: 0;
  right: 0;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  color: #ffffff;

  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;

  margin-bottom: 10px;
}

.training-text {
  margin-top: 10px;
}

.info-title {
  padding-left: 20px;
  font-size: 17px;
}

.youtube-container {
  margin-top: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 10px solid rgba(255, 229, 229, 1);
  margin: 0px 10px;
}

.pet-parent-meet-mobile-view{
  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/train-rect-trainers.png");
  background-repeat: no-repeat;
  background-position: cover;

  margin-top: 40px;
  color: white;
  font-family: "DM Sans";

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 20px 20px;
}

.pet-parent-meet-mobile-view-container-1-subhead{
  width: 100%;
}

.pet-parent-meet-mobile-view-container-1{
  width: 100%;
}

.pet-parent-meet-mobile-view-container-1-head{
  text-align: center;
  font-size: 27px;
  font-weight: 500;
}

.pet-parents-meet-mobile-view-cont{
  position: relative;
}

.ul-list{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.li-list{
  font-size: 14px;
  padding: 3px;
  text-align: left;
}

.pet-parent-meet-mobile-view-container-2-head{
  font-size: 17px;
}

.pet-parent-meet-mobile-view-container-1-subhead{
  text-align: end;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 14px;
}

.youtube-video {
  height: 215px;
}

@media screen and (min-width: 700px){
  .youtube-container{
    transform: scale(1.6);
    margin-bottom: 50px;
  }
  
}

.textbox3 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #8d8d8d;
  padding: 20px;
}

.trainer-container {
  display: flex;
  flex-direction: row;

  justify-content: center;
  text-align: center;
}

.trainer-cards {
  position: relative;
  margin: 50px;
}

.trainer-cards-mobile {
  position: relative;
  margin: 10px;
}

.trainer-card {
  position: absolute;
  top: 15px;
  left: 10px;

  width: 110%;
  height: 110%;
}

.trainer-card-mobile {
  position: absolute;
  top: 5px;
  left: 5px;

  width: 110%;
  height: 110%;
}

.trainer-card-desktop {
  position: absolute;
  top: 15px;
  left: 10px;

  width: 180%;
  height: 120%;
}

.privacy-container {
  margin-left: auto;
  margin-right: auto;

  position: relative;
}

.privacy-link {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  color: #e39555;

  position: absolute;
  bottom: 35px;
  left: 80px;
}

.terms-link {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  color: #6d5f5b;
  position: absolute;
  bottom: 70px;
  right: 75px;
}

.foot-notes {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;

  /* position: absolute;
  top:60%;
  left: 20%; */
}

.foot-notes-landing-page-mobile {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  /* position: absolute;
  top:60%;
  left: 20%; */
}

.foot-notes-desktop-landing{

}

.foot-notes-desktop-walking {
  display: flex;
  flex-direction: column;
  max-width: 380px;

  position: absolute;
  top: 150px;

  margin-left: -20px;

  /* position: absolute;
  top:60%;
  left: 20%; */
}

.select-box {
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: row;
}

.options {
  width: 100%;
  height: 32px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ff5e95;
}

.check-btn {
  margin-left: 15px;
  width: 90px;
  height: 32px;

  background: #680082;
  box-shadow: 0px 4px 4px rgba(104, 0, 130, 0.25);

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;

  outline: none;
  border: none;
}

.dog-bone-1 {
  height: 50px;
  width: 80px;
}

.dog-bone-2 {
  height: 50px;
  width: 80px;
}

.get-in-touch {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

/* .popup{
  position: fixed;

  top: 50%;
  transform: translateY(-50%);

  margin: auto;
  padding: 0;

  display: flex;
  justify-content: center;
  align-self: center;
  vertical-align: middle;
  z-index: 100;
  
}

.popup-close-btn{
  outline: none;
  border: none;
  background-color: transparent;
  position: fixed;
  top: 20px;
  right: 20px;
  vertical-align: middle;

  padding: 10px;

}

.popup-title{
  position: absolute;
  top: 60px;

  display: flex;
  justify-content: left;

  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 28px;
  color: #FFFFFF;

  padding: 10px;
}

.popup-vector{
  position: absolute;
  top: 70px;

  display: flex;
  justify-content: left;
}

.popup-form{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: left ;
  top: 220px;

  width: 348px;
} */

.form-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
}

.form-email {
  height: 32px;
  border: 2px solid #ff5e95;
  filter: drop-shadow(0px 4px 4px rgba(255, 94, 149, 0.25));
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;

  background-color: transparent;
  color: white;
  outline: none;
}

.form-email::placeholder {
  color: rgba(255, 255, 255, 0.7);
  padding-left: 3px;
}

.form-phonenumber {
  width: 214px;
  height: 32px;
  margin-right: 10px;

  border: 2px solid #ff5e95;
  filter: drop-shadow(0px 4px 4px rgba(255, 94, 149, 0.25));
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;

  background-color: transparent;
  color: white;
  outline: none;
}

.form-phonenumber::placeholder {
  color: rgba(255, 255, 255, 0.7);
  padding-left: 3px;
}

.form-btn {
  width: 124px;
  background: #ff5e95;
  box-shadow: 0px 4px 4px rgba(104, 0, 130, 0.25);
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;

  outline: none;
  border: none;
}

.popup-limited-spots {
  position: absolute;
  top: 350px;

  display: flex;
  justify-content: center;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}

.popup-subtitle {
  position: absolute;
  top: 390px;

  display: flex;
  justify-content: center;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;

  text-align: center;

  color: #ffffff;
}

.popup-footer {
  position: absolute;
  bottom: 30px;

  display: flex;
  justify-content: center;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: center;

  color: #ffffff;
  padding-left: 80px;
  padding-right: 80px;
}

.intro-btn-training {
  position: absolute;
  bottom: 80px;
  left: 10px;
}

.intro-btn-training-train {
  position: absolute;
  bottom: 45%;
  left: 25px;
}




.home-floating-menu {
  position: fixed;
  bottom: -5px;
  width: 100%;
  z-index: 1000;
}

@media only screen and (max-width: 400px) {
  .floating-menu {
    width: 100%;
  }
  .home-floating-menu {
    width: 100%;
  }
  /* .training-intro{
    width: 100%;
  } */
}

@media only screen and (max-width: 500px) {
  .floating-menu {
    width: 100%;
  }
  .home-floating-menu {
    width: 100%;
  }
  /* .training-intro{
    width: 100%;
  } */
}


@media screen and (min-width: 1000px){
  .foot-notes {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  
    margin-top: 20px;
    margin-bottom: 30px;

    position: absolute;
    top:60%;
    left: 10%;
  }
}


@media screen and (min-width: 1300px){
  .foot-notes {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  
    margin-top: 20px;
    margin-bottom: 30px;

    position: absolute;
    top:70%;
    left: 10%;
  }
}



.training-page-package-heading{
  font-family: "DM Sans";
  font-size: 50px;
  color: rgba(88, 88, 88, 1);

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
}

.training-page-desktop-slider-container{
  padding: 60px 120px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.training-page-desktop-package-right{
  width: 60%;
  /* background-color: black; */
}

.training-page-desktop-package-left{
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  position: relative;
  /* background-color: red; */
}

.training-page-yt-container{
  display: flex;
  justify-content: space-between;
  
  padding-left: 120px;
  font-family: "DM Sans";
  color:rgba(88, 88, 88, 1);

  margin-top: 100px;
  margin-bottom: 100px;
  padding-right: 120px;
}

.training-page-yt-left{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.training-page-yt-left-heading{
  position: relative;
}

.grooming-page-trainer-meet-head1{
  position: relative;
}

.training-page-yt-line{
  border: 3px solid rgba(254, 119, 152, 1);
  border-radius: 10px;

  position: absolute;

  width: 150px;

}

.training-page-yt-left-btn{
  margin-top: 70px;
}

.training-page-yt-left-heading{
  font-size: 50px;
}

.training-page-behave-box1{
  font-size: 50px;
  font-family: "DM Sans";
  color: rgba(88, 88, 88, 1);

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;

  margin-bottom: 50px;

  max-width: 450px;
}

.grooming-page-behave-box1{
  font-size: 50px;
  font-family: "DM Sans";
  color: rgba(88, 88, 88, 1);

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;

  margin-bottom: 20px;
}

.training-page-behave-line{
  border: 3px solid rgba(254, 119, 152, 1);
  border-radius: 10px;
  width: 150px;

  position: absolute;
  top: 180px;
}

.training-page-behave-box2{
  font-size: 20px;
  font-family: "DM Sans";
  color: rgba(126, 126, 126, 1);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.grooming-page-behave-box2{
  font-size: 20px;
  font-family: "DM Sans";
  color: rgba(126, 126, 126, 1);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.training-page-behave-box3{
  color: rgba(94, 94, 94, 1);
  font-size: 24px;
  font-family: "DM Sans";
  margin-top: 10px;
}
.slider-training-package-mobile::-webkit-scrollbar{
  display: none;
}
@keyframes scrolldiv {
  0% { transform: translateX(0); }
  100% { transform: translateX(-650%); }
}
@keyframes scrolldiv2 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}
@keyframes scrolldiv3 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-800%); }
}
@keyframes scrolldiv4 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-250%); }
}