
* {
  box-sizing: border-box;
}

.App {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.home-floating-menu {
  position: fixed;
  bottom: -5px;
  width: 100%;
  z-index: 1000;
}

@media only screen and (max-width: 400px) {
  .floating-menu {
    width: 100%;
  }
  .home-floating-menu {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .floating-menu {
    width: 100%;
  }
  .home-floating-menu {
    width: 100%;
  }
}

.floating-icons {
  position: fixed;
  bottom: 110px;
  right: 30px;
  display: flex;
  flex-direction: column;
  z-index: 100;
}

.whatsapp-floating-icon {
  height: 70px;
  width: 70px;

  border: none;
  outline: none;
  background-color: transparent;
  margin-top: 10px;
  margin-bottom: 10px;
}

.call-floating-icon {
  height: 60px;
  width: 80px;
  padding: 10px;
  border: none;
  outline: none;
  background-color: transparent;
/* 
  margin-top: 10px;
  margin-bottom: -32px;
  margin-left: 20px; */



  position: absolute;
  bottom: 10px;
  right: -14px;
}

.call-floating-icon img {
  width: 100%;
  height: 100%;
  /* border-radius: 25%; */
  /* padding: 5px; */
  /* border: 1px solid white; */
  background-color: transparent;

}

.floating-boxes {
  display: flex;
  flex-direction: column;

  position: fixed;
  bottom: 100px;
  right: 100px;
  z-index: 100;
}

.connect-call {
  width: 185px;
  height: 45px;

  position: fixed;
  bottom: 120px;
  right: 100px;
}

.connect-whatsapp {
  width: 185px;
  height: 45px;

  position: fixed;
  bottom: 120px;
  right: 100px;
}

.connect-box-1,
.connect-box-2 {
  border: none;
  outline: none;
  background-color: transparent;
}

.popup-box-mobile{
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
  background: #E0FEFF;
border: 2px solid #DEC269;
border-radius: 10px 10px 0px 10px;
align-items: center;
justify-content: center;
padding: 20px 10px;
margin-right:40px;
transition-duration: 4s;
transition-delay: 1s;
}
.popup-box-mobile h2{
  margin:0;
  font-family: 'DM Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 125%;
display: flex;
align-items: flex-end;
color: #585858;
}
.popup-box-mobile p{
  margin: 0;
  font-family: 'DM Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 125%;
display: flex;
align-items: flex-end;
color: #848484;
}

.popup-cross-mobile{
  position: absolute;
  top:0px;
  right:10px;
  color:gray;
  font-size: 20px;
}
.appstore,.playstore{
  /* animation: jump-shaking 5s ; */
  

}

@keyframes jump-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
}
@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-10%); }
}

.social-page-slider-container::-webkit-scrollbar{
  display: none;
}

video {
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.custom-scrollbar::-webkit-scrollbar {
  background: transparent;
  width: 5px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(113, 111, 112);
  border-radius: 5px;
}