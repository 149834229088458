.blog{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: relative;

    position:absolute;
    padding:0;
    margin:0;

    top:0;
    left:0;

    width: 100%;
    height: 100%;

}

.tabs{
  color: black;
}

.tab-container{
    height: 100%;
    margin-bottom: 0px;
}

.blog-container{
    display: flex;
    flex-direction: column;

    margin-left: auto;
    margin-right: auto;
}

.blog-menu{
    background-color: #fff4f4;
    margin-top: '40px',
}

.blog-box{
    display: flex;
    flex-direction: row;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.blog-info{
    display: flex;
    flex-direction: column;

    margin-left: 10px;
}

.blog-title{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #5E5E5E;
    cursor: pointer;
}

.blog-date{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;

    display: flex;
    align-items: center;
    color: #6E6E6E;

    margin-top: 10px;
}

.nav-menu{
    border: none;
    padding: 0;
    background-color: transparent;
}

.blog-img{
    min-width: 160px;
    height: 110px;
}

.blog-expanded{
    margin-top: -60px;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}

.blog-img-container{
    display: flex;
    height: 100%;
    width: 100%;

}

.blog-outer-container{
}

.blog-inner-featured{
    position: relative;
    bottom: 75%;
    left: 60%;
}

.blog-collapsed-container{
    margin: 0;
    padding: 0;

    flex-direction: column;
    margin-bottom: 30px;
}

.blog-expanded-container{
    position: absolute;
    top: 0;
    margin: 0;
    padding: 0;

    flex-direction: column;
    margin-bottom: 30px;
}

.blog-open-container{
    margin-top: -60px;
    margin-bottom: 20px;
}

.navbar{
    z-index: 1000 ;
}

@media only screen and (max-width: 400px) {
    .blog-img-expanded{
        width: 100%;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .blog-img-expanded{
      width: 100%;
    }
  }

.read-all-btn{

    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #525252;

    width: 92px;
    height: 37px;

    border: none;

    cursor: pointer;
}

.blog-image-expanded{
    z-index: -1;
}

.read-all-btn-container{
    margin-top: -60px;
    margin-left: auto;
    margin-right: auto;

    width: 92px;
    height: 37px; 
}


.blog-preview-date{
    display: flex !important;
    justify-content: right !important; 
    align-items: right !important;
    text-align: right !important;

    padding-right: 20px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    display: flex;
    align-items: center;
    color: #6E6E6E;
}

.blog-preview-container{
    display: flex;
    justify-content: right;
    align-items: right;

    margin-left: auto;
    margin-right: auto;

    flex-direction: column;

    padding: 30px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 40px;
}

.blog-closed-content{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #5E5E5E;

    margin-left: auto;
    margin-right: auto;

    padding: 30px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 40px;

    color: #5E5E5E;
}

.blog-closed-container{
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 30px;
}

.read-more-link{
    color: #000000 !important;
    font-weight: 600;
    padding-left: 5px;
}

.read-more-arrow{
    display: inline !important;
    vertical-align: middle;
    padding-left: 5px;
}

.blog-slider-container{
    display: flex;
    flex-direction: column;
}

.blog-slider-title{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 28px;
    text-align: left;
    padding-left: 30px;

    margin-top: 20px;

    color: #5E5E5E;
}

.blog-slide-container{
    padding-left: 10px;
}

.dog-slide-container{
    display: flex;
    flex-direction: column;

    margin-top: -20px;
}

.dog-slide-text{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    
    color: #FFFFFF;
}

.dog-slide-date{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    
    color: #FFFFFF;

    margin-top: 15px;
}

.blog-img-container{
    position: relative;
}

.featured{
    width: 60.49px;
    height: 19.34px;
}

.return-logo{
    width: 30px;
    height: 30px;
}

.blogs-desktop-view-main-container{
    display: flex;
    flex-direction: row;
    padding: 20px 120px;

    font-family: "DM Sans";

    position: relative;

    width: 100%;
}

.blogs-desktop-view-main-container1{
    display: flex;
    flex-direction: row;
    padding: 20px 120px;

    font-family: "DM Sans";

    position: relative;
    height: 85vh;

    width: 100%;
}

.blogs-desktop-view-main-container-left{
    /* display: flex;
    justify-content: space-between;
    align-items: flex-start; */

    border: 1px solid rgba(49, 49, 49, 0.2);
    padding: 20px 20px;
    border-radius: 10px;

    width: 85%;
    position: relative;

    /* height: 75vh; */
}

.blogs-desktop-view-main-container-left-right1{
    width: 50%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 10px;
}

.blogs-desktop-view-main-container-left1{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    border: 1px solid rgba(49, 49, 49, 0.2);
    padding: 20px 20px;
    border-radius: 10px;

    width: 85%;
    position: relative;
}

.blogs-desktop-view-main-container-left-left{
    width: 45%;
    float: left;

    margin: 0px 30px 20px 0px;
}

.blogs-desktop-view-main-container-left-left1{
    width: 50%;
    margin: 0px 30px 20px 0px;
    height: 100%;
}

.blogs-desktop-view-main-container-left-left1-upper{
    height: 92%;
}

.blogs-desktop-view-main-container-left-left-details{
    width: 50%;

    display: flex;
    flex-direction: column;

    height: 100%;
}

.blogs-desktop-view-main-container-main-image{
    width: 100%;
    border-radius: 10px;
    height: 100%;
}

.blogs-desktop-view-main-container-main-image-container{
    height: 100%;
}

.blogs-desktop-view-main-container-main-time-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 20px;
}

.blogs-desktop-view-main-container-main-views{
    display: flex;
    align-items: center;
}

.blogs-desktop-view-main-container-main-date{
    display: flex;
    align-items: center;
}

.desktop-eye-image, .desktop-calander-image{
    width: 20px;
    
}

.blogs-desktop-view-main-container-main-views-text{
    margin-left: 10px;
    color: #6E6E6E;
}

.blogs-desktop-view-main-container-bottom{
    margin-top: 30px;
}

.blogs-desktop-view-main-container-bottom-head{
    font-size: 25px;
    color: rgba(62, 62, 62, 1);
    font-weight: 500;
}

.blogs-desktop-view-main-container-bottom-content{
    margin-top: 10px;
    color: rgba(88, 88, 88, 1);
}

.blogs-desktop-view-main-container-bottom-continue{
    margin-top: 50px;
    color:rgba(62, 62, 62, 1);
    font-weight: 700;
}

.blogs-continue-desktop-icon{
    width: 30px;
    margin-left: 5px;
    margin-top: 5px;
}

.blogs-desktop-view-main-container-bottom-continue{
    display: flex;
    align-items: center;

    font-size: 17px;
}

.blogs-desktop-view-main-container-bottom-continue:hover{
    cursor: pointer;
}

.blogs-desktop-view-main-container-left-right{
    width: 45%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.blogs-desktop-view-main-container-left-right-item-container{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.blogs-desktop-view-main-container-left-right-item-container:hover{
    cursor: pointer;
}

.blogs-desktop-view-main-container-left-right-item-container-right{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    margin: 10px 0px;
}

.blogs-desktop-view-main-container-left-right-item-image-container{
    width: 45%;
}

.blogs-desktop-view-main-container-left-right-item-image{
    width: 100%;
    border-radius: 10px;
}

.blogs-desktop-view-main-container-left-right-item-content{
    width: 50%;
    color: rgba(62, 62, 62, 1);
}

.blogs-desktop-submai1-date{
    color: rgba(88, 88, 88, 1);

    font-size: 14px;
    margin-top: 10px;
}

.blogs-desktop-view-main-container-right{
    padding: 20px 20px;
    /* height: 100%; */

    border: 1px solid rgba(49, 49, 49, 0.2);
    border-radius: 10px;
    margin-left: 50px;
    width: 45%;

    height: 80vh;
}

.blogs-desktop-view-main-container-right-blogs{
    display: flex;
    flex-direction: column;
    height: 95%;
    overflow-y: scroll;

    padding-right: 10px;
}

.blogs-desktop-view-main-container-right-heading{
    color: rgba(49, 49, 49, 1);
    font-size: 20px;
}

.blogs-page-desktop-view-blog-content-container{
    width: 100%;
    /* height: 100%; */
    /* overflow-y: scroll; */
}

.blogs-page-desktop-view-blog-content-heading{
    font-size: 32px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0px;
    
    color: rgba(62, 62, 62, 1);
    font-weight: 700;
    position: relative;
}

.blogs-page-desktop-view-blog-content-text{
    height: 100%   
}

.Blogs-Mobile-Master{
    font-family: "DM Sans";
}

.mobile-view-detail-blog-page-master-container{
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 20px 20px;
}

.mobile-view-blog-detail-main-heading{
    color: rgba(62, 62, 62, 1);
    font-size: 25px;
}

.mobile-view-blog-detail-image-date-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}
.mobile-view-blog-detail-main-image{
    border-radius: 10px;
    width: 100%;
}

.mobile-view-blog-detail-date-container{
    display: flex;
    justify-content: space-between;

    color: #6E6E6E;
}

.mobile-view-blog-detail-date-container-date{
    display: flex;
    align-items: center;
}

.mobile-view-blog-detail-date-container-cal-icon{
    transform: scale(0.5);
}

.mobile-view-blog-detail-main-content-container{
    margin-top: 10px;
    color: rgba(94, 94, 94, 1);
}

.blog-page-mobile-view-dont-forget-container{
    font-size: 20px;
    font-weight: 500;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 50px;
    color: rgba(62, 62, 62, 1);
}

.blog-page-mobile-view-dont-forget-image-container{
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 20px;
}

.blog-page-mobile-view-social-icon{
    width: 40px;
    margin: 0px 10px;
}

.blog-page-mobile-view-related-articles-line{
    margin:  10px 20px;
}

.blogs-page-mobile-view-related-articles-text{
    display: flex;
    flex-direction: row;
    justify-content: center;

    font-size: 20px;
    color:rgba(62, 62, 62, 1);

    margin-top: 50px;
}

.blogs-page-mobile-view-related-articles-icon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
}

.blogs-page-mobile-view-related-blogs-container{
    padding: 50px 20px;

    margin-bottom: 50px;
}

.section-gap-div-mobile{
    margin-top: 10px;
}

.section-gap-div-mobile-h2{
    margin-top: 10px;
    font-size: 18px;
    text-decoration: underline;
}
.section-gap-div-mobile-h1{
    margin-top: 10px;
    font-size: 18px;
}


.blogs-desktop-view-main-container-left-left1-lower{
    margin-top: 20px;
}

@media screen and (max-height: 730px){
    .blogs-page-desktop-view-blog-content-heading{
        font-size: 25px;
    
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    
        color: rgba(62, 62, 62, 1);
        font-weight: 700;
    }
}

.blogs-landing-page-desktop-view-main-heading-container{
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 10px;

    font-family: "DM Sans";
    font-size: 32px;
    color: rgba(88, 88, 88, 1)
}

.blogs-landing-page-desktop-view-main-content-master{
    width: 100%;
    padding: 10px 120px;
}

.blog-card-generator-desktop-master-container{
    border: 1px solid #F5F5F5;
    border-radius: 10px;
    padding: 10px 10px;

    max-width: 350px;
    min-width: 350px;
    height: 500px;
    max-height: 500px;

    margin: 20px 20px;
    font-family: "DM Sans";

    position: relative;
}

.blog-card-generator-mobile-master-container{
    border: 1px solid #F5F5F5;
    border-radius: 10px;
    padding: 10px 10px;

    width: 320px;
    height: 500px;
    max-height: 500px;
    max-width: 350px;

    margin: 20px 20px;
    font-family: "DM Sans";

    position: relative;
}

.blog-card-generator-desktop-image-container{
    width: 100%;
}

.blog-card-generator-desktop-image{
    width: 100%;
}

.blog-card-generator-desktop-content{
    margin-top: 10px;

    color: #6E6E6E;
}

.blog-card-generator-desktop-bottom{
    margin-top: 10px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    position: absolute;
    bottom: 10px;
    width: 100%;
    padding-right: 30px;
}

.blog-card-generator-desktop-view-date-container{
    color: #6E6E6E;
    margin-top: 5px;
    font-size: 14px;
}

.blog-card-generator-desktop-heading{
    margin-top: 10px;
    font-size: 20px;
    font-weight: 700;
}

.blogs-landing-page-desktop-view-main-content-container{
    display: flex;
    justify-content: space-between;

    width: 100%;
    column-count: 3;
}

.blogs-landing-page-mobile-view-heading{
    width: 100%;
    text-align: center;

    font-family: "DM Sans";
    font-size: 27px;
    font-weight: 500;

    color: #3E3E3E;
}

.home-page-mobile-view-form-option{
    display: flex;
    align-items: center;

    font-family: "DM Sans";
    color: rgba(88, 88, 88, 1);

    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 10px;

    padding: 2px 5px;
    margin-bottom: 10px;
    background-color: #F7F8F9;
}

.active-home-page-mobile-view-form-option{
    display: flex;
    align-items: center;

    font-family: "DM Sans";
    color: white;

    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 10px;

    padding: 2px 5px;
    margin-bottom: 10px;
    background-color: rgba(255, 94, 149, 1);
}

.home-page-mobile-view-form-selection-container{
    background-color: white;
    width: 100%;
    padding: 0px 15px;
    padding-top: 10px;
    border-radius: 8px 8px 0px 0px;
}

.blog-landing-page-mobile-view-blog-cards-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-page-mobile-view-form-training-image{
    width: 40px;
}

.home-page-mobile-view-form-training-text{
    margin-left: 20px;
    font-weight: 700;
}

.home-page-form-walking-training-mobile-section{
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 20px;
}

.home-page-form-walking-training-mobile-section-text{
    color: rgba(255, 94, 149, 1);
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
}

.blog-card-generator-card-link{
    text-decoration: none;
    color:#2C2C2C;
}