.slick-slide img {
    margin: auto;
    width: 50%;
    /* height: 50%; */
}
.slick-dots li button:before{
    color:#3e3e3e;
    font-size:15px;
    
}
.slick-dots li.slick-active button:before{
    color:#FF5E95
}
.slick-dots li{
    height: 10px;
    width: 10px;
    
}
.slick-dots{
    button{
        width: 1rem;
        height: 1rem;
    }
}