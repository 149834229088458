
.adoption-page-container{
    /* height: 100vh; */
    /* height: auto; */
    width: 100vw;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://websitefilesbucket.s3.ap-south-1.amazonaws.com/adoption-bg-image.jpg);
    background-repeat: no-repeat;
 background-size: cover;
}
.adoption-page-desktop-view-intro-container{
  /* position: absolute; */
  /* top: 0px; */
  height: 100%;
  width: 100%;
}
.adoption-page-desktop-view-intro-content-master{
    padding: 0px 120px;
    font-family: "DM Sans";
    color: white;
    margin-top: 60px;
    
  }
.golden-text{
    
        color: rgba(204,172,80);

}
.adoption-page-desktop-view-intro-bottom-section{
    margin-top: 70px;
    display: flex;
  }

.hero-image-container-desktop-adoption {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://websitefilesbucket.s3.ap-south-1.amazonaws.com/why-petmojo-bg-adoption.jfif);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 120px;
  
    font-family: "DM Sans";
  }
  .adoption-page-why-petmojo-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

  }
  .doption-page-why-petmojo-content-section{
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  .adoption-page-desktop-view-standard-upper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
  }
  .adoption-page-desktop-view-standard-master-container {
    padding: 70px 100px;
  }
  
  .adoption-page-desktop-view-standard-master-container {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://websitefilesbucket.s3.ap-south-1.amazonaws.com/why-petmojo-bg-adoption.jfif);
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .adoption-page-desktop-view-standard-upper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 10%;
  }
  
  .adoption-page-desktop-view-standard-lower {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 10%;
    margin-top: 00px;
  }
  .live-training-golden-line {
    border: 4px solid rgba(192, 156, 62, 1);
    border-radius: 10px;
    width: 150px;
    margin-left: 0px;
  }
  .adoption-page-pets-description{
    background-color: rgb(41, 49, 60);
    padding: 70px 100px;
  }
  .adoption-page-pets-section{
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 40px;

  }
  .adoption-page-pets-section-mobile{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    padding: 50px 50px;
    /* background-color: red; */
    gap: 40px;
  }
  .adoption-page-pets-section-contents{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }
  .adoption-page-desktop-view-standard-image-conatiner1{
    position: relative;
     height: 458px;
    width: 393px;
    border-radius: 10px;
    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/adoption-pet-1.jpg");
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size:cover;
    object-position: top;
  }
  .adoption-page-desktop-view-standard-image-conatiner2{
    position: relative;
     height: 470px;
    width: 393px;
    border-radius: 10px;
    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/adoption-pet-2.jpg");
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size:cover;
    object-position: top;
  }
  .adoption-page-desktop-view-standard-image-conatiner3{
    position: relative;
     height: 470px;
    width: 393px;
    border-radius: 10px;
    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/siberian-husky.jpeg");
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size:cover;
    object-position: top;
  }
  .adoption-page-desktop-view-standard-image-conatiner4{
    position: relative;
     height: 470px;
    width: 393px;
    border-radius: 10px;
    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/adoption-pet-4.jfif");
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size:cover;
    object-position: top;
  }
  .adoption-page-desktop-view-standard-image-conatiner5{
    position: relative;
     height: 470px;
    width: 393px;
    border-radius: 10px;
    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/adoption-pet-5.jfif");
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size:cover;
    object-position: top;
  }
  .adoption-page-desktop-view-standard-image-conatiner6{
    position: relative;
     height: 470px;
    width: 393px;
    border-radius: 10px;
    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/pug.jpg");
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size:cover;
    object-position: top;
  }
  .adoption-page-desktop-view-standard-image-conatiner1-mobile{
    position: relative;
     height: 437px;
    width: 298px;
    border-radius: 10px;
    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/adoption-pet-1.jpg");
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size:cover;
    object-position: top;
  }
  .adoption-page-desktop-view-standard-image-conatiner2-mobile{
    position: relative;
     height: 437px;
    width: 298px;
    border-radius: 10px;
    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/adoption-pet-4.jfif");
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size:cover;
    object-position: top;
  }
  .adoption-page-desktop-view-standard-image-conatiner3-mobile{
    position: relative;
     height: 437px;
    width: 298px;
    border-radius: 10px;
    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/adoption-pet-2.jpg");
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size:cover;
    object-position: top;
  }
  .adoption-page-desktop-view-standard-image-conatiner4-mobile{
    position: relative;
     height: 437px;
    width: 298px;
    border-radius: 10px;
    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/adoption-pet-5.jfif");
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size:cover;
    object-position: top;
  }
  .adoption-page-desktop-view-standard-image-conatiner5-mobile{
    position: relative;
     height: 437px;
    width: 298px;
    border-radius: 10px;
    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/siberian-husky.jpeg");
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size:cover;
    object-position: top;
  }
  .adoption-page-desktop-view-standard-image-conatiner6-mobile{
    position: relative;
     height: 437px;
    width: 298px;
    border-radius: 10px;
    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/pug.jpg");
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size:cover;
    object-position: top;
  }
  
  .adoption-page-content{
    width: 100%;
    background: rgba(74, 74, 74, 0.9);
    height: 170px;
    position: absolute;
    /* top: 65%; */
    bottom: 0px;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    flex-direction: column;
  }
.adoption-text{
    text-align: center;
    background-color: rgba(192, 156, 62, 1);
    /* position: absolute; */
    /* top: 60%; */
    width: 100%;
    font-size: 15px;
    font-family: "DM Sans";
    color: rgba(41, 49, 60, 1);
    padding: 1px;


    /* left: 50%; */
    /* z-index: 2; */
}
.adoption-text-mobile{
    text-align: center;
    background-color: rgba(192, 156, 62, 1);
    /* position: absolute; */
    /* top: 60%; */
    width: 100%;
    font-size: 10px;
    padding: 5px;
    font-family: "DM Sans";
    color: rgba(41, 49, 60, 1);

    /* left: 50%; */
    /* z-index: 2; */
}
.adoption-desc{
    padding: 20px;
    position: absolute;
    /* top: 60%; */
    color: white;
    width: 100%;
    /* opacity: 8;
    z-index:2; */
    margin-top: 10px;
}
.adoption-desc-text{
    display: flex;
    /* flex-direction: column; */
    /* z-index:2; */
    margin-top: 10px;
    font-size: 20px;
    width: 100%;
    gap: 20px;
    font-family: "DM Sans";

}
.adoption-details,.adoption-details-bold{
    display: flex;
    flex-direction: column;
    font-size: 20px;
    /* width: 100%; */
    /* gap: 10px; */
    /* z-index:2; */
    text-align: left;
    /* justify-content: space-between; */
    /* background-color: red; */

}
.adoption-details-mobile,.adoption-details-bold-mobile{
    display: flex;
    flex-direction: column;
    font-size: 15px;
    /* width: 100%; */
    /* gap: 10px; */
    /* z-index:2; */
    text-align: left;
    /* justify-content: space-between; */
    /* background-color: red; */

}
.adoption-details{
    font-weight: 400;
}
.adoption-details-bold{
    font-weight: 700;
}
.adoption-pet-name{
    font-size: 30px;
    font-family: "DM Sans";
}
.adoption-pet-name-mobile{
    font-size: 25px;
    font-family: "DM Sans";
}
/* .adoption-page-desktop-view-standard-image-conatiner {
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  } */
  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.9) 100%);
  }
  .adoption-home-page-intro-mobile-view-point {
    display: flex;
    /* align-items: top; */
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    padding-top: 10px;
  }
  .adoption-page-desktop-view-standard-image-container-mobile{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://websitefilesbucket.s3.ap-south-1.amazonaws.com/adoption-page-bg-why-petmojo.jfif);
    height: auto;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 60px;
  }
  .adoption-page-why-petmojo-content-mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
  .adoption-page-pets-description-mobile{
    background-color: rgb(41, 49, 60);
    padding: 35px 50px;
  }
  .adoption-price-tag{
    position:absolute;
    width: 140px;
    /* align-items: center; */
    height: 60px;
    top: 0px;
    text-align: center;
    right: -20px;
    background: linear-gradient(180deg, #BF9A3C 0%, #F7E790 100%);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px 10px 10px 10px;
font-family: "DM Sans";
font-size: 25px;
font-weight: 700;
font-style: normal;
/* transform: matrix(-1, 0, 0, 1, 0, 0); */
  }
  .adoption-price-tag-out{
    background: linear-gradient(180deg, #C29E40 0%, #F4E38C 100%);
/* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
transform: matrix(0, -1, -0.87, 0.49, 0, 0);
position: absolute;
width: 50px;
height: 43px;
/* left: 380px; */
/* right: 10px; */
right: -25px;
top:20px;
  }
  .adoption-price{
    position: relative;
    top: 20%;
    color: #29313C;
  }
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 10%;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    /* Fallback color */
    /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    /* border: 1px solid #888; */
    width: 25%;
    height: 60%;
    border-radius: 20px;
  }
  .modal-content-mobile {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    /* border: 1px solid #888; */
    width: 80%;
    height: 60%;
    border-radius: 20px;
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  .adopt-now-button{
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    font-size: 18px;
    font-family: "DM Sans";
  }
  div.adoption-button-container:hover{
    background: linear-gradient(180deg, #BF9A3C 0%, #F7E790 100%);
  }