.walking {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: relative;

  position: absolute;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(248, 248, 248, 1);
}

.walking-container {
  position: relative;
}
.slider-training-package-mobile::-webkit-scrollbar {
  display: none;
}
.walking-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */

  text-align: center;

  color: #ffffff;
}

.walking-bg {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
}

.walking-info {
  position: absolute;
  top: 10px;
  padding: 20px;
}

.walking-container {
  margin: auto;
  margin-top: 10%;
}

.walking-package-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.walking-package-leftbtn-inactive {
  background: linear-gradient(257.74deg, #ffae7f 16.56%, #fe7195 58.69%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px 0px 0px 0px;

  outline: none;
  border: none;
  width: 170px;
  height: 37px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  color: #ffffff;
}

.walking-package-leftbtn-active {
  background: linear-gradient(262.89deg, #ffae7f 26.77%, #fe7195 66.06%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 4px 15px rgba(0, 0, 0, 0.5);
  border-radius: 20px 0px 0px 0px;

  outline: none;
  border: none;
  width: 170px;
  height: 37px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  color: #ffffff;
}

.walking-package-rightbtn-inactive {
  width: 170px;
  height: 37px;
  left: 357px;
  top: 0px;

  background: linear-gradient(98.5deg, #f98586 18.97%, #f13e7e 83.89%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 0px 0px;

  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  color: #ffffff;
}

.walking-package-rightbtn-active {
  width: 170px;
  height: 37px;
  left: 357px;
  top: 0px;

  background: linear-gradient(101.88deg, #ff8889 23.07%, #ef2a7c 98.39%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 4px 15px rgba(0, 0, 0, 0.5);
  border-radius: 0px 20px 0px 0px;

  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  color: #ffffff;
}

.subtext-text {
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 600;

  text-align: center;
  color: #5e5e5e;

  margin-top: 5px;
}

.subtext-text-pink {
  color: pink;
}

.once-a-day {
  margin-top: 10px;
  width: 340px;
  height: 370px;

  background: linear-gradient(229.73deg, #ffae7f 5.12%, #fe7195 80.78%);
  border-radius: 0px 0px 20px 20px;

  margin-left: auto;
  margin-right: auto;
}

.once-a-day-desktop {
  margin-top: 10px;
  width: 340px;
  height: 400px;

  background: linear-gradient(229.73deg, #ffae7f 5.12%, #fe7195 80.78%);
  border-radius: 20px;

  margin-left: auto;
  margin-right: auto;

  box-shadow: #5e5e5e 2px 2px 8px;

  margin-top: -100px;
}

.twice-a-day {
  margin-top: 10px;
  width: 340px;
  height: 370px;

  background: linear-gradient(202.62deg, #ff8a89 0.71%, #eb1379 118.72%);
  border-radius: 0px 0px 20px 20px;

  margin-left: auto;
  margin-right: auto;
}

.twice-a-day-desktop {
  margin-top: 10px;
  width: 340px;
  height: 400px;

  background: linear-gradient(202.62deg, #ff8a89 0.71%, #eb1379 118.72%);
  border-radius: 20px 20px;

  margin-left: 60px;
  margin-right: auto;

  position: relative;

  box-shadow: #5e5e5e 2px 2px 8px;
  margin-top: 50px;
}

.walking-package-list-container {
  display: flex;
}

.walking-package-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center safe;
  height: 100%;
  width: 100%;

  text-align: center;
  background-color: rgba(248, 248, 248, 1);
}

.walking-package-container-desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center safe;
  height: 100%;
  width: 60%;

  margin-left: auto;
  margin-right: auto;
  text-align: center;

  margin-top: 30px;
}

.walking-package-slides {
  display: flex;
  flex-direction: row;
  width: 340px;
  height: 420px;

  text-align: center;
  border-radius: 20px;

  margin-left: auto;
  margin-right: auto;

  position: relative;
}

.walking-page-tick-mobile {
  width: 15px;
  margin-right: 5px;
}

.walking-page-mobile-view-package-point {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 0px;
}

.walking-package-slides-desktop {
  display: flex;
  flex-direction: row;
  width: 340px;
  height: 420px;

  text-align: center;
  border-radius: 20px;

  margin-right: auto;

  position: relative;
}

.walking-page-package-heading {
  font-family: "DM Sans";
  font-size: 50px;

  color: rgba(88, 88, 88, 1);

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
}

.walking-package-slide-1 {
  min-width: 340px;
  height: 350px;
  margin-right: 10px;

  border-radius: 20px;
  position: relative;
}

.walking-package-offer {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  color: #c10023;
  padding-top: 10px;
}

.walking-package-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;

  color: #ffffff;
  padding-top: 10px;
}

.walking-package-subtitle {
  display: flex;
  flex-direction: row;
  line-height: 25px;
  justify-content: space-between;
  margin: 0px 10px;
  align-items: start;
  height: 50px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.walking-package-duration,
.walking-package-time {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  display: flex;
  flex-direction: column;
  align-items: end;

  color: #ffffff;
  padding: 10px;
}

.walking-package-content {
  display: flex;
  flex-direction: row;
}

.walking-package-points {
  display: flex;
  flex-direction: column;
}

.walking-package-point {
  display: flex;
  flex-direction: row;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;

  color: #ffffff;
  padding-bottom: 5px;
  padding-left: 30px;
}

.onceaday-icon {
  position: absolute;

  bottom: 25px;
  right: 10px;

  width: 102px;
  height: 102px;
}

.onceaday-icon-desktop {
  position: absolute;

  bottom: 135px;
  right: 10px;

  width: 102px;
  height: 102px;
}

.twiceaday-icon {
  position: absolute;
  bottom: 15px;
  right: 10px;

  width: 102px;
  height: 102px;
}

.twiceaday-icon-desktop {
  position: absolute;
  bottom: 15px;
  right: 10px;

  width: 102px;
  height: 102px;
}

.diagonal-line-desktop {
  position: relative;
  color: rgba(230, 17, 125, 1);
  width: 100%;
}

.diagonal-line-desktop-pro1 {
  position: relative;
  color: rgba(253, 107, 106, 1);
  width: 100%;
}

.diagonal-line-desktop-pro2 {
  position: relative;
  color: rgba(115, 1, 144, 1);
  width: 100%;
}

.diagonal-line-desktop-pro3 {
  position: relative;
  color: rgba(0, 164, 155, 1);
  width: 100%;
}

.diagonal-line-desktop2 {
  position: relative;
  color: rgba(117, 3, 144, 1);
  width: 100%;
}

.diagonal-line-desktop3 {
  position: relative;
  color: rgba(0, 213, 186, 1);
  width: 100%;
}

.diagonal-line-desktop4 {
  position: relative;
  color: rgba(248, 173, 58, 1);
  width: 100%;
}

.diagonal-line-desktop5 {
  position: relative;
  color: rgba(27, 241, 251, 1);

  width: 100%;
}

.diagonal-line-desktop5-walking1 {
  position: relative;
  color: rgba(254, 113, 149, 1);

  width: 100%;
}

.diagonal-line-desktop5-walking2 {
  position: relative;
  color: rgba(240, 52, 125, 1);

  width: 100%;
}

.diagonal-line {
  position: relative;
  color: red;
}

.diagonal-line-mobile-view-training {
  position: relative;
  color: rgba(88, 88, 88, 1);
}

.training-page-mobile-view-package-sec-1 {
  margin-top: 10px;
  width: 100%;
}

.tracking-info-mobile-view-container {
  display: flex;
  align-items: center;
  font-family: "DM Sans";
  font-size: 12px;
}

.tick-mobile-view-image {
  width: 20px;
}

.tracking-info-mobile-view-master-container {
  padding-left: 20px;
  margin-bottom: 20px;
}

.training-page-mobile-view-package-sec-2-i {
  background: linear-gradient(180deg, #00c8b2 0%, #008184 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.training-package-mobile-view-canine-misbehave {
  background: linear-gradient(180deg, #e61e74 0%, #f7a73d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.training-package-mobile-view-agression {
  background: linear-gradient(
    180deg,
    rgba(235, 204, 40, 1) 0%,
    rgba(186, 151, 32, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.mobile-view-per-session {
  color: rgba(62, 62, 62, 1);
  font-size: 12px;
  margin-left: 50px;
}

.diagonal-line-text {
  color: white;
}

.diagonal-line-mobile-view-text {
}

.training-card-mobile-view-price-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
  background-color: rgba(243, 243, 243, 1);
  border-radius: 10px;
  padding: 5px 0px;
  padding-left: 10px;
  padding-right: 5px;
}

.white-background {
  background-color: white;
}

.groomer-meet-section-2-subtext-1 {
  padding: 2px 0px;
}

.nav-dropdown-main-link-text {
  text-decoration: none;
  font-size: 22px;

  font-weight: 500;
}

.groomer-meet-section-1 {
  font-size: 30px;
  padding: 0px 30px;
  text-align: center;
  padding-top: 10px;
}

.groomer-meet-section-2-subtext {
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.walking-page-mobile-view-daily {
  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_904_eb7h9y.png");
  background-repeat: no-repeat;
  background-position: contain;

  color: white;
  font-family: "DM Sans";
  padding: 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.walking-page-mobile-view-daily-section-1 {
  font-size: 25px;
  text-align: center;
  margin-top: 10px;
}

.walking-page-mobile-view-daily-section-2 {
  text-align: center;
  padding: 0px 20px;
}

.walking-page-mobile-view-daily-section-3 {
  margin-top: 40px;
}

.walking-orange-txt {
  color: rgba(254, 113, 149, 1);
}

.walking-pink-txt {
  color: rgba(239, 41, 124, 1);
}

.walking-card-mobile-view-price-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  background-color: rgba(243, 243, 243, 1);
  border-radius: 10px;
  padding: 5px 0px;
  padding-left: 10px;
  padding-right: 5px;
}

.training-card-mobile-view-discounted-price {
  color: rgba(62, 62, 62, 1);
  font-size: 12px;
  margin-bottom: 5px;
}

.training-card-mobile-view-disc-price {
  background-color: #585858;
  color: white;
  padding: 2px 8px;
  border-radius: 8px;
}
.training-card-mobile-view-disc-price-golden {
  background: linear-gradient(180deg, #9c773f 0%, #d4b133 100%);
  color: white;
  padding: 2px 8px;
  border-radius: 8px;
}

.grooming-1-card-mobile-view-disc-price {
  background-color: #585858;
  color: white;
  padding: 2px 19px;
  border-radius: 8px;
}

.walking-1-card-mobile-view-disc-price {
  background-color: #585858;
  color: white;
  padding: 2px 14px;
  border-radius: 8px;
}
.walking-1-card-mobile-view-disc-price-golden {
  background: linear-gradient(180deg, #9c773f 0%, #d4b133 100%);
  color: white;
  padding: 2px 14px;
  border-radius: 8px;
}

.diagonal-line-text-mobile-view-training {
  color: rgba(88, 88, 88, 1);
}

.diagonal-line-text-desktop {
  color: rgba(248, 87, 110, 1);
}

.diagonal-line-text-desktop-pro1 {
  color: rgba(253, 107, 106, 1);
}

.diagonal-line-text-desktop2 {
  color: rgba(117, 3, 144, 1);
}

.diagonal-line-text-desktop-pro2 {
  color: rgba(115, 1, 144, 1);
}

.diagonal-line-text-desktop3 {
  color: rgba(0, 213, 186, 1);
}

.diagonal-line-text-desktop-pro3 {
  color: rgba(0, 164, 155, 1);
}

.diagonal-line-text-desktop4 {
  color: rgba(248, 173, 58, 1);
}

.diagonal-line-text-desktop5 {
  color: rgba(27, 241, 251, 1);
}

.diagonal-line-text-desktop5-walking1 {
  color: rgba(254, 113, 149, 1);
}

.diagonal-line-text-desktop5-walking2 {
  color: rgba(240, 52, 125, 1);
}

.diagonal-line:before {
  position: absolute;
  content: "";
  left: 0;
  top: 42.5%;
  right: 0;
  border-top: 2px solid;
  border-color: inherit;
  -webkit-transform: skewY(10deg);
  -moz-transform: skewY(10deg);
  transform: skewY(10deg);
}

.diagonal-line-mobile-view-training:before {
  position: absolute;
  content: "";
  left: 0;
  top: 42.5%;
  right: 0;
  border-top: 2px solid;
  border-color: inherit;
  -webkit-transform: skewY(10deg);
  -moz-transform: skewY(10deg);
  transform: skewY(10deg);
}

.diagonal-line-desktop:before {
  position: absolute;
  content: "";
  left: 15px;
  top: 42.5%;
  right: 15px;
  border-top: 2px solid;
  border-color: inherit;
  -webkit-transform: skewY(10deg);
  -moz-transform: skewY(10deg);
  transform: skewY(10deg);
}

.diagonal-line-desktop-pro1:before,
.diagonal-line-desktop-pro2:before,
.diagonal-line-desktop-pro3:before {
  position: absolute;
  content: "";
  left: 15px;
  top: 42.5%;
  right: 15px;
  border-top: 2px solid;
  border-color: inherit;
  -webkit-transform: skewY(10deg);
  -moz-transform: skewY(10deg);
  transform: skewY(10deg);
}

.diagonal-line-desktop2:before,
.diagonal-line-desktop3:before,
.diagonal-line-desktop4:before,
.diagonal-line-desktop5:before {
  position: absolute;
  content: "";
  left: 15px;
  top: 42.5%;
  right: 15px;
  border-top: 2px solid;
  border-color: inherit;
  -webkit-transform: skewY(10deg);
  -moz-transform: skewY(10deg);
  transform: skewY(10deg);
}

.diagonal-line-desktop5-walking1:before,
.diagonal-line-desktop5-walking2:before {
  position: absolute;
  content: "";
  left: 5px;
  top: 42.5%;
  right: 5px;
  border-top: 2px solid;
  border-color: inherit;
  -webkit-transform: skewY(10deg);
  -moz-transform: skewY(10deg);
  transform: skewY(10deg);
}

.popup-vector-walking {
  position: absolute;
  top: 90px;
}

.intro-btn-walking {
  position: absolute;
  bottom: 40%;
  left: 8%;
}

.walking-intro {
  width: 100%;
  overflow: hidden;

  transform: scale(0.9);
}

@media only screen and (max-width: 400px) {
  .walking-intro {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .walking-intro {
    width: 100%;
  }
}

.walking-page-hero-image {
  width: 700px;
}

.grooming-page-hero-image {
  width: 700px;
}

.training-page-hero-image {
  width: 650px;
}

.walking-page-intro-container {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.walking-page-intro-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-left: 120px;
}

.training-page-intro-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-left: 100px;
}

.walking-page-intro-left-box1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-family: "DM Sans";
  font-size: 50px;
  color: rgba(88, 88, 88, 1);
}

.walking-page-intro-right {
  margin-right: 120px;
}

.walking-page-intro-left-box3 {
  position: relative;

  width: 400px;
}

.once-a-day-header {
  background-color: white;
  border-radius: 0px 0px 20px 0px;
  width: 200px;

  font-family: "DM Sans";
  font-size: 14px;

  padding: 10px 15px;
}

.walking-page-packages-masterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 120px;
  background-color: rgba(248, 248, 248, 1);
}

.walking-page-package-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 350px;
}

.walking-page-desktop-view-trainer-meet-subtext {
  width: 500px;
  text-align: center;
}

.walking-page-packages-dropdown-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;

  margin-top: 20px;

  border: 1px solid rgba(166, 166, 166, 1);
  border-radius: 10px;
  font-family: "DM Sans";
  font-size: 18px;
  color: rgb(64, 64, 64);
  width: 210px;

  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.walking-page-packages-dropdown-container:hover {
  cursor: pointer;
}

.walking-dropdown-container {
  font-family: "DM Sans";
  font-size: 19px;
  color: rgba(88, 88, 88, 1);

  border-left: 1px solid rgba(166, 166, 166, 1);
  border-right: 1px solid rgba(166, 166, 166, 1);
  border-bottom: 1px solid rgba(166, 166, 166, 1);
  border-radius: 0px 0px 10px 10px;
  padding: 10px 20px;

  margin-top: 0px;

  width: 210px;
  text-align: center;

  position: absolute;
}

.walking-page-packages-right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.walking-dropdown-container:hover {
  cursor: pointer;
}

.bold-pink-line-walking-packages {
  border-radius: 10px;
  border: 3px solid rgba(254, 119, 152, 1);

  width: 150px;
  position: absolute;
  top: 120px;
}

.bookingbutton-desktop {
  margin-top: 30px;
}

.walking-page-track-image {
  width: 390px;
  margin-top: -50px;
}

.walking-page-track-left {
  font-family: "DM Sans";
  color: rgba(88, 88, 88, 1);
  display: flex;
  /* align-items: flex-end; */
  flex-direction: column;
  width: 400px;
}

.walking-page-track-right {
  /* text-align: right; */
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  position: relative;
  flex: 1;
}

.walking-page-track-left-heading {
  position: relative;
  font-size: 50px;
}

.walking-page-track-left-content {
  font-size: 20px;

  /* margin-top: 60px; */
}

.booksession-desktop-view-placard {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg,
      #d6b75c 0%,
      #e8d078 39.06%,
      #faec95 63.09%,
      #d8be6b 100%
    ),
    rgba(69, 163, 165, 1);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;

  margin-top: 40px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 190px;
  height: 48px;
}

.walking-page-track-line {
  border: 3px solid rgba(254, 119, 152, 1);
  /* right:0; */
  left: 0;
  border-radius: 10px;
  position: absolute;
  width: 150px;
}

.walking-page-track-container {
  display: flex;
  justify-content: space-between;
  padding: 60px 120px;

  position: relative;
}

.walking-page-track-left-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.walking-page-track-left-buttons-store {
  width: 200px;
}

.walking-page-trainer-background {
  width: 100vw;
}

.walking-page-trainer-container {
  margin-top: 50px;
  margin-bottom: 50px;

  position: relative;
}

.walking-page-trainer-left {
  position: absolute;
  z-index: 100;
}

.walking-page-trainer-line {
  border: 3px solid rgba(254, 119, 152, 1);
  border-radius: 10px;

  width: 150px;
}

.walking-page-trainer-content {
  font-size: 30px;
}

.walking-trainer-button-container {
  margin-left: 200px;
}

.get-started-walking-line {
  border: 3px solid rgba(254, 119, 152, 1);
  border-radius: 10px;
  width: 150px;
}
/* @keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}
.training-page-mobile-view-package-container{
  animation: scroll 5s linear infinite;
} */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes scroll2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes scroll3 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-75%);
  }
}
@keyframes scroll4 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-250%);
  }
}
