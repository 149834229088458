.all-services {
  font-family: "DM Sans";

  display: flex;
  flex-direction: column;
  align-items: center;
}

.services-page-mobile-view-heading{
  font-size: 35px;
  color: #585858;
}

.services-page-mobile-view-subhead{
  font-size: 20px;
  text-align: center;
  color: #848484;
  padding: 0px 10px;
}

.services-page-mobile-view-service-1{
  position: relative;
  margin-top: 10px;

  max-width: 385px;

  padding: 0px 20px;
}

.services-page-mobile-view-service-1-1{
  position: relative;
  margin-top: 40px;

  max-width: 385px;

  padding: 0px 20px;
}

.services-page-mobile-view-icon1{
  width: 100%;
  min-height: 100px;
}

.service-page-go-icon{
  width: 100px;
}

.services-page-mobile-view-service-1-contents{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  top: 0px;
  left: 0px;

  color: white;

  padding: 0px 20px;
  width: 100%;

}

.services-page-mobile-view-service-1-contents-head{
  font-size: 25px;
  margin-top: 10px;
}

.services-page-mobile-view-service-1-contents-content{
  text-align: center;
  font-weight: 200;
  margin-top: 10px;
  width: 80%;
}

.services-page-mobile-view-service-1-contents-content-grom{
  text-align: center;
  font-weight: 200;
  /* margin-top: 10px; */
  padding: 0px 30px;
}

.service-page-go-icon-container{
  margin-top: 10px;
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.services-slider {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.services-package {
  margin: 0;
  box-shadow: none;
  padding: 0;
}

.services-subtitle {
  margin-left: auto;
  margin-right: auto;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(93, 93, 93, 0.8);

  margin-top: -40px;
}

.services-container {
  margin-bottom: 10px;
}

.allservices-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 400px) {
  .allservices-bg {
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .allservices-bg {
    width: 90%;
  }
}

.walking-package {
  box-shadow: none;
}

.package-btn-text {
  position: absolute;
  top: 10px;
  left: 15px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
}

.color-1 {
  color: #EC2978;
}
.color-2 {
  color: #8d1691;
}
.color-3 {
  color: #007b80;
}

.services-grooming {
  width: 119px;
  height: 102px;
}

.filterBtn {
  border: 2px solid #ff5e95;
  color: #ff5e95;
  background: transparent;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 0rem 0.5rem;
  cursor: pointer;
}

.activeFilterBtn {
  border: 2px solid #ff5e95;
  color: white;
  background: #ff5e95;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 0rem 0.5rem;
}
