.about{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: relative;

    position:absolute;
    padding:0;
    margin:0;

    top:0;
    left:0;

    width: 100%;
    height: 100%;
}

.about-bg{
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    margin-top: 30px;
}

.petmojo-aim{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    margin-top: 20px;
    margin-bottom: 20px;
}

.mission{
    margin-top: 10px;
    margin-bottom: 10px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;

    color: #5E5E5E;
}

.mission-subtitle{
    margin-top: 10px;
    margin-bottom: 10px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;

    color: #5E5E5E;

    padding-left: 60px;
    padding-right: 60px;
}

.our-story-text{
    font-family: 'DM Sans';
    font-style: normal;
    font-size: 18px;
    text-align: center;

    color: rgba(93, 93, 93, 1);

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    padding: 20px;
}


.about-page-main-image-desktop{
    height: 100%;
    width: 100%;
}
.about-page-desktop-view-master-container{
    position: relative;
}

.about-page-desktop-view-intro-container{
    position: absolute;
    top: 0px;
    width: 100%;
}
.about-page-desktop-view-mobile-image{
    width: 100%;
    height: 70vh;
}

.about-page-desktop-view-intro-main{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 120px;
}

.about-page-desktop-view-intro-left{
    color: white;
    font-family: "DM Sans";
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}

.about-page-desktop-view-intro-left-head{
    font-size: 50px;
    text-align: left;
    width: 100%;
}

.about-page-desktop-view-intro-left-content-1{
    font-size: 23px;
    margin-top: 20px;
}

.about-page-desktop-view-intro-left-button{
    margin-top: 20px;
}

.socials-page-available-text{
    color: rgb(241, 76, 76);
    font-weight: 700;
    margin-top: 5px;
}

.about-page-mission-image{
    width: 100%;
}

.about-page-desktop-view-mission-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 120px;
    align-items: center;
    font-family: "DM Sans";
    width: 100%;
}

.about-page-desktop-view-mission-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-page-desktop-view-mission-right{
    width: 50%;
}

.about-page-desktop-view-mission-head{
    color: rgba(88, 88, 88, 1);
    font-size: 50px; 
}

.about-page-desktop-view-mission-content{
    font-size: 25px;
    color: rgba(94, 94, 94, 1);
    margin-top: 20px;
    width: 400px;
    text-align: center;
}

.about-page-desktop-view-plus-container{
    display: flex;
    padding: 20px 120px;
    justify-content: space-between;
    align-items: center;
    color: rgba(94, 94, 94, 1);
    font-family: "DM Sans";
}

.plus-1-outer-container{
    border: 2px solid #D7D7D7;
    border-radius: 10px;
    background-color: rgba(254, 119, 152, 1);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 90px;
    margin: 0px 10px;
    width: 300px;
}

.plus-1-inner-container{
    border-radius: 0px 7px 7px 0px; 
    padding: 15px 10px;
    width: 95%;
    background-color: rgba(255, 234, 241, 1);
    display: flex;
    align-items: center;
    position: relative;
}

.number-container{
    position: absolute;
    top: -23px;
    font-size: 32px;
    font-weight: 700;
    color: rgba(254, 119, 152, 1);
}

.plus-1-text{
    font-weight: 500;
    font-size: 18px;
}

.about-page-mobile-view-intro-section{
    position: absolute;
    top: 0px;
    left: 0px;

    width: 100%;
}

.about-page-mobile-view-intro-image{
    height: 100vh;
    width: 100%;
}

.about-page-mobile-view-intro-subsection{
    padding: 20px 20px;
    font-family: "DM Sans";
    color: white;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 50vh;
}


.about-page-mobile-view-intro-subsection-heading{
    font-size: 30px;
    font-weight: 700;
}

.about-page-mobile-view-intro-subsection-text{
    margin-top: 10px;
    font-size: 18px;
}

.about-page-mobile-view-intro-subsection-btn{
    margin-top: 20px;
}
