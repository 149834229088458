@import url("https://fonts.googleapis.com/css2?family=Comforter&family=Lobster+Two&family=Montserrat:wght@200&family=Pacifico&family=Poppins:wght@100;300&display=swap");
.popup {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: relative;

  position: absolute;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding-left: 20px;
  padding-right: 20px;
}

.smallTxt {
  font-size: 11px;
}

.popup-navbar {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popup-navbar-special {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.nav-logo {
  margin-left: 20px;
}

.nav-text {
  margin-right: 20px;
}

.popup-limited-slots,
.popup-book-now {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: right;

  color: #3d3d3d;
}

.popup-book-now {
  font-size: 14px;
  color: #f5cc39;
}

.popup-input-container {
  margin-top: 50px;
  margin-bottom: 30px;

  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.popup-input-container-desktop {
  margin-top: 50px;
  margin-bottom: 30px;

  position: relative;
}

.popup-desktop-image-train {
  margin-right: 100px;
}

.popup-desktop-training-container {
  display: flex;
  justify-content: space-between;
}

.popup-image-desktop-train {
  width: 45vw;
}

.phone-number-title,
.name-input-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #5e5e5e;

  position: absolute;
  top: -10px;
  left: 30px;
  z-index: 100;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.popup-phone-input {
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 326px;
  height: 37px;
  position: relative;
  outline: none;
  padding-left: 10px;

  color: #585858;
  font-size: 14px;
  margin-top: 5px;
  background-color: #f8f8f8;
}

.popup-phone-input-desktop {
  border: 1px solid #cacaca;
  border-radius: 10px;
  width: 100%;
  height: 48px;
  position: relative;
  outline: none;
  padding-left: 10px;

  color: #585858;
  font-size: 14px;
  margin-top: 5px;
  background-color: #f8f8f8;
}

.popup-phone-input-desktop-time {
  border: 1px solid #cacaca;
  border-radius: 10px;
  width: 100%;
  height: 48px;
  position: relative;
  outline: none;
  padding-left: 10px;

  color: #585858;
  font-size: 14px;
  margin-top: 5px;
  background-color: #f8f8f8;
}

.popup-phone-input-invalid {
  border: 1px solid rgb(204, 0, 0);
  border-radius: 5px;
  width: 326px;
  height: 37px;
  position: relative;
  outline: none;
  padding-left: 10px;

  color: #585858;
  font-size: 14px;
  margin-top: 5px;
  background-color: #f8f8f8;
}

.popup-phone-input-desktop-invalid {
  border: 1px solid rgb(204, 0, 0);
  border-radius: 5px;
  width: 386px;
  height: 48px;
  position: relative;
  outline: none;
  padding-left: 10px;

  color: #585858;
  font-size: 14px;
  margin-top: 5px;
  background-color: #f8f8f8;
}

.popup-phone-input-desktop-invalid-date {
  border: 1px solid rgb(204, 0, 0);
  border-radius: 5px;
  width: 100%;
  height: 48px;
  position: relative;
  outline: none;
  padding-left: 10px;

  color: #585858;
  font-size: 14px;
  margin-top: 5px;
  background-color: #f8f8f8;
}
.popup-phone-input-desktop-invalid-time {
  border: 1px solid rgb(204, 0, 0);
  border-radius: 5px;
  width: 100%;
  height: 48px;
  position: relative;
  outline: none;
  padding-left: 10px;

  color: #585858;
  font-size: 14px;
  margin-top: 5px;
  background-color: #f8f8f8;
}
.popup-phone-input-desktop-invalid-email {
  border: 1px solid rgb(204, 0, 0);
  border-radius: 5px;
  width: 100%;
  height: 48px;
  position: relative;
  outline: none;
  padding-left: 10px;

  color: #585858;
  font-size: 14px;
  margin-top: 5px;
  background-color: #f8f8f8;
}

.popup-phone-input::placeholder {
  color: rgb(188, 188, 188);
  font-size: 14px;
}

.popup-phone-input-desktop::placeholder {
  color: rgb(188, 188, 188);
  font-size: 14px;
}

.validation-container {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  vertical-align: middle;
}

.validation-msg {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  color: #d73637;
  padding-left: 5px;
}

.options-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #5e5e5e;

  display: flex;
  justify-content: left;
  align-items: center;
  vertical-align: middle;

  margin-top: 20px;
}

.options-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.option-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.option-name {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #5e5e5e;
  padding-left: 5px;
}

.radio-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.popup-cta {
  margin-top: 0px !important;
  margin-bottom: 20px;
}

.popupfooter {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: rgba(75, 75, 75, 0.7);
  margin-top: 10px;
}

.success-animation {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.success-animation-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.success-animation-container {
  width: 100%;
  height: 100%;
}

.checkmark {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #fff;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #41cb46;
  fill: #41cb46;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.textOverBg {
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-weight: bolder;
}
.textOverBg2 {
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  /* font-weight: 500; */
  font-weight: bolder;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #dec269;
  }
}

.success-info {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 22px;
  text-align: right;

  color: #616161;
  margin-top: 30px;
}

.success-msg {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #3f414e;
  padding: 22px;
}

.options-container-grooming {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}

.option-box-grooming {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.grooming-option {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  vertical-align: middle;
}

.option-price {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;

  color: #5e5e5e;
}

.name-input-container {
  margin-top: 30px;
  position: relative;
}

.name-input-container-1 {
  margin-top: 30px;
  position: relative;
}

.name-input-container {
  position: relative;
}

.name-input-container-desktop {
  margin-top: 0px;
  position: relative;
}

.limited-slots-left-desktop-grooming-popup {
  color: rgb(214, 18, 18);
  font-family: "DM Sans";
  text-align: center;
}

.date-time-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.date-time-container-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.date-time-input {
  width: 140px;
  display: flex;
}

.date-time-input-desktop {
  width: 250px;
  display: flex;
}

.date-time-desktop-bundle {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.popup-header-container {
  font-family: "DM Sans";
  margin-top: 40px;
}

.popup-header {
  font-size: 30px;
  font-weight: 600;
  color: #727272;
}

.popup-header-grooming {
  font-size: 25px;
  font-weight: 600;
  color: #727272;
}

.popup-header-grooming-desktop {
  font-size: 32px;
  font-weight: 600;
  color: #727272;
}

.header-pink {
  color: #ff5e95;
}

.disclaimer {
  color: #8b8b8b;
  margin-top: 3%;
  font-size: 14px;
}

.disclaimer-heading {
  font-weight: 1000;
  color: rgba(27, 27, 27, 0.7);
}

.phone-number-text {
  font-family: "DM Sans";
  color: #727272;
  font-size: 14px;
  font-weight: bold;
}

.phone-number-text-grooming {
  font-family: "DM Sans";
  color: #727272;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
}

.phone-number-text-grooming-1 {
  font-family: "DM Sans";
  color: #727272;
  font-size: 14px;
  font-weight: bold;
  position: relative;
}

.time-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  font-family: "DM Sans";
}

.timeSlotText {
  color: rgb(188, 188, 188);
}

.timeSlotTextDark {
  color: grey;
}

.timeSlotIcon {
  color: black;
}

.timeSlotIcon:hover {
  cursor: pointer;
}

.timeSlot-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  position: absolute;
  right: 10px;

  border: 1px solid rgb(193, 193, 193);
  border-radius: 10px;

  padding: 10px 10px;

  margin-top: 10px;

  z-index: 100;

  background-color: #f8f8f8;
}

.timeSlot-container-desktop {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  position: absolute;
  right: 0px;
  top: 120px;

  border: 1px solid rgb(193, 193, 193);
  border-radius: 10px;
  padding: 10px 10px;
  margin-top: 10px;
  z-index: 100;
  background-color: #f8f8f8;
}

.timeSlot-upper {
  display: flex;
  justify-content: space-between;
}

.timeSlot-lower {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  padding: 0px 35px;
}

.timeSlot {
  background-color: #fe7798;
  color: white;
  font-family: "DM Sans";

  padding: 10px 10px;

  border: 1px solid white;
  border-radius: 10px;
}

.timeSlot:hover {
  cursor: pointer;
}

.validationText {
  color: rgb(210, 0, 0);

  font-family: "DM Sans";
  font-size: 13px;

  position: absolute;
  z-index: 100;
  right: 10px;
  top: 0px;
}

.validationText-grooming-name {
  color: rgb(210, 0, 0);

  font-family: "DM Sans";
  font-size: 13px;

  position: absolute;
  z-index: 100;

  top: 0px;
  right: 0px;
}

.validationText-grooming-phone {
  color: rgb(210, 0, 0);

  font-family: "DM Sans";
  font-size: 13px;

  position: absolute;
  z-index: 100;

  top: 0px;
  right: 0px;
}

@media screen and (min-width: 361px) {
  .validationText-grooming-phone {
    color: rgb(210, 0, 0);

    font-family: "DM Sans";
    font-size: 13px;

    position: absolute;
    z-index: 100;

    top: 0px;
    right: 0px;
  }
}

.validationText-grooming-date {
  color: rgb(210, 0, 0);

  font-family: "DM Sans";
  font-size: 13px;

  position: absolute;
  z-index: 100;

  top: 0px;
  right: 0px;
}

.validationText-grooming-time {
  color: rgb(210, 0, 0);

  font-family: "DM Sans";
  font-size: 13px;

  position: absolute;
  z-index: 100;

  top: 345px;
  left: 265px;
}

.validationText-grooming-address {
  color: rgb(210, 0, 0);

  font-family: "DM Sans";
  font-size: 13px;

  position: absolute;
  z-index: 100;

  top: 0px;
  right: 0px;
}

.spinnerLocation {
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 20px;
}

.popup-grooming-desktop-master-container {
  display: flex;
  justify-content: space-between;

  padding: 0px 120px;
  margin-top: 70px;
}

.popup-grooming-desktop-services {
  margin-top: 60px;

  width: 70%;
}

.popup-grooming-mobile-services {
  margin-top: 0px;
  width: 100%;
  margin-bottom: 20px;
}

.popup-grooming-desktop-phone {
  margin-top: 0px;
}

.popup-grooming-desktop-right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.phone-number-desktop-bundle {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  width: 386px;
}

.red-feedback-text-desktop {
  font-family: "DM Sans";
  color: rgb(204, 0, 0);
  font-size: 12px;
}
