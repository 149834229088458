.viewMoreContainer {
    /* width: 100rem; */
  
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  
    background: white;
    margin-left: 10px;
  }
  
  .viewMoreContainerDesktop {
    /* width: 100rem; */
  
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    justify-content: center;
  
    background: white;
    margin-left: 10px;
  }
  
  .viewMoreContainer p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
  }
  
  .viewMoreContainerDesktop p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
  }
  
  .viewMoreContainer div {
    width: max-content;
  
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px 0px;
    padding: 0.75rem 2rem;
  
    cursor: pointer;
  }
  
  .viewMoreContainerDesktop div {
    width: max-content;
  
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px 0px;
    padding: 0.75rem 2rem;
  
    cursor: pointer;
  }
  
  .heartSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    padding: 60px 120px;
  
    font-family: "DM Sans";
  
    background-color: rgba(248, 248, 248, 1);
  }
  
  .heartSectionHomePage{
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    padding: 60px 120px;
  
    font-family: "DM Sans";
  
    background-color: rgba(248, 248, 248, 1);
  }
  
  .heartSectionAboutPage{
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    padding: 60px 120px;
  
    font-family: "DM Sans";
  
    background-color: rgba(248, 248, 248, 1);
  }
  
  .heartSectionWalking{
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    padding: 60px 120px;
    margin-top: 40px;
  
    font-family: "DM Sans";
  }
  
  .heartSectionText{
    width: 40%;
    font-size: 20px;
    font-weight: 700;
  
    color: rgba(132, 132, 132, 1);
    margin-top: 30px;
    width: 300px;
  }
  
  .home-page-why-choose-container2{
  }
  
  .getStartedHeading{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  
  .text{
    font-size: 18px;
  }
  
  .heartSectionTextContainer{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .heartSectionTextHeading{
    color: rgba(88, 88, 88, 1);
    
    font-size: 50px;
    font-weight: 400;
  
    position: relative;
  }
  
  .heartSectionTextHeading2{
    color: rgba(88, 88, 88, 1);
    
    font-size: 50px;
    font-weight: 400;
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  
  }
  
  .heartHeadingLine{
    color: rgba(254, 119, 152, 1);
    background-color: rgba(254, 119, 152, 1);
    border: 2px solid rgba(254, 119, 152, 1);
    border-radius: 10px;
  
    width: 200px;
    position: absolute;
    top: 50px;
    left: 10px;
  }
  
  .heartSectionText{
    color:rgba(94, 94, 94, 1);
    width: 350px;
  }
  
  .heartSectionImage{
  
    width: 40%;
  }
  
  .slideHeader{
    margin: 0px 10px;
  }
  
  .slideHeaderText{
    font-family: "DM Sans";
    font-size: 30px;
  
    width: 250px;
  
    color: rgba(88, 88, 88, 1);
  }
  
  .slideHeaderLine{
    border: 2px solid rgba(254, 119, 152, 1);
    border-radius: 10px;
  
    width: 150px;
  }
  
  .servicesBackground{
    background-color: rgba(255, 232, 238, 1);
  
    display: flex;
    justify-content: space-around;
    /* justify-content: space-between; */
  
    position: relative;
  
    margin-top: 100px;
  }
  
  
  .serviceTexts{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
  
    width: 60%;
  
    position: absolute;
    top: 30px;
    left:120px;
  }
  
  .dogImage{
    margin-left: 40%;
  }
  
  .serviceHeading{
    font-family: "DM Sans";
    font-size: 50px;
  
    color: rgba(93, 93, 93, 1);
  }
  
  .serviceHeadingLine{
    border: 3px solid rgba(254, 119, 152, 1);
    border-radius: 10px;
  
    width: 150px;
  
    position: absolute;
    top: 50px;
  }
  
  .serviceContainer{
    display: flex;
    flex-direction: column;
  
    width: 100%;
  
    margin-top: 100px;
  }
  
  .upperContainer{
    display: flex;
    justify-content: space-between;
  }
  
  .lowerContainer{
    display: flex;
    justify-content: space-between;
  
    margin-top: 40px;
  }
  
  .walkingPackageHeading{
    font-family: "DM Sans";
    font-size: 50px;
  
    color:rgba(88, 88, 88, 1);
    width: 460px;
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    position: relative;
  }
  
  .walkingPackageContainer{
    margin-right: 120px;
    margin-left: 120px;
  }
  
  .howToStart{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  
    position: relative;
  
    font-family: "DM Sans";
    font-size: 50px;
    color:rgba(88, 88, 88, 1);
    margin-bottom: 20px;
  
    width: 500px;
  }
  
  
  .getStartedLine{
    border-radius: 10px;
    border: 3px solid rgba(254, 119, 152, 1);
  
    width: 150px;
  
    position: absolute;
    left: 15px;
    top: 70px;
  }
  
  .gettingStartedBookingBtn{
    
  }
  
  .gettingStartedBookingBtn1{
  }
  
  .home-page-why-choose-container1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  
  
  .landingGroomingContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .landingTrainingContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  
    width: 350px;
  }
  
  .landingGroomingHeading{
    font-family: "DM Sans";
    font-size: 50px;
  
    color:rgba(88, 88, 88, 1);
  
    width: 400px;
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  
    position: relative;
  }
  
  .landingWalkingHeading{
    font-family: "DM Sans";
    font-size: 50px;
  
    color:rgba(88, 88, 88, 1);
  
    position: relative;
    width: 500px;
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .landingGroomingLine{
    border: 3px solid rgba(254, 119, 152, 1);
    width: 150px;
    border-radius: 10px;
  
    position: absolute;
    left: 140px;
  }
  
  .landingGroomingLineGrooming{
    border: 3px solid rgba(254, 119, 152, 1);
    width: 150px;
    border-radius: 10px;
  
    position: absolute;
    left: 0px;
  }
  
  .landingGroomingLineLive{
    border: 3px solid rgba(254, 119, 152, 1);
    width: 150px;
    border-radius: 10px;
  
    position: absolute;
    margin-left: 30px;
  }
  
  .leftHead{
    text-align:left;
    font-size: 45px;
    color: rgba(88, 88, 88, 1);
    font-weight: 500;
  }
  
  .leftHeadLive{
    margin-top: 15px;
    text-align:left;
  }
  
  .horizontalScroll{
    display: flex;
    flex-direction: row;
  }
  