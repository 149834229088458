.grooming{
    display: flex;
    flex-direction: column;
    width: 100vw;
    /* height: 100vh; */
     position: relative;

    position:absolute;
    padding:0;
    margin:0;

    top:0;
    left:0;

    width: 100%;
    /* height: 100%; */

    background-color: rgba(248, 248, 248, 1);
}

.grooming-intro{
  margin-left: 14px;
}

.boksession{
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    #DEC269;
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 0px 5px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;

  margin-top: 40px;
  width: 50px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;
}

.grooming-meet{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.grooming-title{
    padding: 0;
}

.package-points{
  display: flex;
  flex-direction: row;
  vertical-align: middle;

  margin: 0 !important;
  padding: 0 !important;

  height: 25px;
}

.package-point-left{
    display: flex;
    flex-direction: row;
    vertical-align: middle;

    margin: 0 !important;
    padding: 0 !important;

    height: 25px;
}

.package-point-left-last{
  display: flex;
  flex-direction: row;
  vertical-align: middle;

  margin: 0 !important;
  padding: 0 !important;

  height: 25px;
}

.package-point-right{
  display: flex;
  flex-direction: row;
  vertical-align: middle;

  margin: 0 !important;
  padding: 0 !important;

  height: 25px;
}

.grooming-text{
    display: flex;
    flex-direction: row;

    padding-top: 0px !important;
}

.package-points-left{
    padding-right: 5px;
    width: 75%; 
}

.package-points-right{
    width: 100%;
}

.grooming-icon-1{
    width: 116px;
    height: 115px;
}

.grooming-icon-1-desktop{
  transform: scale(1.5);
  position: absolute;
  right: 30px;
  bottom: 30px;
}

.grooming-package-icon-1-desktop{
  transform: scale(1.2);
  position: absolute;
  right: 40px;
  bottom: 30px;
}

.grooming-icon-rel-desktop{
  transform: scale(1.5);
  position: absolute;
  right: 45px;
  bottom: 30px;
}

.grooming-icon-2{
    width: 200px;
    height: 178px;
}

.grooming-icon-2-desktop{
  transform: scale(1.5);
  position:absolute;
  right: 40px;
  bottom: 20px;
}

.grooming-package-icon-2-desktop{
  transform: scale(0.8);
  position:absolute;
  right: 0px;
  bottom: 0px;
}

.grooming-icon-3{
    width: 136px;
    height: 110px;
}

.grooming-icon-3-desktop{
  transform: scale(0.5);
  position: absolute;
  right: -25px;
  bottom: -20px;
}

.grooming-package-icon-3-desktop{
  transform: scale(1.2);
  position: absolute;
  right: 40px;
  bottom: 40px;
}

.grooming-service{
    display: flex !important;
    justify-content: left !important;
}

.grooming-icon-2-test{
  transform: scale(0.5);
  position: absolute;
  right: -35px;
  bottom: -30px;
}

/* .popup-dropdown-btn{
    display: flex;
    flex-direction: row;

    border: 2px solid #FF5E95;
    filter: drop-shadow(0px 4px 4px rgba(255, 94, 149, 0.25));
    margin-bottom: 5px;
    justify-content: space-between;

    align-items: center;
}

.popup-dropdown-option{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 10px;
}

.popup-dropdown-icon{
    padding: 10px;
}

.popup-dropdown-content{
    border: 2px solid #FF5E95;
    filter: drop-shadow(0px 4px 4px rgba(255, 94, 149, 0.25));
    padding: 5px;
    margin-bottom: 5px;
  
    box-shadow: 0px 4px 4px rgba(104, 0, 130, 0.25);
    padding-left: 10px;
    background-color: transparent;
    color: #FFFFFF;
  }
  
  .popup-dropdown-item{
    transition: all 0.2s;
    cursor: pointer;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    padding-top: 5px;
    padding-bottom: 5px;
  }

.popup-limited-spots-grooming{

    display: flex;
    justify-content: center;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;

    margin-top: 5px;
}

.popup-dropdown{
    margin-top: -20px;
}

.popup-dropdown-price{
    display: flex;
    justify-content: flex-end;
    padding-right: 25px;
}

.popup-dropdown-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.popup-selected-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.popup-selected-price{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
} */

.form-row-1{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    margin-top: 10px;
}

.form-firstname{
    width: 100%;
    height: 32px;
  
    border: 2px solid #FF5E95;
    filter: drop-shadow(0px 4px 4px rgba(255, 94, 149, 0.25));
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
  
    background-color: transparent;
    color: white;
    margin-right: 5px;
    outline: none;
  }
  
  .form-firstname::placeholder{
    color: rgba(255, 255, 255, 0.7);
    padding: 3px;
  }

  .form-lastname{
    width: 100%;
    height: 32px;
  
    border: 2px solid #FF5E95;
    filter: drop-shadow(0px 4px 4px rgba(255, 94, 149, 0.25));
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
  
    background-color: transparent;
    color: white;

    margin-left: 5px;
    outline: none;
  }
  
  .form-lastname::placeholder{
    color: rgba(255, 255, 255, 0.7);
    padding: 3px;
  }

  .form-date{
    width: 100%;
    height: 32px;
  
    border: 2px solid #FF5E95;
    filter: drop-shadow(0px 4px 4px rgba(255, 94, 149, 0.25));
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
  
    background-color: transparent;
    color: white;

    margin-right: 5px;
    outline: none;
  }
  
  .form-date::placeholder{
    color: rgba(255, 255, 255, 0.7);
    padding-left: 3px;
  }

  .form-time{
    width: 100%;
    height: 32px;
  
    border: 2px solid #FF5E95;
    filter: drop-shadow(0px 4px 4px rgba(255, 94, 149, 0.25));
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
  
    background-color: transparent;
    color: white;

    margin-left: 5px;
    outline: none;
  }
  
  .form-time::placeholder{
    color: rgba(255, 255, 255, 0.7);
    padding-left: 3px;
  }

  .form-address{
    height: 32px;
    border: 2px solid #FF5E95;
    filter: drop-shadow(0px 4px 4px rgba(255, 94, 149, 0.25));
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
  
    background-color: transparent;
    color: white;
    outline: none;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .form-address::placeholder{
    color: rgba(255, 255, 255, 0.7);
    padding-left: 3px;
  }

  .popup-grooming-subtitle{
    display: flex;
    justify-content: center;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .grooming-title{
    margin-top: 0 !important;
  }

  .grooming-form{
    position: absolute !important;
    top: 190px !important;
  }

  .grooming-footer{
    bottom: 10px !important;
  }

  .intro-btn-grooming{
    position: absolute ;
    bottom: 140px ;
    left: 17px;
  }

  .intro-btn-grooming-desktop{
    position: absolute ;
    top: 0px;
    left: 0px;
  }

  .book-btn-grooming{
    width: 179px !important;
    height: 47.43px !important;
  }


  @media only screen and (min-width: 600px){
    .intro-btn-grooming{
      bottom: 40%;
      left: 17px;
    }
  }

  @media only screen and (min-width: 800px){
    .intro-btn-grooming{
      bottom: 50%;
      left: 17px;
    }
  }

  @media only screen and (max-width: 400px) {
    .grooming-intro{
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .grooming-intro{
      width: 100%;
    }
  }

  @media screen and (min-width: 800px){
    .grooming-intro{
      transform: scale(0.8);
      margin-top: 0px;
    }
  }

  .grooming-slide-text{
    margin-top: 25px !important;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 22px;
    color: #FFFFFF;
  }

  .grooming-slide-text-desktop{
    margin-top: 25px !important;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 30px !important;
    color: #FFFFFF;

    width: 280px;
  }

  .pink-container-left-box1-line{
    border: 3px solid rgba(254, 119, 152, 1);
    border-radius: 10px;

    width: 150px;

    position: absolute;
  }

  .intro-grooming-text{
    margin-top: 80px;

    font-size: 20px;
  }


  .intro-grooming-text-training{
    margin-top: 150px;

    font-size: 20px;
  }

  .grooming-page-packages-heading{
    font-family: "DM Sans";
    font-size: 50px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    color: rgba(88, 88, 88, 1);

  }

  .grooming-package-1-desktop{

    width: 500px !important;

    display: flex;
    flex-direction: column;
  }

  .grooming-page-packages-heading{
    position: relative;

    width: 100%;
  }

  .bold-pink-line-desktop-grooming-package{
    border: 3px solid rgba(254, 119, 152, 1);
    width: 150px;
    border-radius: 10px;
  
    position: absolute;
    left: 0px;
    top: 110px;
  }

  .grooming-page-packages-cta{
    margin-top: 50px;

    position: absolute;
    top: 150px;
  }

  .grooming-page-packages-cta2{
    margin-top: 5px;

    position: absolute;
    top: 150px;
  }

  .grooming-page-trainer-meet-background{
    width: 100vw;
  }

  .grooming-page-trainer-meet{
    display: flex;
    justify-content: space-between;
    padding: 150px 120px;
    align-items: center;
    font-family: "DM Sans";
    color: white;

    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_2_kwdu9p.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;

  }

  .training-page-trainer-meet{

    font-family: "DM Sans";
    color: white;

    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_2_sovzld.png");
    background-repeat: no-repeat;
    background-size: cover;

    height: 70vh;
    padding: 150px 120px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .walking-page-trainer-meet{
    position: relative;

    font-family: "DM Sans";
    color: white;

    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1078_gwnsbt.png");
    background-repeat: no-repeat;
    background-size: cover;

    height: 70vh;

    padding: 50px 0px;
  }


.grooming-page-trainer-meet-left{

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-size: 48px;
  font-weight: 500;

  color: white;

  position: relative;
  max-width: 400px;

}

.training-page-trainer-meet-left{

  font-size: 50px;
  font-weight: 500;

  color: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;

}

.walking-page-trainer-meet-left{
  font-family: "DM Sans";
  font-size: 45px;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;

  width: 450px;
  
}

.grooming-page-trainer-meet-right{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.walking-page-trainer-meet-right{

  display: flex;
  justify-content: space-between;

  font-family: "DM Sans";
  font-size: 25px;
}

.grooming-page-trainer-meet-right-head{
  font-size: 36px;
}

.grooming-page-trainer-meet-subtext{
  /* margin-top: 50px; */
  font-size: 20px;
}

.grooming-page-trainer-meet-pink{
  color: rgba(254, 119, 152, 1);
}

.grooming-dog-slide-desktop{
  color: rgba(88, 88, 88, 1);

  font-size: 50px;
}

.dog-slide-desktop-subtext{
  color: rgba(161, 161, 161, 1);

  font-size: 20px;
  margin-top: 70px;
}

.dog-slide-desktop-line{
  border:3px solid rgba(254, 119, 152, 1);
  border-radius: 10px;

  width: 150px;
  position: absolute;
}

.grooming-page-trainer-meet-head2{
  font-size: 24px;
}
.slider-training-packages::-webkit-scrollbar,.slider-grooming-package-mobile::-webkit-scrollbar{
  display: none;
}
.dog-box-desktop-training::-webkit-scrollbar,.dog-box-2::-webkit-scrollbar{
  display: none;
}

/* @keyframes scroll2 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-300%); }
} */
@keyframes scroll1 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-350%); }
}
@keyframes scroll-div {
  0% { transform: translateX(0); }
  100% { transform: translateX(-150%); }
}


.loaderDiv{
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  /* background-color: red; */
    
}
/* Creating the dots */
.loaderDiv>span{
  height: 25px;
  width: 25px;
  /* margin-right: 10px; */
  border-radius: 50%;
  background-color: rgba(254, 119, 152, 1);
  animation: loading 1s linear infinite;
}
/* Creating the loading animation*/
@keyframes loading {
  0%{
   transform: translateX(0);
  }
  25%{
   transform: translateX(15px);
  }
  50%{
   transform: translateX(-15px);
  }
  100%{
   transform: translateX(0);
  }
    
}
.loaderDiv>span:nth-child(1){
 animation-delay: 0.1s;
}
.loaderDiv>span:nth-child(2){
 animation-delay: 0.2s;
}
.loaderDiv>span:nth-child(3){
 animation-delay: 0.3s;
}
.loaderDiv>span:nth-child(4){
 animation-delay: 0.4s;
}
.loaderDiv>span:nth-child(5){
 animation-delay: 0.5s;
}
.grooming-page-banner-mobile{
  width: 100%;
  background: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-page-banner.png");
  background-size: cover;
  height: 420px;
}

.cat-training-page-trainer-meet{

  font-family: "DM Sans";
  color: white;

  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Desktop_Parent_Trainer_Meet.png");
  background-repeat: no-repeat;
  background-size: cover;

  height: 70vh;
  padding: 150px 120px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}