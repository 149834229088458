.refunds_text_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 160px;
}
@media only screen and (max-width: 600px) {
  .refunds_text_wrapper {
    margin: 40px;
  }
  .refunds_heading {
    font-size: 20px;
  }
  .refunds_gray_text {
    font-size: 15px;
  }
  .refunds_text {
    font-size: 18px;
  }
}

.refunds_div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 35px;
  width: 100%;
}
.refunds_heading {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  color: #585858;
  margin: 0;
  margin-bottom: 20px;
}
.refunds_heading_2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #585858;
  margin: 0;
}
.refunds_gray_text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #585858;
  opacity: 0.5;
  margin: 0;
  margin-bottom: 15px;
}
.refunds_text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  color: black;
  margin: 0;
}
.refunds_dot {
  width: 8px;
  height: 8px;
  background-color: #848484;
  border-radius: 50%;
}
.refunds_points {
  display: flex;
  gap: 10px;
}
