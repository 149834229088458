.timeline-item {
  box-sizing: border-box;
}

.timeline-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  position: relative;
  margin: 40px 0;


  margin-left: auto;
  margin-right: auto;

  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.timeline-container-boarding {
  display: flex;
  flex-direction: column;
  justify-content: left;
  position: relative;
  margin: 40px 0;


  margin-left: auto;
  margin-right: auto;

  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.timeline-container::after {
  background-color: #ffb5c7;
  content: "";
  position: absolute;
  left: 47px;
  width: 3px;
  height: 70%;
  margin-top: 8px;
}

.timeline-container-boarding::after {
  background-color: #ffb5c7;
  content: "";
  position: absolute;
  left: 47px;
  width: 3px;
  height: 75%;
  margin-top: 8px;
}

.grey-background-container{
  /* background-color: rgba(248, 248, 248, 1); */
}

.timeline-container:nth-child(5)::after {
  width: 0px;
}

.timeline-container:nth-child(5)::after {
  width: 0px;
}

.timeline-container-boarding:nth-child(5)::after {
  width: 0px;
}

.timeline-item {
  display: flex;
  justify-content: flex-start;
  padding-right: 30px;
  position: relative;
  margin: 10px 0;

  margin-top: 0;
  width: 93%;

  margin-left: 30px;
}

.timeline-item:nth-child(odd) {
  align-self: flex-start;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}

.timeline-item:nth-child(even) {
  align-self: flex-start;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}

.timeline-item-content {
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); */
  border-radius: 5px;
  /* background-color: #FE77981A; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 15px; */
  position: relative;
  width: 400px;
  max-width: 70%;
  text-align: right;
}

.timeline-item:nth-child(odd) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
}

.timeline-item:nth-child(even) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item:nth-child(even) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item-content .circle {
  filter: drop-shadow(0px 4px 10px #fe7798);
  background-color: #fff;
  border: 2px solid #ffb5c7;

  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: -40px;
  width: 40px;
  height: 40px;
  z-index: 100;
}

.timeline-item-content .circle-desktop {
  filter: drop-shadow(0px 4px 10px #fe7798);
  background-color: #fff;
  border: 2px solid #ffb5c7;

  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: -40px;
  width: 40px;
  height: 40px;
  z-index: 100;
}

.circle-desktop-boarding {
  filter: drop-shadow(0px 4px 10px #fe7798);
  background-color: #fff;
  border: 2px solid #ffb5c7;

  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: -40px;
  width: 40px;
  height: 40px;
  z-index: 100;
}

.timeline-item-content-desktop .circle {
  filter: drop-shadow(0px 4px 10px #fe7798);
  background-color: #fff;
  border: 2px solid #ffb5c7;

  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -40px;
  width: 40px;
  height: 40px;
  z-index: 100;
}

.timeline-item-content-desktop .circle-desktop {
  filter: drop-shadow(0px 4px 10px #fe7798);
  background-color: #fff;
  border: 2px solid #ffb5c7;

  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -40px;
  width: 40px;
  height: 40px;
  z-index: 100;
}

.circle-desktop-boarding {
  filter: drop-shadow(0px 4px 10px #fe7798);
  background-color: #fff;
  border: 2px solid #ffb5c7;

  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -40px;
  width: 40px;
  height: 40px;
  z-index: 100;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  left: -51.5px;
}

.timeline-item:nth-child(even) .timeline-item-content .circle {
  right: auto;
  left: -51.5px;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle-desktop {
  right: auto;
  left: -51.5px;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle-desktop-boarding {
  right: auto;
  left: -51.5px;
}

.timeline-item:nth-child(even) .timeline-item-content .circle-desktop {
  right: auto;
  left: -51.5px;
}

.timeline-item:nth-child(even) .timeline-item-content .circle-desktop-boarding {
  right: auto;
  left: -51.5px;
}

@media only screen and (max-width: 1023px) {
  .timeline-item-content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .timeline-item-content,
  .timeline-item:nth-child(odd) .timeline-item-content {
    padding: 15px 10px;
    padding-top: 10px;
  }
}

.timeline-item-content div {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #5e5e5e;
}

.timeline-item-content p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #848484;
}

.timeline-point {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;

  color: #5e5e5e;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  padding-top: 5px;
}

.timeline-item-content a {
  text-decoration: none;
}

.timeline-text span {
  color: #fe7798;
}


@media screen and (min-width >= 1000px){
  .timeline-item-content .circle {
    filter: drop-shadow(0px 4px 10px #fe7798);
    background-color: #fff;
    border: 2px solid #ffb5c7;
  
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: -40px;
    width: 40px;
    height: 40px;
    z-index: 100;
  }
}