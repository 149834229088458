.wrapper {
  background-image:url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/christmas-footer.png");
  height:60px;
  background-repeat: no-repeat;
  background-size:cover;
  position: absolute;
  bottom:80px;
  width: 100%;
}

.footerNav {
  position: fixed;
  bottom: 0;
  width: 100%;

  z-index: 100;

  box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 8px;
  transition: all 300ms;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: #787878;
}

.innerContainer{
  width: 100%;
}

.footerNav:hover{
  box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 8px;
}

.footerNav > div {
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  
}

.footerNav_link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  font-weight: normal;
}

.activeFooterNav_link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  /* font-weight: bold; */
}

.servicesIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -40px;
  background-color: #ff5e95;
  border-radius: 50%;
  padding: 15px;
  border: 10px solid rgb(255, 255, 255);
  opacity: 1;
}

.servicesIconActive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -40px;
  background-color: #ff5e95;
  border-radius: 50%;
  padding: 15px;
  border: 10px solid rgb(255, 255, 255);
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 8px;
}


.serviceIcon::before{

}


.activeFooterNav_link {
  position: relative;
}

.activeFooterNav_link_social {
  position: relative;
}

.activeSocialsNavIcon{
  position: absolute;
}

.activeFooterNav_link_services{
  position: relative;
}

.activeFooterNav_link_social > img{
  position: absolute;
  top: -50px;
  left: 15px;
}

.activeFooterNav_link > img {

}

.activeFooterNav_link_services > img {
  position: absolute;
  top: -50px;
  left: 5px;
}

.footerNav_link,
.activeFooterNav_link,
.activeFooterNav_link_social
 {
  text-decoration: none;
}
.activeFooterNav_link_services {
  text-decoration: none;
}

.servicesIcon{
  text-decoration: none;
}

.servicesIconActive{
  text-decoration: none;
}

.activeFooterNav_link > p{

}
.activeFooterNav_link_social > p{
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
.activeFooterNav_link_services > p{
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px; 
}

.footerNav_link > p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}

.activeFooterNav_link > p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
}
.servicesIcon > p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.servicesIconActive > p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.footerNav_link > p {
  color: rgba(157, 178, 206, 1);
}

.servicesIcon > p {
  color: #787878;
}

.servicesIconActive > p {
  color: #787878;
}

.activeFooterNav_link > p {
  color: #ff5e95;
}
.activeFooterNav_link_services > p {
  color: #ff5e95;
}
.activeFooterNav_link_social > p {
  color: #ff5e95;
}
