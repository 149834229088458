.help {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: relative;

  position: absolute;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: auto;

  margin-top: 40px;
  margin-bottom: 20px;
}

.contact-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  text-align: center;

  color: #5e5e5e;
}

.contact-type {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;

  color: #5e5e5e;
}

.help-bg {
  margin-top: 20px;
}

@media only screen and (max-width: 400px) {
  .help-bg {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .help-bg {
    width: 100%;
  }
}

.help-accordion {
  margin-left: auto;
  margin-right: auto;
}

.help-page-desktop-view-master-container {
  position: relative;
}

.help-page-desktop-view-intro-container {
  position: absolute;
  top: 0px;
  width: 100%;
}

.help-page-desktop-view-intro-content-master {
  font-family: "DM Sans";
  color: white;
}

.help-page-desktop-view-intro-head {
  font-size: 50px;
  font-weight: 600;
  font-family: "DM Sans";
}

.help-page-desktop-view-intro-bottom-section-container {
  background: #6d6d6d;
  backdrop-filter: blur(12.5px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.help-page-desktop-view-intro-points-container {
  margin-top: 50px;
}

.help-page-desktop-view-intro-bottom-section {
  margin-top: 50px;
  display: flex;
  gap: 40px;
}

.help-page-desktop-view-intro-bottom-section-container {
  padding: 10px 20px;
  width: 250px;
  /* margin-right: 20px; */
}

.help-page-mobile-view-background-image {
  width: 100%;
  height: 150vh;
}

.help-page-mobile-view-intro-info-section {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
}

.help-page-mobile-view-intro-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  color: white;
  font-family: "DM Sans";
}

.help-page-mobile-view-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 25px;
}

.help-page-mobile-view-intro-points-container {
  padding: 20px 20px;
  margin-top: 20px;
}

.help-page-mobile-view-intro-phone-container {
  margin-top: 20px;
  height: 150px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
