.masterContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0px 120px;
    padding-top: 20px;

    z-index: 100;
    width: 100%;
    color: white;
    padding-bottom: 20px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 0.2) 90%, rgba(0, 0, 0, 0) 100%);
}

.masterContainerBlogs{
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0px 120px;
    padding-top: 20px;

    z-index: 100;
    width: 100%;
    color: white;
    padding-bottom: 20px;
}

.right{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;

    font-family: "DM Sans";

    color: white;
}

.text{
    color: black;
    text-decoration: none;

    font-size: 16px;
    color: white;
}

.link{
    text-decoration: none;
    color: white;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link4{
    text-decoration: none;
    color: white;
}

/* .item4{
    background-color: #FF5E95;
    padding: 5px 15px;

    border-radius: 5px;
} */

/* .item4:hover{
    cursor: pointer;
} */

/* .dropdownContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdownIcon{
    margin-left: 5px;
    margin-top: 5px;

    color: white;
}

.dropdownText{
    color: white;
} */





