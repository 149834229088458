.socials{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: relative;

    position:absolute;
    padding:0;
    margin:0;

    top:0;
    left:0;

    width: 100%;
    height: 100%;

    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;

    animation-name: fadein;
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.socials-bg,.socials-intro{
    margin-top: 20px;
}

@media only screen and (max-width: 400px) {
    .socials-bg{
      width: 100%;
    }
    .socials-intro{
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .socials-bg{
      width: 100%;
    }
    .socials-intro{
      width: 100%;
    }
  }
  
  .app-features{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;

    color: #5E5E5E;
    margin-top: 10px;
  }

  .feature-description{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;

    color: #5E5E5E;
    margin-top: 10px;

    padding-left: 50px;
    padding-right: 50px;
  }

  .feature-container,.subtext{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    
    margin-left: auto;
    margin-right: auto;

    margin-top: 10px;
    margin-bottom: 10px;
  }

  .subtext{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #5E5E5E;

  }

.intro-btn-training{
  margin-left: 30px;
}