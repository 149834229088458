
.accordion-item{
    position: relative;

    width: 324px;

    background: #FFFFFF;
    border: 1px solid rgba(177, 177, 177, 0.25);
    box-shadow: 0px 4px 4px rgba(177, 177, 177, 0.25);
    border-radius: 10px;

    margin-top: 10px;
    margin-bottom: 10px;
}

.accordion-item-desktop{
    position: relative;

    width: 100%;

    background: #FFFFFF;
    border: 1px solid rgba(177, 177, 177, 0.25);
    box-shadow: 0px 4px 4px rgba(177, 177, 177, 0.25);
    border-radius: 10px;
    padding: 0px 20px;
    margin-top: 20px;
    margin-bottom: 25px;
}

.accordion-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    
    /* margin: 20px; */
    padding: 15px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: rgba(93, 93, 93, 0.8);
}

.accordion-title-desktop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    
    /* margin: 20px; */
    padding: 15px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: rgba(93, 93, 93, 0.8);
}

.accordion-symbol{
    /* position: absolute;
    right: 20px; */

    text-align: center;
    padding-left: 10px;
}

.accordion-content{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* or 125% */


    color: #5D5D5D;
    padding: 15px;
    padding-top: 0;
}

.accordion-content-desktop{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /* or 125% */


    color: #5D5D5D;
    padding: 15px;
    padding-top: 0;
}