.partner{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: relative;

    position:absolute;
    padding:0;
    margin:0;

    top:0;
    left:0;

    width: 100%;
    height: 100%;

    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;

    animation-name: fadein;

    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/partnermobile.webp");
    background-position:contain;
    background-repeat: no-repeat;
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


@media only screen and (max-width: 400px) {
    .partner-bg{
      width: 100%;
    }
  }
  
@media only screen and (max-width: 500px) {
    .partner-bg{
      width: 100%;
    }
  }
  
.partner-bg{
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: auto; 
    margin-right: auto;
    margin-top: 30px;
}

.partner-form{
    position: relative;
}

.submitted-form{
    position: relative;
    margin-bottom: 10px;
}

.partner-form-box{
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;

    width: 348px;
    /* min-height: 628px; */
    padding-bottom: 10px;

    background: rgba(74, 74, 74, 0.7);
    backdrop-filter: blur(12.5px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    left: 20;
    right: 20;

    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-message{
    padding-left: 30px;
    padding-right: 30px;

    font-family: 'DM Sans';
    font-style: normal;
    font-size: 18px;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    margin-top: 10px;
}

.partner-form-mobile-head{
    color: white;
    font-family: "DM Sans";
    margin-top: 20px;
    font-size: 35px;
}

.partner-contact-container{
    margin-top: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
}

.partner-contact-container-desktop{
    /* margin-top: 40px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.partner-page-desktop-view-section-2-input-container{
    margin-top: 30px;
}

.partner-contact-input{
    width: 314px;
    height: 41px;

    background: #F5F5F5;
    border: 1px solid #A6A6A6;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-left: 10px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #5D5D5D;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    margin-bottom: 12.5px;

    border-radius: 10px;
    outline: none;
}

.partner-interest-text{
    margin-left: 10px;
    color: #727272;
}

.partner-dropdown-img{
    margin-right: 10px;
    margin-top: 11px;
}

.form-point{
    font-family: 'DM Sans';
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: white;
    margin-bottom: 10px;
}

.form-points-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    padding-left: 50px;
    padding-right: 50px;

}

.form-submit-points{
    margin-top: 20px !important;
    padding-left: 70px !important;
    padding-right: 70px !important;
}

.blue-text{
    display: inline;
    color: #2A82D4;
}

.partner-submit-btn{
}

.submit-acknowledgement{
    justify-content: center;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    padding-left: 40px;
    padding-right: 40px;
    margin-top: 25px;
    margin-bottom: 25px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    text-align: center;

    color: #5E5E5E;
}

.partner-footer{
    margin-top: 60px !important;

    margin-left: auto;
    margin-right: auto;
}

.partner-form-container{
}

.partner-title{
    position: absolute; 
    top: 40px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;

    text-align: center;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 35px;

    color: #FFFFFF;
}

.partnership-message{
    position: absolute; 
    top: 100px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;

    text-align: center;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 35px;
    text-align: center;

    color: #FFFFFF;

    padding-left: 30px;
    padding-right: 30px;
}
.dropdown-aoi{
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 40px;
    z-index: 100;

    width: 100%;

    border: 1px solid rgb(181, 181, 181);
    border-radius: 10px;
    background-color: rgba(245, 245, 245, 0.9);
}

.dropdown-aoi-desktop{
    display: flex;
    flex-direction: column;

    position: absolute;

    z-index: 100;
    top: 40px;

    border: 2px solid rgb(181, 181, 181);
    border-radius: 10px;
    width: 100%;
    background-color: rgba(245, 245, 245, 1);
}

.partner-interest-text-dark-desktop{
    margin-left: 10px;
}

.partner-interest-text-dark{
    margin-left: 10px;
}

::placeholder{
    color: #686868;
}

.line{

}

.dropdown-aoi-item1{
    padding: 5px;
    font-family: "DM Sans";
    font-size: 16px;

    color:black;
    padding: 10px 15px 0px 15px;
}

.dropdown-aoi-item2 {
    padding: 5px;
    font-family: "DM Sans";
    font-size: 16px;

    color:black;
    padding: 2px 15px;
}

.dropdown-aoi-item3{
    padding: 5px;
    font-family: "DM Sans";
    font-size: 16px;

    color:black;
    padding: 0px 15px 10px 15px;
}

.dropdown-aoi-item3:hover, .dropdown-aoi-item2:hover, .dropdown-aoi-item1:hover{
    cursor: pointer;
}


.partner-contact-input::placeholder{
    color: rgb(179, 179, 179);
}

.partner-interest-text{
    color:rgb(179, 179, 179);
}

.invalidPhone{
    color: rgb(210, 0, 0);

    font-family: "DM Sans";
    font-size: 13px;

    position: absolute;
    z-index: 100;

    top: -17px;
    right: 5px;
}



.invalidAOI{
    color: rgb(210, 0, 0);

    font-family: "DM Sans";
    font-size: 13px;

    position: absolute;
    z-index: 100;
    right: 5px;
    top: -24px;
}

.partner-page-desktop-view-master-container{
    position: relative;
}

.partner-page-desktop-view-background{
    height: 100vh;
    width: 100%;
}

.partner-page-desktop-view-master{
    position: absolute;
    top: 0px;
    left: 0px;

    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 20px;
}

.partner-page-desktop-view-section-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "DM Sans";
    color: white;
}

.partner-page-desktop-view-section-3{
    display: flex;
    align-items:flex-start;
    justify-content: space-between;
    font-family: "DM Sans";
    color: white;
    width: 100%;
    padding: 20px 120px;
}

.partner-page-desktop-view-section-2-head{
    font-size: 50px;
    font-weight: 700;
}
.partner-page-desktop-view-section-2-subhead{
    width: 400px;
    text-align: center;
    margin-top: 10px;
    font-size: 20px;
}
.partner-page-desktop-view-section-2-submit{
    /* background-color: rgba(253, 122, 155, 1); */
    
    border-radius: 10px;
    padding: 10px 25px;
    animation: linear;
    animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;
    background: linear-gradient( 132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100% ), rgb(222,194,105);
    animation-name: shine;
    animation-fill-mode: forwards;
}

.partner-page-desktop-view-section-2-submit:hover{
    cursor: pointer;
}

.partner-page-desktop-view-section-2-disclaimer{
    margin-top: 30px;
    font-size: 18px;
    width: 450px;
    text-align: center;
}

.privacy-statement-partner:hover{
    cursor: pointer;
    text-decoration: none;
}

.partner-page-desktop-view-section-3-1{
    font-size: 18px;
    width: 350px;
}

.partner-page-desktop-view-section-3-2{
    font-size: 18px;
    width: 350px;
}

.partner-page-desktop-view-section-3-3{
    font-size: 18px;
    width: 350px;
}