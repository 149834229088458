@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Water+Brush&display=swap");


.landingpage {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: relative;
  font-display: swap;

  position: absolute;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;

  animation-name: fadein;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.arrowIcon {
  color: white;
  background-color: white;
}

.navbar {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  /* align-items:  */
  margin-bottom: 60px;
  margin-top: 30px;
  align-items: center;
}

.navbar-blogDetail {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 90%;

  right: 7%;
  top: 3%;
}

.navbar-partner {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  /* align-items:  */
  margin-bottom: 0px;
  margin-top: 30px;
  align-items: center;
}

.gst {
  font-size: 13px;
}

.secParagraph {
  margin-top: 10px;
}

.navbar-bottomSpace {
  position: relative;
  display: flex;
  justify-content: space-between;
  /* align-items:  */
  margin-bottom: 30px;
  margin-top: 30px;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
}

.navbar-bottomSpace2 {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  /* align-items:  */
  margin-bottom: 15px;
  margin-top: 30px;
  align-items: center;
}

.navbar-bottomSpace3 {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  /* align-items:  */
  margin-bottom: 5px;
  margin-top: 30px;
  align-items: center;
}

.navbar-bottomSpace4 {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  /* align-items:  */
  margin-top: 30px;
  align-items: center;
}

.circle {
  margin-top: 8px;
}

.circle-desktop {
  margin-top: 7px;
}

.circle-desktop-boarding {
  margin-top: 5px;
}

.navbar-blogs {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  /* align-items:  */
  margin-top: 30px;
  align-items: center;
}

.boarding-page-desktop-view-standard-upper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.boarding-page-desktop-view-standard-lower {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 00px;
}

.boarding-page-desktop-view-standard-image-conatiner {
  position: relative;
}

.bording-page-mobile-training-master-container {
  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/doggo-1.png");
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 20px 20px;

  font-family: "DM Sans";
  color: white;
}

.bording-page-mobile-training-section1-head {
  font-size: 30px;
  text-align: center;
}

.boarding-page-mobile-view-standard-master-container {
  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/doggo-0.png");
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px 20px;

  font-family: "DM Sans";
  color: white;
}

.boarding-page-mobile-standard-heading {
  font-size: 32px;
}

.boarding-page-desktop-view-standard-inner-text1 {
  position: absolute;
  font-family: "DM Sans";
  color: white;
  font-size: 20px;

  top: 80px;
  text-align: center;

  padding: 0px 10px;
}

.boarding-page-desktop-view-standard-text-conatiner-text {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.boarding-page-desktop-view-standard-text-conatiner {
  color: white;
  font-family: "DM Sans";

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.boarding-page-desktop-view-standard-master-container {
  padding: 70px 100px;
}

.boarding-page-desktop-view-standard-master-container {
  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/doggo-2.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-services {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  /* align-items:  */
  margin-bottom: 30px;
  margin-top: 30px;
  align-items: center;
}

.area-interest-container {
  width: 314px;
  height: 41px;

  background: #f5f5f5;
  border: 1px solid #a6a6a6;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-left: 0px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #5d5d5d;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-left: auto;
  margin-right: auto;

  margin-bottom: 12.5px;

  border-radius: 10px;
  outline: none;

  margin-top: 10px;

  position: relative;
}

.canine-misbehave-small {
  font-size: 10px;
}

.area-interest-container-desktop {
  width: 314px;
  height: 41px;

  background: #f5f5f5;
  border: 1px solid #a6a6a6;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #5d5d5d;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-left: auto;
  margin-right: auto;

  margin-bottom: 12.5px;

  border-radius: 10px;
  outline: none;

  margin-top: 10px;

  position: relative;
}

.petmojo-logo {
  width: 190px;
  height: 70px;
}

.nav-menu {
  vertical-align: middle;

  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: white;
}

.textbox {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: #585858;
  margin: 25px;
  padding: 20px;
  font-size: 32px;
}

.pinktext {
  color: #ff5e95;
  font-weight: bold;
}

.intro-container-landing-container {
  display: flex;
}

.intro-container-landing-left {
  display: flex;
  flex-direction: column;
}

.intro-container {
  position: relative;
  width: 100%;
  margin-bottom: 100px;

  display: flex;
  justify-content: space-between;

  margin-right: 120px;
  margin-top: 30px;
}

.m {
  margin-top: 40px;
}

.intro-container-socials {
  position: relative;
  width: 100%;

  display: flex;
  justify-content: space-between;

  margin-right: 120px;
  margin-top: 30px;
}

.intro-container-home-page-mobile-view {
  position: relative;
  width: 100%;

  display: flex;
  justify-content: space-between;
}

.intro-container-grooming-mobile-view {
  position: relative;
  width: 100%;

  display: flex;
  justify-content: space-between;

  margin-right: 120px;
  margin-top: 30px;
}

.homepage-intro {
}

.home-intro-image {
  position: relative;
  margin-top: -80px;
}

.home-intro-text {
  font-family: "DM Sans";
  font-size: 50px;

  color: rgba(88, 88, 88, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 1600px;
  margin-left: 100px;
  margin-top: 100px;
}

.home-intro-text-line-pink {
  color: rgba(255, 94, 149, 1);
}

.intro-container-walking {
  display: flex;
  justify-content: right;
  flex-direction: column;
  margin-top: 20px;
  position: relative;
  width: 100%;
}

.heading-subtext-container {
  display: flex;
  justify-content: center;
  font-family: "DM Sans";
  font-size: 20px;
}

.heading-subtext {
  color: #5e5e5e;
}

.pink-heading-subtext {
  color: #fe7798;
}

.glowBtn {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    #DEC269;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  animation-name: shine;
}

.booksession-mobile-form {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);

  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 160px;
  height: 40px;
}

.booksession-mobile-form-2 {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);

  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 100px;
  height: 40px;
}

.mobile-view-top-margin {
  background-color: rgba(248, 248, 248, 1);
  margin-top: 30px;
}

.nav-mobile-view-downloads-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 50px;
}

.nav-mobile-view-google-icon {
  width: 130px;
}

@media screen and (min-width: 515px) {
  .mobile-view-top-margin {
    margin-top: 120px;
  }
}

.booksession-grooming {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    #DEC269;
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 0px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;

  margin-top: 40px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;
}

.booksession-grooming-2 {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    #DEC269;
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 162px;
  height: 36px;
}

.booksession {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;

  /* margin-top: 40px; */

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 20rem;
  height: 48px;
}
.booksession-track{
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;

  /* margin-top: 40px; */

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  /* width: 100%; */
  height: 48px;
}
.booksession-mobile-view-golden{
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
    132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;

  margin-top: 40px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 190px;
  height: 48px;  
}
.booksession-mobile-view {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;

  margin-top: 40px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 190px;
  height: 48px;
  /* margin-bottom: 30px; */
}

.booksession-mobile-view-grooming {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;

  margin-top: 40px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 220px;
  height: 48px;
  margin-bottom: 30px;
}
.booksession-golden-cta{
  border-radius: 10px;
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;

  margin-top: 40px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);
  width: 220px;
  height: 48px;
  margin-bottom: 30px;
  background: linear-gradient(132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%);
}

.booksession-mobile-view-form {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;

  margin-top: 40px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 190px;
  height: 48px;
}

.booksession-mobile-view-grooming-form {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;

  margin-top: 40px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 230px;
  height: 48px;
}

.booksession-mobile-view-grooming-form-grooming {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;
  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;

  margin-top: 40px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 100%;
  height: 48px;
}

.booksession-mobile-view-nav {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 5px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 140px;
  height: 40px;
}

.booksession-mobile-view-form {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;

  margin-top: 20px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 140px;
  height: 42px;
}

.booksession-mobile-view2 {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 190px;
  height: 48px;
}

.booksession-form {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 250px;
  height: 48px;
}

.booksession-form:hover {
  cursor: pointer;
}

.booksession-navigation-desktop {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 250px;
  height: 48px;
  color: #000;
}

.booksession-circle {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;

  margin-top: 40px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 50%;

  width: 120px;
  height: 120px;
}

.booksession-grooming-form {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    #DEC269;
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;

  margin-top: 40px;

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 100%;
  height: 46px;
}

.grey-container {
  background-color: #f8f8f8;
}

@keyframes shine {
  0% {
    background-position: -450px 0;
  }
  10% {
    background-position: -350px 0;
  }
  20% {
    background-position: -100px 0;
  }
  30% {
    background-position: 50px 0;
  }
  40% {
    background-position: 100px 0;
  }
  50% {
    background-position: 125px 0;
  }
  60% {
    background-position: 150px 0;
  }
  70% {
    background-position: 275px 0;
  }
  80% {
    background-position: 300px 0;
  }
  90% {
    background-position: 325px 0;
  }
  100% {
    background-position: 350px 0;
  }
}

.limitedslots {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  text-align: center;

  color: #ff2323;

  margin-top: 10px;
  margin-bottom: 20px;
}

.boarding-mobile {
  display: flex;
  flex-direction: column;
  width: 100vw;
  /* height: 100vh; */
  position: relative;

  position: absolute;
  padding: 0;
  margin: 0;

  top: 0px;
  left: 0px;

  width: 100%;
  /* height: 100%; */
  background-color: rgba(248, 248, 248, 1);
}

.grooming-page-mobile-view-form-master-text{
  font-size: 20px;
  text-align: center;
}

.boarding-desktop {
  display: flex;
  flex-direction: column;
  width: 100vw;
  /* height: 100vh; */
  position: relative;
  /* position: absolute; */

  position: absolute;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.textbox2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: #585858;

  font-size: 25px;
  text-align: center;
  margin-top: 0px;

  padding-right: 15px;
  padding-left: 15px;
}
.textbox2-get-pet-grooming {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: #585858;

  font-size: 25px;
  text-align: center;
  margin-top: 00px;

  padding-right: 45px;
  padding-left: 45px;
}

.textbox2-walking-mobile {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: #585858;

  font-size: 25px;
  text-align: center;

  padding-right: 15px;
  padding-left: 15px;

  background-color: rgba(248, 248, 248, 1);
}

.textbox2-desktop {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  color: #585858;
  width: 400px;
  font-size: 50px;
  text-align: center;
  margin-top: 20px;
  text-align: left;
}

.textbox2-desktop-walking {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  color: #585858;

  font-size: 50px;
  text-align: center;
  margin-top: 20px;

  padding-right: 15px;
  padding-left: 15px;
}

.textbox2-desktop-faq {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: #585858;

  font-size: 50px;
  text-align: center;
  margin-top: 20px;

  padding-right: 15px;
}

.bold-pink-line-faq {
  border: 3px solid rgba(254, 119, 152, 1);
  border-radius: 10px;
  width: 150px;

  position: absolute;
  left: 40px;
  top: 200px;
}

.textbox2-desktop-live {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: #585858;

  font-size: 50px;
  text-align: center;
  margin-top: 20px;

  width: 50%;
  margin-left: 120px;
}

.live-left-heading {
  font-family: "DM Sans";
  color: rgba(88, 88, 88, 1);
}

.live-left-heading-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-size: 50px;
}

.live-training-pink-line {
  border: 4px solid rgba(254, 119, 152, 1);
  border-radius: 10px;
  width: 150px;
  margin-left: 0px;
  /* margin: 1rem 0; */
}

.relative-line {
  position: absolute;
  bottom: -5px;
  left: 0px;
}

.storeContainer_heading {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 55px;
  color: #585858;
  margin: 0;
}

.storeContainer_text {
  margin: 0;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: #848484;
}

.relative-line-right {
  position: absolute;
  bottom: -5px;
  right: 0px;
}

.boarding-page-desktop-view-standard-subtext {
  font-size: 20px;
  text-align: right;
}

.boarding-page-desktop-view-standard-text-conatiner {
  max-width: 320px;
}

.standard-and-safety {
  text-align: right;
}

.boarding-page-desktop-view-meet-right-head {
  font-size: 36px;
}

.boarding-page-desktop-view-right-container-subtext {
  font-size: 20px;
}

.boarding-page-desktop-view-meet-right-container {
  max-width: 650px;
}

.relative-line2 {
  position: absolute;
  bottom: -20px;
  left: 0px;
}

.heart-section-head-1 {
  position: relative;
}

.line-div {
  display: flex;
  justify-content: flex-start;
}

.line-div2 {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.live-left-heading-inner1 {
  position: relative;
}

.red-dot-desktop {
  position: absolute;
  top: 10px;
  right: 0px;
}

.red {
  color: red;

  position: relative;
}

.live-master-container-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 30px 120px;
}

.liveLowerContainer {
  margin-top: 40px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-left: 40px;
  margin-right: 40px;
}

.liveButton {
  color: rgba(255, 118, 152, 1);

  font-family: "DM Sans";
  font-size: 20px;
  padding: 5px 20px;
  font-weight: 700;

  border: 2px solid;
  border-radius: 5px;
  background-color: white;

  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

.landingBlogsContainer {
  font-family: "DM Sans";

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.landingBlogsContent {
  margin-top: 40px;
}

.landingBlogsContent1 {
  color: rgba(161, 161, 161, 1);
  font-size: 20px;
}

.landingBlogsHelp {
  margin-top: 20px;

  font-size: 27px;
}

.pinkHelp {
  color: rgba(254, 119, 152, 1);
}

.desktopLine {
  border: 3px solid rgba(255, 118, 152, 1);
  border-radius: 10px;
  width: 200px;

  position: absolute;
}

.landingBlogsHeader {
  font-size: 50px;

  color: rgba(88, 88, 88, 1);
}

.liveLowerLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  margin-top: 130px;
}

.liveLowerRight {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
}

.live-lower-right {
  margin-bottom: 100px;
}

.liveDisclaimer {
  color: rgba(88, 88, 88, 1);
  font-family: "DM Sans";
  font-size: 24px;

  text-align: center;
  margin-top: 55px;
}

.textbox2-grooming {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: white;

  font-size: 25px;
  text-align: center;
  margin-top: 20px;

  padding-right: 15px;
  padding-left: 15px;
}

.textbox2-space {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: #585858;

  font-size: 25px;
  text-align: center;
  margin-top: 40px;

  padding-right: 15px;
  padding-left: 15px;
}

.textbox2-services {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: #585858;

  font-size: 25px;
  text-align: center;

  padding-right: 15px;
  padding-left: 15px;
}

.new-textbox2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;

  color: white;

  font-size: 25px;
  text-align: center;

  padding-right: 15px;
  padding-left: 15px;
}

.textbox1 {
  font-size: 32px;
}

.walking-info {
}

.vector1 {
  width: 104px;
  text-align: center;
  border: 1px solid #fe7798;
  margin-bottom: 15px;
  color: #fe7798;
  background-color: #fe7798;
}

.view-more-container {
}

.view-more-box {
  position: relative;
  top: 70px;
  left: 70px;
}

.view-more-text {
  position: relative;
}

.heartimg {
  margin-top: 10px;
  text-align: center;
}

.white-space {
  display: flex;
  flex-direction: column;
}

.vector2 {
  width: 133px;
  text-align: center;
  border: 1px solid #fe7798;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #fe7798;
  background-color: #fe7798;
}

.socials-page-mobile-view-carousel {
  background-color: rgba(248, 248, 248, 1);
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "DM Sans";
}

.social-page-mobile-view-header {
  font-size: 25px;
  color: rgba(94, 94, 94, 1);
}

.social-page-slider-master {
  width: 100%;
}

.socials-page-mobile-view-subhead {
  text-align: center;
  color: rgba(94, 94, 94, 1);
  margin-bottom: 40px;
}

.social-page-mobile-view-card-container {
  border: 1px solid #dedede;
  padding: 5px 10px;
  /* margin-bottom: 10rem; */
  margin-right: 20px;
  border-radius: 10px;
  padding-bottom: 10px;
}

.social-page-mobile-view-card-img {
  width: 200px;
  height: 180px;
}

.social-page-slider-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 100%;
}

.social-page-slider-container::-webkit-scrollbar {
  margin-top: 1rem;
  width: 21px;
  height: 8px;
}

.social-page-slider-container::-webkit-scrollbar-track {
  width: 189px;
  height: 8px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.social-page-slider-container::-webkit-scrollbar-thumb {
  width: 24px;
  height: 8px;
  border-radius: 50px;
  background: #fe7798;
}

.social-page-mobile-view-card-title {
  font-family: "DM Sans";
  font-size: 20px;
  color: rgba(94, 94, 94, 1);
  margin-top: 5px;
}

.social-page-mobile-blog-text {
  font-family: "DM Sans";
  color: rgba(94, 94, 94, 1);
  font-size: 14px;
}

.social-page-mobile-view-card-read {
  margin-top: 5px;
  text-decoration: none;
  color: royalblue;
  font-family: "DM Sans";
}

.socials-page-mobile-btn {
  margin-bottom: 30px;
}

.vector2-social-mobile {
  width: 133px;
  text-align: center;
  border: 1px solid #fe7798;
  margin-top: 20px;
  margin-bottom: 45px;
  color: #fe7798;
  background-color: #fe7798;
}

.vector2-pet-parents {
  width: 133px;
  text-align: center;
  border: 1px solid #fe7798;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #fe7798;
  background-color: #fe7798;
  position: absolute;
  top: 5px;
  right: 10px;
}

.vector2-footer {
  width: 133px;
  text-align: center;
  border: 1px solid #fe7798;
  margin-top: 10px;
  margin-bottom: 15px;
  color: #fe7798;
  background-color: #fe7798;
}

.vector2-spaceBelow {
  width: 133px;
  text-align: center;
  border: 1px solid #fe7798;
  margin-top: 20px;
  color: #fe7798;
  background-color: #fe7798;
}

.slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  width: 100%;
}

.book-now-button-mobile-view {
  margin-left: 10px;
}

.padding-20 {
  padding: 0px 20px;
}

.slider-container-grey-background {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  background-color: rgba(248, 248, 248, 1);
}

.slider-container-desktop-training {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  /* overflow-x: scroll; */

  width: 100%;
}

.slider-container-desktop-training2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  width: 100%;
}

.slider-container-desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  width: 100%;

  margin-top: 100px;
}

.slider-container-desktop-grooming {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  width: 100%;

  padding: 60px 120px;
  background-color: rgba(248, 248, 248, 1);
}

.bold-pink-line-desktop {
  border: 3px solid rgba(254, 119, 152, 1);
  width: 150px;
  border-radius: 10px;

  position: absolute;
  left: 0px;
}

.heroImage1Container {
  display: flex;
  justify-content: center;
}

.heroImage1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.petmojo-partner-mobile-view-name {
  position: absolute;
  bottom: 15px;
  font-family: "DM Sans";
  color: rgba(88, 88, 88, 1);
  font-size: 18px;
}

.petmojo-partner-mobile-view-name-cursive {
  font-family: "Damion", cursive;
}

.heroImage1-grooming {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
}

.make-pet-smile-head {
  position: absolute;
  top: 10px;
}

.slider {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 350px;
  margin: 20px;
  text-align: center;
  /* border-radius: 20px; */
  overflow: scroll;
  overflow-y: hidden;
}

.slider-training-package-mobile {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 550px;
  text-align: center;
  /* border-radius: 20px; */
  overflow: scroll;
  overflow-y: hidden;
  /* margin-left: 20px;
  margin-right: 20px; */
}

.slider-training-package-desktop {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 550px;
  text-align: center;
  border-radius: 20px;
  overflow: scroll;
  overflow-y: hidden;
  /* margin-left: 20px;
  margin-right: 20px; */
}

.slider-grooming-package-mobile {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 560px;
  text-align: center;
  /* border-radius: 20px; */
  overflow: scroll;
  overflow-y: hidden;
  margin-top: 30px;
}

.slider-grooming-package-mobile::-webkit-scrollbar {
  margin-top: 1rem;
  width: 21px;
  height: 6px;
}

.slider-grooming-package-mobile::-webkit-scrollbar-track {
  width: 189px;
  height: 6px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.slider-grooming-package-mobile::-webkit-scrollbar-thumb {
  width: 24px;
  height: 6px;
  border-radius: 50px;
  background: #fe7798;
}

.subpackages-subtext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  color: white;
  font-family: "DM Sans";
}

.slider-grooming-packages {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 420px;
  margin: 20px;
  text-align: center;
  border-radius: 20px;
  overflow: scroll;
  overflow-y: hidden;
}

.slider-grooming-packages::-webkit-scrollbar {
  margin-top: 1rem;
  width: 21px;
  height: 8px;
}

.slider-grooming-packages::-webkit-scrollbar-track {
  width: 189px;
  height: 8px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.slider-grooming-packages::-webkit-scrollbar-thumb {
  width: 24px;
  height: 8px;
  border-radius: 50px;
  background: #fe7798;
}

.slider-training-packages {
  /* display: flex;
  flex-direction: row;
  width: 100%;
  height: 390px;
  margin: 20px;
  text-align: center;
  border-radius: 20px;
  overflow: scroll;
  overflow-y: hidden; */

  display: flex;
  flex-direction: row;

  width: 60vw;
  overflow-x: scroll;
  /* border-radius: 20px; */
  height: 550px;
  overflow-y: hidden;
  margin: 20px;
  text-align: center;
}

.slider-training-packages-styling {
  /* display: flex;
  flex-direction: row;
  width: 100%;
  height: 390px;
  margin: 20px;
  text-align: center;
  border-radius: 20px;
  overflow: scroll;
  overflow-y: hidden; */

  display: flex;
  flex-direction: row;

  width: 60vw;
  border-radius: 20px;
  height: 550px;
  overflow-y: hidden;
  margin: 20px;
  text-align: center;
}

.slider-training-packages::-webkit-scrollbar,
.slider-training-packages-styling::-webkit-scrollbar {
  margin-top: 1rem;
  width: 21px;
  height: 8px;
}

.slider-training-packages::-webkit-scrollbar-track,
.slider-training-packages-styling::-webkit-scrollbar {
  width: 189px;
  height: 8px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.slider-training-packages::-webkit-scrollbar-thumb,
.slider-training-packages-styling::-webkit-scrollbar {
  width: 24px;
  height: 8px;
  border-radius: 50px;

  background: #fe7798;
}

.slider-training-packages:hover::-webkit-scrollbar,
.slider-training-packages-styling::-webkit-scrollbar {
  /* height: 14px; */
}

.slider-training-packages:hover::-webkit-scrollbar-track,
.slider-training-packages-styling::-webkit-scrollbar {
  /* height: 14px; */
}

.slider-training-packages:hover::-webkit-scrollbar-thumb,
.slider-training-packages-styling::-webkit-scrollbar {
  /* height: 14px; */
}

.slider-landingGrooming {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 550px;
  margin: 20px;
  text-align: center;
  border-radius: 20px;
  overflow: scroll;
}

.slider-landingWalking {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 350px;
  margin: 20px;
  text-align: center;
  border-radius: 20px;
  overflow: scroll;
  overflow-y: hidden;
}

.slider-desktop {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 400px;
  margin: 20px;
  text-align: center;
  border-radius: 20px;
  overflow: scroll;
  overflow-y: hidden;
}

.slider-desktop-home-page-training {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  height: 400px;
  text-align: center;
  border-radius: 20px;
  overflow: scroll;
  overflow-y: hidden;
  transition: all 0.5s;
  /* background-color: black; */
}

.slider::-webkit-scrollbar {
  margin-top: 1rem;
  width: 21px;
  height: 8px;
}

.slider:hover::-webkit-scrollbar {
  margin-top: 1rem;
  width: 21px;
  /* height: 14px; */
}

.slider-training-package-mobile::-webkit-scrollbar {
  margin-top: 1rem;
  width: 21px;
  height: 8px;
}

.slider::-webkit-scrollbar-track {
  width: 189px;
  height: 8px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.slider:hover::-webkit-scrollbar-track {
  /* height: 14px; */
}

.slider-training-package-mobile::-webkit-scrollbar-track {
  width: 189px;
  height: 6px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.sliderhome-page-training::-webkit-scrollbar-track {
  width: 189px;
  height: 8px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.slider::-webkit-scrollbar-thumb {
  width: 24px;
  height: 8px;
  border-radius: 50px;
  background: #fe7798;
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25); */
  /* border-radius: 50px; */
  /* border-top: 0.55rem solid #fff; */
}

.slider:hover::-webkit-scrollbar-thumb {
  width: 24px;
  /* height: 14px; */
  border-radius: 50px;
  background: #fe7798;
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25); */
  /* border-radius: 50px; */
  /* border-top: 0.55rem solid #fff; */
}

.slider-training-package-mobile::-webkit-scrollbar-thumb {
  width: 24px;
  height: 8px;
  border-radius: 50px;
  background: #fe7798;
}

.slider-desktop::-webkit-scrollbar {
  margin-top: 1rem;
  width: 21px;
  height: 8px;
}

.slider-desktop-home-page-training::-webkit-scrollbar {
  margin-top: 1rem;
  width: 21px;
  height: 8px;
  transition: all 1s;
}

.slider-desktop-home-page-training:hover::-webkit-scrollbar {
  /* height: 14px; */
  cursor: pointer;
}

.slider-desktop::-webkit-scrollbar-track {
  width: 189px;
  height: 8px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.slider-desktop-home-page-training::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  transition: all 1s;
}

.slider-desktop::-webkit-scrollbar-thumb {
  width: 24px;
  height: 8px;

  background: #fe7798;
  border-radius: 50px;
}

.slider-desktop-home-page-training::-webkit-scrollbar-thumb {
  width: 40%;
  height: 8px;

  background: #fe7798;
  border-radius: 50px;
  transition: all 1s;
}

.slider-desktop-home-page-training:hover::-webkit-scrollbar-thumb {
  /* height: 14px; */
  cursor: pointer;
}

.slide {
  min-width: 320px;
  height: 21rem;
  margin-right: 10px;

  background: linear-gradient(180deg, #fd6b6a 0%, #e60f7d 100%);
  border-radius: 20px;
  position: relative;
}

.slide-home-page-desktop-training {
  min-width: 320px;
  height: 21rem;
  margin-right: 10px;

  background: linear-gradient(180deg, #fd6b6a 0%, #e60f7d 100%);
  border-radius: 20px;
  position: relative;
}

.slide-view-more {
  min-width: 320px;
  height: 21rem;
  margin-right: 10px;

  border-radius: 20px;
  position: relative;
}

.slide-desktop-grooming-packages {
  min-width: 340px;
  height: 25rem;
  margin-right: 10px;

  background: linear-gradient(180deg, #fd6b6a 0%, #e60f7d 100%);
  border-radius: 20px;
  position: relative;
}

.slide-desktop-grooming-packages-revert {
  min-width: 340px;
  height: 25rem;
  margin-right: 10px;

  background: linear-gradient(0deg, #fd6b6a 0%, #e60f7d 100%);
  border-radius: 20px;
  position: relative;
}

.slide-desktop-training-packages {
  min-width: 330px;
  height: 23rem;
  margin-right: 10px;

  background: linear-gradient(180deg, #fd6b6a 0%, #e60f7d 100%);
  border-radius: 20px;
  position: relative;
}

.slide-grooming-package {
  min-width: 380px;
  height: 21rem;
  margin-right: 10px;

  border-radius: 20px;
  position: relative;
}

.training-package-1-pro {
  min-width: 320px;
  height: 21rem;
  margin-right: 10px;

  background: #fd6b6a;
  border-radius: 20px;
  position: relative;
}

.training-package-2-pro {
  min-width: 320px;
  height: 21rem;
  margin-right: 10px;

  background: #730190;
  border-radius: 20px;
  position: relative;
}

.training-package-3-pro {
  min-width: 320px;
  height: 21rem;
  margin-right: 10px;

  background: #00a49b;
  border-radius: 20px;
  position: relative;
}

.slideheading {
  width: 178px;
  height: 37px;

  background: #ffffff;
  border-radius: 0px 20px 0px 20px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #303030;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  position: absolute;

  right: 0px;
  top: 0px;
}

.box-price {
  display: flex;
  flex-direction: column;
}

.packageright {
  /* position: absolute;
  top: 20px;
  right: 20px; */
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;

  color: #ffffff;

  width: 150px;

  display: flex;
  justify-content: space-between;
}

.package-right {
  font-family: "DM Sans";
  color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100px;

  position: absolute;
  bottom: 10px;
  left: 10px;
}

.package-right-mobile-view-training {
  font-family: "DM Sans";
  color: rgba(88, 88, 88, 1);

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100px;

  position: absolute;
  bottom: 10px;
  left: 10px;
}

.package-right-desktop {
  font-family: "DM Sans";
  color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 150px;

  position: absolute;
  bottom: 20px;
  left: 10px;

  border-radius: 10px;
  background-color: white;

  padding: 3px 3px 3px 10px;
}

.package-right-desktop-walking {
  font-family: "DM Sans";
  color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 160px;

  position: absolute;
  bottom: 20px;
  left: 10px;

  border-radius: 10px;
  background-color: white;

  padding: 3px 3px 3px 10px;
}

.package-right-desktop-walking2 {
  font-family: "DM Sans";
  color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 160px;

  border-radius: 10px;
  background-color: white;

  padding: 3px 3px 3px 10px;
}

.package-right-desktop-flex {
  display: flex;
  flex-direction: column;

  position: absolute;
  bottom: 10px;
  left: 10px;
}

.package-right-desktop-flex-walking1 {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 10px;
  right: 50px;
}

.package-right-desktop-flex-walking2 {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 105px;
  right: 50px;
}

.price {
  font-size: 22px;
}

.price-desktop {
  font-size: 16px;
  color: white;
  background: linear-gradient(180deg, #fd6a6a 0%, #e6117c 100%);
  border-radius: 8px;
  padding: 2px 20px;
}

.price-desktop-pro1 {
  font-size: 16px;
  color: white;
  background-color: rgba(253, 107, 106, 1);
  border-radius: 8px;
  padding: 2px 20px;
}

.price-desktop2 {
  font-size: 16px;
  color: white;
  background: linear-gradient(180deg, #730190 0%, #d55195 100%);
  border-radius: 8px;
  padding: 2px 20px;
}

.price-desktop-pro2 {
  font-size: 16px;
  color: white;
  background-color: rgba(115, 1, 144, 1);
  border-radius: 8px;
  padding: 2px 20px;
}

.price-desktop2-revert {
  font-size: 16px;
  color: white;
  background: linear-gradient(0deg, #730190 0%, #d55195 100%);
  border-radius: 8px;
  padding: 2px 20px;
}

.price-desktop3 {
  font-size: 16px;
  color: white;
  background: linear-gradient(180deg, #00d6bb 0%, #007b80 100%);
  border-radius: 8px;
  padding: 2px 20px;
}

.price-desktop-pro3 {
  font-size: 16px;
  color: white;
  background-color: rgba(0, 164, 155, 1);
  border-radius: 8px;
  padding: 2px 20px;
}

.price-desktop3-revert {
  font-size: 16px;
  color: white;
  background: linear-gradient(0deg, #00d6bb 0%, #007b80 100%);
  border-radius: 8px;
  padding: 2px 20px;
}

.price-desktop4 {
  font-size: 16px;
  color: white;
  background: linear-gradient(180deg, #e61d74 0%, #f8aa3b 100%);
  border-radius: 8px;
  padding: 2px 20px;
}

.price-desktop4-revert {
  font-size: 16px;
  color: white;
  background: linear-gradient(0deg, #e61d74 0%, #f8aa3b 100%);
  border-radius: 8px;
  padding: 2px 20px;
}

.price-desktop5 {
  font-size: 16px;
  color: white;
  background: linear-gradient(180deg, #34eff8 0%, #9f40e3 100%);
  border-radius: 8px;
  padding: 2px 20px;
}

.price-desktop5-walking {
  font-size: 16px;
  color: white;
  background: linear-gradient(180deg, #fe7195 0%, #ffae7f 100%);
  border-radius: 8px;
  padding: 2px 20px;
}

.price-desktop5-walking2 {
  font-size: 16px;
  color: white;
  background: linear-gradient(180deg, #f0347d 0%, #fe8388 100%);
  border-radius: 8px;
  padding: 2px 20px;
}

.price-desktop5-walking3 {
  font-size: 16px;
  color: white;
  background: linear-gradient(98.5deg, #f98586 18.97%, #f13e7e 83.89%);
  border-radius: 8px;
  padding: 2px 20px;
}

.sessionlabel {
  font-size: 10px;
}

.sessionlabel-desktop {
  font-size: 12px;
  font-family: "DM Sans";
  color: white;

  margin-left: 75px;
}

.packageinfo {
  position: absolute;
  top: 75px;
  left: 20px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 15px;

  color: #ffffff;
  text-align: left;
}

.icons-store-section-float {
  position: absolute;
  bottom: 20%;
  left: -200px;
}

.icons-store-section-float-2 {
}

.home-page-customers-head-walking {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.home-page-customers-head-grooming {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.packageinfo2 {
  position: absolute;
  top: 75px;
  left: 20px;

  width: 100%;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 15px;

  color: #ffffff;
  text-align: left;
}

.packageinfo-bathAndHair {
  position: absolute;
  top: 45px;
  left: 20px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 15px;

  color: #ffffff;
  text-align: left;
}

.space-above {
  margin-top: 30px;
}

.packagesubtitle {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  color: #ffffff;
  margin-bottom: 10px;
}

.packagetext {
  padding-top: 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;

  color: #ffffff;
}

.packageicon {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100px;
}

.packageicon-desktop1 {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 96px;
  height: 96px;
}

.packageicon-desktop1-1 {
  position: absolute;
  bottom: 0px;
  right: 20px;
  /* width: 150px; */
  width: 96px;
height: 96px;
}

.packageicon-desktop1-agression {
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform: scale(0.8);
  height: 96px;
  width: 96px;
}

.packageicon-grooming {
  position: absolute;
  bottom: 0px;
  right: 10px;
  width: 100px;
}

.packageicon-grooming-desktop {
  position: absolute;
  bottom: 0px;
  right: 10px;
  width: 96px;
  height: 96px;
}

.packageicon-grooming-4 {
  position: absolute;
  bottom: 0px;
  right: 10px;
  width: 140px;
  height: 100px;
}

.packageicon-grooming-4-desktop {
  position: absolute;
  bottom: 0px;
  right: 10px;
  width: 96px;
  height: 96px;
}

.packageicon-desktop {
  position: absolute;
  bottom: 10px;
  right: 20px;
  padding-top: 20px;
}

.canine-misbehaviours {
  font-size: 14px;
  font-weight: 400;
  margin-top: 3px;

  line-height: 22px;
}

.canine-misbehaviours-tick {
  font-size: 18px;
  font-weight: 400;
  margin-top: 3px;

  line-height: 22px;
  margin-bottom: 15px;
}

.training-package-2 {
  background: linear-gradient(180deg, #730190 0%, #d55195 89.06%);
}

.training-package-2-revert {
  background: linear-gradient(0deg, #730190 0%, #d55195 89.06%);
}

.training-package-3 {
  background: linear-gradient(180deg, #00d6bb 0%, #00797f 100%);
}

.training-package-3-revert {
  background: linear-gradient(0deg, #00d6bb 0%, #00797f 100%);
}

.training-package-4 {
  background: linear-gradient(180deg, #f8ae3a 0%, #e61877 100%);
}

.training-package-4-revert {
  background: linear-gradient(0deg, #f8ae3a 0%, #e61877 100%);
}

.training-package-5 {
  background: linear-gradient(180deg, #1af3fb 0%, #a13de3 100%);
}

@media only screen and (max-width: 400px) {
  .dogimgcontainer {
    width: 100%;
  }
  .hearticon {
    width: 100%;
  }
  .services-img {
    width: 100%;
    height: 100%;
  }
  .yellowbg {
    width: 100%;
    height: 100%;
  }
  .make-pet-smile {
    width: 100%;
    height: 100%;
  }
  .privacy-image {
    width: 100%;
    height: 100%;
  }
  .homepage-grooming-bg {
    width: 100%;
  }
  .homepage-intro {
    width: 100%;
  }
  .training-intro {
    width: 100%;
  }
  .grooming-intro {
    width: 100%;
  }
  .footer-title {
    width: 100%;
  }
  .walking-bg {
    width: 100%;
  }
  .pink-container {
    width: 100%;
  }
}

.services-img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 500px) {
  .dogimgcontainer {
    width: 100%;
  }
  .hearticon {
    width: 100%;
  }
  .services-img {
    width: 100%;
    height: 100%;
  }
  .yellowbg {
    width: 100%;
    height: 100%;
  }
  .make-pet-smile {
    width: 100%;
    height: 100%;
  }
  .privacy-image {
    width: 100%;
    height: 100%;
  }
  .homepage-grooming-bg {
    width: 100%;
  }
  .homepage-intro {
    width: 100%;
  }
  .training-intro {
    width: 100%;
  }
  .grooming-intro {
    width: 100%;
  }
  .footer-title {
    width: 100%;
  }
  .walking-bg {
    width: 100%;
  }
  .pink-container {
    width: 100%;
  }
}

@media screen and (min-width: 520) {
  .services-img {
    width: 90%;
  }
}

.download-btn-container {
  display: flex;
  justify-content: space-between;
  width: 65%;
}

@media only screen and (min-width: 450px) {
  .download-btn-container {
    width: 280px;
  }
}

.download-text {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}

.download-text-link {
  font-family: "DM Sans";
}

.bookingbutton {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.bookingbutton2 {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.bookingbutton-circle {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  position: absolute;
  top: 0px;
  left: -30px;
}

.bookingbutton-grooming {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.bookingbutton-grooming-desktop {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 30px;
}

.excercisepackage {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  max-width: 374px;
  text-align: center;

  margin-left: auto;
  margin-right: auto;

  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
}

.package-top {
  display: flex;
  flex-direction: row;
  max-width: 374px;

  margin-left: auto;
  margin-right: auto;
}

.package-topleft {
  text-align: center;
  background: linear-gradient(262.89deg, #ffae7f 26.77%, #fe7195 66.06%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 4px 15px rgba(0, 0, 0, 0.5);
  border-radius: 20px 0px 0px 0px;
  width: 100%;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  color: #ffffff;
}

.package-topright {
  text-align: center;
  background: linear-gradient(98.5deg, #f98586 18.97%, #f13e7e 83.89%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 0px 0px;
  width: 100%;
  color: #ffffff;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  color: #ffffff;
}

.package-bottom {
  width: 100%;
  max-width: 374px;
  height: 351px;

  background: linear-gradient(229.73deg, #ffae7f 5.12%, #fe7195 80.78%);
  box-shadow: 0px 0px 25px rgba(201, 201, 201, 0.7);

  margin-top: 20px;
  border-radius: 0px 0px 20px 20px;

  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
}
.package-discount {
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #c10023;

  margin-top: 15px;
}

.package-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height, or 125% */

  text-align: center;

  color: #ffffff;

  margin-top: 15px;
}

.package-subtitle {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.package-duration {
  position: absolute;
  left: 45px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: #ffffff;
}

.package-price {
  position: absolute;
  right: 45px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: #ffffff;

  display: flex;
  flex-direction: row;
}

.old-price {
  padding-right: 5px;
  padding-left: 15px;

  background-color: transparent;
  background-image: gradient(
    linear,
    19.1% -7.9%,
    81% 107.9%,
    color-stop(0, transparent),
    color-stop(0.48, transparent),
    color-stop(0.5, #000),
    color-stop(0.52, transparent),
    color-stop(1, transparent)
  );
  background-image: repeating-linear-gradient(
    -163deg,
    transparent 0%,
    transparent 48%,
    #e92a2a 50%,
    transparent 52%,
    transparent 0%
  );

  color: rgb(243, 233, 233);
}

.new-price {
  padding-right: 15px;
  padding-left: 5px;
}

.package-info {
  margin-top: 15px;
  padding-right: 10px;
}

.statistics {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  margin-bottom: 40px;
  margin-top: 20px;
}

.stat-box {
  position: relative;
  width: 286px;
  height: 98px;

  background: #ffeaf1;
  border-radius: 5px;
  margin-top: 40px;

  border-left: solid 10px;
  border-color: #fe7798;
  padding-left: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.stat-info {
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 17px;
  line-height: 17px;

  display: flex;
  align-items: center;

  color: #5e5e5e;
  margin: 5px;
  padding-right: 20px;
}

.stat-number {
  position: absolute;
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 22px;
  /* identical to box height, or 69% */

  text-align: center;
  letter-spacing: 2px;

  color: #fe7798;
  top: -11px;
  left: 20px;
}

.services-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.services-container {
  margin-top: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  /* display: flex;
    justify-content: center;
    align-items: center; */
  height: 100%;
  /* background-color: red; */

  margin-left: auto;
  margin-right: auto;
}

.services-container2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  /* display: flex;
    justify-content: center;
    align-items: center; */
  height: 100%;
  /* background-color: red; */

  margin-left: auto;
  margin-right: auto;
}

.service-1 {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  height: 60px;
  top: 20px;
  color: #ffffff;
  width: 300px;

  background: #4a4a4a80;

  backdrop-filter: blur(25px);

  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  margin-bottom: 10px;
}

.service-1-desktop {
  /* position: absolute; */
  display: flex;
  flex-direction: row;

  top: 20px;
  color: #ffffff;
  width: 390px;

  background: #4a4a4a80;

  backdrop-filter: blur(25px);

  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.service-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* text-align: center; */

  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  margin: auto;
}

.grey-background {
  background-color: rgba(248, 248, 248, 1);
  /* margin-top: 75px; */
}

.margin-top-boarding-faq-btn {
  margin-top: 15px;
}

.boarding-page-live-tracking-margin-bottom {
  margin-bottom: 20px;
}

.trust-icon {
  width: 55px;
  height: 55px;
  padding-left: 5px;
  padding-right: 5px;
}

.door-icon {
  width: 55px;
  height: 55px;
  padding-left: 5px;
  padding-right: 5px;
}

.refund-icon {
  width: 55px;
  height: 55px;
  padding-left: 5px;
  padding-right: 5px;
}

.trust-icon {
  width: 55px;
  height: 55px;
  padding-left: 5px;
  padding-right: 5px;
}

.training-page-mobile-view-package-selector-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.training-page-mobile-view-cards-master-container {
  padding: 0px 20px;
}

.training-page-why-choose-mobile-view {
  background-color: #000;
}

.trainers-option {
  border: 1px solid rgb(167, 167, 167);

  font-size: 16px;
  border-radius: 10px;
  background-color: rgb(236, 236, 236);
  padding: 5px 10px;

  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 200px;
  font-family: "DM Sans";
  color: rgba(88, 88, 88, 1);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.training-page-heart-container {
  margin-top: 100px;
  margin-bottom: 100px;
}

.trainers-option-desktop {
  border: 1px solid rgb(167, 167, 167);

  font-size: 18px;
  border-radius: 10px;
  background-color: rgb(236, 236, 236);
  padding: 10px 10px;
  font-family: "DM Sans";
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.trainers-option-desktop:hover {
  cursor: pointer;
}

.optionsContainer {
  position: absolute;
  right: 23%;
  margin-top: 100px;

  border: 1px solid rgb(167, 167, 167);
  border-radius: 10px;
  padding: 10px 12px;
  z-index: 100;
  width: 150px;
  background-color: rgb(236, 236, 236);

  font-family: "DM Sans";
  font-size: 15px;
  color: #727272;

  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.optionsContainer-walking {
  position: absolute;
  right: 23%;
  margin-top: 48px;

  border: 1px solid rgb(167, 167, 167);
  border-radius: 10px;
  padding: 10px 12px;
  z-index: 100;
  width: 150px;
  background-color: rgb(236, 236, 236);

  font-family: "DM Sans";
  font-size: 15px;
  color: #727272;

  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

  margin-bottom: 20px;
}

.mobile-view-walking-packages-master-container {
  padding: 0px 20px;
  padding-top: 20px;
}

.optionsContainer-desktop {
  position: absolute;
  left: 120px;
  margin-top: 20px;

  border: 1px solid rgb(167, 167, 167);
  border-radius: 10px;
  padding: 10px 12px;
  z-index: 100;
  background-color: rgb(236, 236, 236);

  font-family: "DM Sans";
  font-size: 20px;
  color: #727272;

  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.optionsContainer-desktop:hover {
  cursor: pointer;
}

.downArrow {
  padding-top: 15px;
}

.option {
}

.service-info {
  padding-left: 5px;
  padding-right: 5px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  /* identical to box height, or 22px */

  color: #ffffff;
}

.service-2 {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  height: 60px;
  top: 115px;
  color: #ffffff;
  width: 300px;

  background: #4a4a4a80;

  backdrop-filter: blur(25px);

  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;

  margin-bottom: 10px;
}

.service-2-desktop {
  /* position: absolute; */
  display: flex;
  flex-direction: row;

  top: 115px;
  color: #ffffff;
  width: 390px;

  background: #4a4a4a80;

  backdrop-filter: blur(25px);

  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;

  margin-bottom: 10px;
}

.service-3 {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  height: 60px;
  top: 210px;
  color: #ffffff;
  width: 300px;

  background: #4a4a4a80;

  backdrop-filter: blur(25px);

  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  margin-bottom: 10px;
}

.service-3-desktop {
  /* position: absolute; */
  display: flex;
  flex-direction: row;

  top: 210px;
  color: #ffffff;
  width: 390px;

  background: #4a4a4a80;

  backdrop-filter: blur(25px);

  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.service-4 {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  height: 60px;
  top: 305px;
  color: #ffffff;
  width: 300px;

  background: #4a4a4a80;

  backdrop-filter: blur(25px);

  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  margin-bottom: 15px;
}

.training-page-mobile-view-why-container {
background-image: url('https://websitefilesbucket.s3.ap-south-1.amazonaws.com/homepage-why-petmojo-mobile.webp');
background-repeat: no-repeat;
background-size:cover;
margin:20px 0;
display: flex;
align-items: center;
justify-content: center;
}

.styling-page-mobile-view-why-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  background-image: url('https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/mobile-view-services-dog.png');
background-repeat: no-repeat;
background-size:cover;
margin:20px 0;
}


.service-4-desktop {
  /* position: absolute; */
  display: flex;
  flex-direction: row;

  top: 305px;
  color: #ffffff;
  width: 390px;

  background: #4a4a4a80;

  backdrop-filter: blur(25px);

  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.walking-1 {
  background: linear-gradient(213.04deg, #ffae7f 0.01%, #fe7195 126.55%);
}

.walking-2 {
  background: linear-gradient(204.68deg, #ff8a89 13.71%, #ef2a7c 101.82%);
}

.help-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  color: #5d5d5d;
}

.phone-input {
  width: 324px;
  height: 41px;

  background: #f5f5f5;
  border: 1px solid #a6a6a6;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-left: 10px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #5d5d5d;
}

.input-container {
  margin-left: auto;
  margin-right: auto;
}

.email-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  margin-bottom: 5px;
  color: #5d5d5d;
}

.phonenumber {
  display: flex !important;
  justify-content: left !important;
  align-items: left !important;
  margin-bottom: 20px;
  flex-direction: column;
}

::placeholder {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #5d5d5d;
  padding-left: 10px;
}

.submit-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.submit-btn {
  width: 153px;
  height: 36px;
  /* background: #fd7a9b; */
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;
  color: black;
  border: none;
  outline: none;
  animation-name: shine;
    animation-fill-mode: forwards;
    animation: linear;
    animation-duration: 3s;
    /* animation-iteration-count: 1; */
    animation-iteration-count: infinite;
    background: linear-gradient( 132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100% ), rgb(222,194,105);

  border-radius: 10px;
}

.dark-grey-text {
  color: rgba(88, 88, 88, 1);
}

.grey-text {
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #989898;
}

.grey-text-desktop {
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;

  margin-top: 20px;

  color: #989898;
}

.faqContainerDesktop {
  display: flex;
  justify-content: space-between;
}

.faq-master-container-desktop {
  padding: 60px 120px;

  background-color: rgba(248, 248, 248, 1);
}

.faqContainerDesktop-left {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.accordion {
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.accordion-desktop {
  margin-left: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;
}

.live-class {
  color: #ff002e;
  font-weight: 700;
  position: relative;
  padding-right: 5px;
}

.red-dot {
  position: absolute;
  top: 0;
  right: 0;
}

.pink-bold {
  color: rgba(255, 94, 149, 1);
  font-weight: 700;
}

.pink-bold-typing {
  color: rgba(255, 94, 149, 1);
  font-weight: 700;
  position: relative;
}

.pink-bold-typing::after{
  content: "";
  left:0;
  animation: typing 1.5s steps(10) infinite;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb;
  border-left:2px solid rgba(255,94,149,1);
}

@keyframes typing{
  100%{
    left:100%;
    margin: 0 -35px 0 35px;
  }
}

.heroImageHome-desktop {
  position: relative;
}

.active {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;

  color: #ff5e95;
}

.active:hover {
  cursor: pointer;
}

.non-active {
  color: white;
  text-decoration: none;

  font-size: 16px;
  font-family: "DM Sans";

  transition: all 300ms;
}

.non-active:hover {
  color: #ff5e95;
  cursor: pointer;
}

.non-active-blogs {
  color: rgba(88, 88, 88, 1);
  text-decoration: none;

  font-size: 16px;
  font-family: "DM Sans";

  transition: all 300ms;
}

.non-active-blogs:hover {
  color: #ff5e95;
  cursor: pointer;
}

.make-pet-smile-head-desktop-text {
  text-align: center;
}

.make-pet-smile-head-desktop {
  position: absolute;
  top: 50px;

  font-family: "DM Sans";
  color: white;
  font-size: 50px;

  width: 100%;
}

.make-pet-smile-desktop {
  width: 100vw;
}

.make-pet-smile-line-desktop {
  border: 3px solid rgba(254, 119, 152, 1);
  width: 180px;

  border-radius: 10px;
}

.hero-image-container-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 60px 120px;

  font-family: "DM Sans";
}

.storeContainer {
  background-color: rgba(248, 248, 248, 1);
  padding: 60px 120px;
  display: flex;
  align-items: center;
}

.storeContainer_left {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.storeContainer_right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.storeContainer_logo_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.storeContainer_logo_container_mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 30px;
}
.storeContainer_logo_container_mobile img {
  width: 140px;
  height: 48px;
}
.storeContainer_logo_container img {
  width: 272px;
  height: 62px;
}
.storeContainer_main_image_mobile {
  display: flex;
  align-items: center;
  justify-content: center;
}
.storeContainer_main_image_mobile img {
  flex: 1;
}
.storeContainer_main_image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.storeContainer_main_image img {
  width: 100%;
  height: 100%;
}
.storeContainer_button_container {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.storeContainer_button_container img {
  width: 219px;
  height: 74px;
}

.hero-image-container-desktop-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pet-smile-img {
  width: 600px;
}

.boarding-page-desktop-view-left-head {
  position: relative;

  max-width: 550px;
}

.boarding-page-desktop-view-meet-master-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 100px 100px;
  width: 100%;

  font-family: "DM Sans";

  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/doggo-4.png");
  background-size: cover;
  background-repeat: no-repeat;

  color: white;
}

.pet-smile-home-set1 {
  font-size: 50px;
  color: rgba(88, 88, 88, 1);

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;

  width: 350px;
}

.pet-smile-home-set2 {
  font-size: 20px;
  color: #848484;
}

.carousel-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;

  width: 324px;
  min-height: 150px;
  box-sizing: border-box;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));  */
  box-shadow: 0px 3px 8px rgb(100, 100, 100);
  border-radius: 10px;
  border: 0.5px solid rgb(100, 100, 100);
  border-top: none;

  /* border-image-source: linear-gradient(0deg, rgba(0, 0, 0, 0.46) 54.9%, rgba(0, 0, 0, 0) 100%); */
  /* background-color: red; */

  justify-content: center;
  align-items: center;
}

.carousel-container-desktop {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;

  width: 374px;
  min-height: 200px;
  box-sizing: border-box;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));  */
  box-shadow: 0px 3px 8px rgba(254, 119, 152, 1);
  border-radius: 10px;
  border-top: none;

  /* border-image-source: linear-gradient(0deg, rgba(0, 0, 0, 0.46) 54.9%, rgba(0, 0, 0, 0) 100%); */
  /* background-color: red; */

  justify-content: center;
  align-items: center;
}

.social-media-links-line {
  border: 3px solid rgba(254, 119, 152, 1);
  border-radius: 10px;
  width: 150px;
  position: absolute;
}

.social-media-links-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 120px;
}

.address-footer-wrap{
  padding: 20px 120px;
  background-color: #f8f8f8;
}


.address-footer-wrap-boarding{
  padding: 20px 120px;
}

.address-footer-wrap-mobile{
  padding: 5px 10px;
  width:100%;
  background-color: #f8f8f8;
}

.address_footer{
  font-family: 'DM Sans';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 25px;
color: #5D5D5D;
width:100%;
}

.address_footer_mobile{
  font-family: 'DM Sans';
font-style: normal;
font-weight: 600;
font-size: 11px;
line-height: 20px;
color: #5D5D5D;
width:100%;
}

.desktop-footer-buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 120px;
  background-color: rgba(248, 248, 248, 1);
}
.desktop-footer-buttons-container-add {
  display: flex;
  justify-content: space-between;
  padding: 0px 120px;
  background-color: rgba(248, 248, 248, 1);
  text-decoration: none;
  color: rgba(152, 152, 152, 1);
  font-size: 17px;
  padding-top: 1px;
  transition: 100ms;
}
.desktop-footer-buttons-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* line-height: "25px"; */
  width: 50%;
  font: "DM Sans";
}

.desktop-footer-buttons-left-btn1-img {
  width: 150px;
}

.desktop-footer-buttons-left-btn3 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: rgba(254, 119, 152, 1);
  border-radius: 10px;
  padding: 0px 10px;

  width: 140px;
  height: 50px;

  color: white;
  font-family: "DM Sans";
}

.desktop-footer-buttons-left-btn3:hover {
  cursor: pointer;
}

.top-arrow-container {
  padding-right: 10px;
}

.top-arrow {
  position: absolute;
}

.icon-desktop-mail {
  width: 30px;
  margin-right: 15px;
}

.icon-desktop-call {
  width: 32px;
  margin-right: 15px;
}

.footer-details-content-call-mail {
  display: flex;
  align-items: center;
}

.social-media-links-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.social-media-links-text {
  font-size: 50px;
  color: rgba(88, 88, 88, 1);

  font-family: "DM Sans";

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 350px;

  position: relative;
}

.social-media-links-image {
  margin-right: 150px;
}

.carousel-card {
  display: flex;
  flex-direction: row;
  width: 100%;

  margin-left: auto;
  margin-right: auto;
}

.rec-dot {
  margin: 0 !important;
  padding: 0 !important;

  margin-left: 2px !important;
  margin-right: 2px !important;
}
.rec-dot_active {
  background-color: #f87898 !important;
  box-shadow: none !important;
}

.rec-dot_active:focus,
.rec-dot_active:hover {
  background-color: #f87898 !important;
}

.rec-arrow {
  background-color: transparent !important;
  transition: all 0.3s ease;

  width: 1.7rem !important;
  height: 1.7rem !important;
  min-width: 1.7rem !important;
  line-height: 1.7rem !important;
  border: none !important;
  border-radius: 0 !important;
  outline: none !important;

  box-shadow: none !important;
}

.carousel-icon {
  margin: 10px;
  vertical-align: middle;
}

.carousel-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;

  color: #5e5e5e;
  padding: 10px;
}

.imageCont {
  width: 100%;
}

.foot-note-grooming {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 45px;
}

.foot-icon {
  transform: scale(0.8);
}

.foot-icon-landing {
  transform: scale(0.8);
}

.landing-subtext-txt {
  display: flex;
  align-items: center;
}

.gst-text {
  font-size: 12px;
}

.actual-price {
  font-size: 18px;
}

/* hello */

.view-app {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-top: 20px;
}

.view-btn {
  width: 201px;
  height: 40px;

  border: 3px solid #ff7698;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #ff7698;
  background-color: white;
}

.limited-container {
  display: flex;
  justify-content: center;
}

.limited-message {
  font-size: 12px;
  font-family: "DM Sans";
  font-weight: 900;

  color: #d73637;
}

.items {
  display: flex;
  flex-direction: row;
  /* overflow-x: scroll; */
}

.items::-webkit-scrollbar {
  display: none;
}

.item {
  min-width: 324px;
  background-color: red;
  margin: 10px;
}

.ellipse {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.outer-ring-desktop {
  width: 400px;
  height: 400px;
}

.inner-ring-desktop {
  width: 300px;
  height: 300px;

  position: absolute;
  left: 45px;
  top: 45px;
}

.ellipse-desktop {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.ellipse-container {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
}

.inner-ring {
  position: absolute;
  top: 55px;
  left: 55px;
}

.youtube-icon {
  position: absolute;
  top: 50px;
  left: 0px;

  border: none;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
}

.youtube-icon-desktop {
  position: absolute;
  top: 50px;
  left: 10px;

  border: none;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
}

.facebook-icon {
  position: absolute;
  top: 0;
  right: 50px;

  border: none;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
}

.facebook-icon-desktop {
  position: absolute;
  bottom: 180px;
  right: -25px;

  border: none;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
}

.twitter-icon {
  position: absolute;
  bottom: 65px;
  right: 0px;

  border: none;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
}

.twitter-icon-desktop {
  position: absolute;
  bottom: 65px;
  right: 0px;

  border: none;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
}

.instagram-icon {
  position: absolute;
  bottom: 15px;
  left: 40px;

  border: none;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
}

.instagram-icon-desktop {
  position: absolute;
  bottom: 20px;
  left: 40px;

  border: none;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
}

.phone-icon {
  position: absolute;
  top: 50px;
  left: 140px;

  border: none;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
}

.phone-icon-desktop {
  position: absolute;
  top: 45px;
  left: 210px;

  border: none;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
}

.gmail-icon {
  position: absolute;
  bottom: 105px;
  left: 50px;

  border: none;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
}

.gmail-icon-desktop {
  position: absolute;
  bottom: 145px;
  left: 40px;

  border: none;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
}

.whatsapp-icon {
  position: absolute;
  bottom: 105px;
  right: 52px;

  border: none;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
}

.whatsapp-icon-desktop {
  position: absolute;
  bottom: 85px;
  right: 82px;

  border: none;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
}

.refund {
  color: #5d5d5d;
  margin-top: 20px;
}

.iphone-icon {
  width: 324px;
}

.iphone-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  position: relative;
  margin-bottom: 40px;
}

.end-container {
  display: flex;
  flex-direction: row;

  margin-top: 30px;
  margin-bottom: 30px;

  margin-left: auto;
  margin-right: auto;
}

.service-heading {
  display: flex;
  flex-direction: column;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;

  letter-spacing: 0.02em;

  color: #5d5d5d;
}

.services {
  padding-left: 20px;
  padding-right: 10px;
}
.about {
  padding-left: 20px;
  padding-right: 10px;
}

.about-heading {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
  /* or 200% */

  letter-spacing: 0.02em;

  color: #5d5d5d;
}

.links {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  color: #5d5d5d;
  padding-top: 5px;
  padding-bottom: 5px;
}

.vector3 {
  width: 38.5px;
  border: 1px solid #fe7798;
  margin: 0;
  margin-bottom: 20px;
}

.footer {
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;

  text-align: center;

  color: #5d5d5d;
  margin-bottom: 50px;
  margin-top: 25px;
}

.footer-desktop {
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  text-align: center;

  color: rgba(93, 93, 93, 1);
  margin-bottom: 50px;
  margin-top: 25px;
}

.review-container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;

  display: flex;
  flex-direction: row;
  width: 100%;
}

.review-card {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  min-height: 228px;
  margin: 10px;

  background: #ffffff;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.review-card-desktop {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  height: 278px;
  margin: 10px;

  background: #ffffff;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.review-card-grooming {
  display: flex;
  flex-direction: column;
  margin: 10px;
  min-width: 350px;
  min-height: 110px;

  background-color: #ffffff;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.review-top {
  display: flex;
  flex-direction: row;
}

.user-profile {
  padding: 20px;
}

.circle-1 {
  position: relative;
  width: 125px;
  height: 125px;
  background: #fe7798;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.circle-1-grooming {
  position: relative;
  width: 125px;
  background: #fe7798;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.circle-2 {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 81px;
  height: 81px;
  border-radius: 50%;

  background: #fe7798;
  box-shadow: 15px 15px 15px 8px rgba(0, 0, 0, 0.25);
}

.popup-training-walking-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 50%;
  margin-left: 70px;
  margin-top: 100px;
}

.review-info {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  position: relative;
  padding-bottom: 10px;
}

.review-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;

  text-align: center;

  color: #000000;
  text-align: left;
}

.review-text {
  display: flex;
  flex-direction: column;
}

.review-box {
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: 100%;
}

.home-page-customer-heading {
  color: rgba(88, 88, 88, 1);
  font-size: 50px;
  font-family: "DM Sans";
  width: 450px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
}

.home-page-customer-heading2 {
  color: rgba(88, 88, 88, 1);
  font-size: 50px;
  font-family: "DM Sans";
  width: 450px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
}

.bold-none {
  font-weight: 100;
}

.home-page-customers-contents {
  color: #848484;
  font-family: "DM Sans";
  font-size: 20px;
}

.review-box-desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
  width: 100%;
}

.review-box-desktop-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
  width: 100%;
  overflow-x: scroll;
}

.review-box-desktop-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.review-box-desktop-2::-webkit-scrollbar {
  margin-top: 3rem;
  width: 21px;
  height: 8px;
}

.review-box-desktop-2::-webkit-scrollbar-track {
  width: 190px;
  height: 8px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.review-box-desktop-2::-webkit-scrollbar-thumb {
  width: 27px;
  height: 8px;

  background: #fe7798;
  border-radius: 50px;
}

.review-box-desktop-2:hover::-webkit-scrollbar {
  margin-top: 3rem;
  width: 21px;
  /* height: 14px; */
}

.review-box-desktop-2:hover::-webkit-scrollbar-track {
  width: 190px;
  /* height: 14px; */

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.review-box-desktop-2:hover::-webkit-scrollbar-thumb {
  width: 27px;
  /* height: 14px; */

  background: #fe7798;
  border-radius: 50px;
}

.review-box::-webkit-scrollbar {
  display: none;
}

.review-box::-webkit-scrollbar-track {
  width: 189px;
  height: 6px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.review-box::-webkit-scrollbar-thumb {
  width: 24px;
  height: 6px;

  background: #fe7798;
  border-radius: 50px;
}

.review-profession {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  color: #7e7e7e;
  padding-top: 10px;
}
.review-location {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  color: #7e7e7e;
  padding-top: 0px;
}

.review-profession-grooming,
.review-location-grooming {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  color: #7e7e7e;
}

.card-icon {
  position: absolute;
  top: 20px;
  right: -30px;
}

.user-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  border: white 4px solid;
  border-radius: 50%;
  width: 105px;
}

.accordion-text {
  display: flex;
  align-items: center;
}

.user-icon-desktop {
  position: absolute;
  top: 20px;
  left: 20px;
  border: white 4px solid;
  border-radius: 50%;
}

.review-bottom {
  padding: 20px;
  border-top: 1px solid #d8c5c5;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  color: #7e7e7e;
}

.review-stars {
  display: flex;
  flex-direction: row;
}
.star-icon {
  padding-right: 5px;
}

.dog-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.dog-container-grooming-page-desktop {
  display: flex;
  flex-direction: row;

  padding: 60px 120px;
}

.dog-container-desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 60px 120px;
}

.dog-container-desktop2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 60px 120px;
}

.dog-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;

  height: 250px;
  overflow-y: hidden;

  margin-left: 15px;
  margin-right: 15px;
  overflow-x: scroll;
}

.dog-box-2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;

  height: 330px;
  overflow-y: hidden;

  margin-left: 15px;
  margin-right: 15px;
  overflow-x: scroll;

  margin-top: 10px;
}

.navigation-desktop-services-drop-container {
  position: absolute;
  top: 20px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  right: -52px;
  border: 1px solid rgba(232, 236, 244, 1);
  background-color: black;
  padding: 10px 10px;
  border-radius: 5px 5px 5px 5px;
  width: 211px;

  z-index: 100;
}

.navigation-desktop-service-drop-item {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  margin: 5px 0px;
  border-radius: 5px;
  padding: 5px 5px;
  text-decoration: none;
  transition: all 300ms;
}

.navigation-desktop-service-drop-item:hover {
  color: rgba(255, 94, 149, 1);
  margin: 5px 0px;
  border-radius: 5px;
  padding: 5px 5px;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 8px;
  font-size: 18px;
}

.dog-box-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;

  overflow: scroll;
  overflow-y: hidden;
}

.dog-box-desktop::-webkit-scrollbar {
  margin-top: 1rem;
  width: 21px;
  height: 8px;
}

.dog-box-desktop-2::-webkit-scrollbar {
  margin-top: 1rem;
  width: 21px;
  height: 8px;
}

.dog-box-desktop::-webkit-scrollbar-track {
  width: 189px;
  height: 8px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.dog-box-desktop-2::-webkit-scrollbar-track {
  width: 189px;
  height: 8px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.dog-box-desktop::-webkit-scrollbar-thumb {
  width: 20px;
  height: 8px;
  background: rgba(254, 119, 152, 1);
  border-radius: 50px;
}

.dog-box-desktop-training {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;

  height: 400px;
  overflow-y: hidden;

  margin-left: 15px;
  margin-right: 15px;
  overflow-x: scroll;
}

.dog-box-desktop-training::-webkit-scrollbar {
  margin-top: 1rem;
  width: 21px;
  height: 8px;
}

.dog-box-desktop-training::-webkit-scrollbar-track {
  width: 189px;
  height: 8px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.dog-box-desktop-training::-webkit-scrollbar-thumb {
  width: 24px;
  height: 8px;

  background: #fe7798;
  border-radius: 50px;
}

.dog-box-desktop-training:hover::-webkit-scrollbar {
  /* height: 14px; */
}

.dog-box-desktop-training:hover::-webkit-scrollbar-track {
  /* height: 14px; */
}

.dog-box-desktop-training:hover::-webkit-scrollbar-thumb {
  /* height: 14px; */
}
.blogs-desktop-view-main-container-left-right1::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.blogs-desktop-view-main-container-left-right1::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}
 
/* Handle */
.blogs-desktop-view-main-container-left-right1::-webkit-scrollbar-thumb {
  background: rgba(254, 119, 152, 1); 
  border-radius: 10px;
}
.blogs-desktop-view-main-container-right-blogs::-webkit-scrollbar {
  width: 5px;
  height: 100px;
}

/* Track */
.blogs-desktop-view-main-container-right-blogs::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
  height: 100px;
}
 
/* Handle */
.blogs-desktop-view-main-container-right-blogs::-webkit-scrollbar-thumb {
  background: rgba(254, 119, 152, 1); 
  border-radius: 10px;
  height: 100px;
}

/* Handle on hover */

.dog-box::-webkit-scrollbar {
  margin-top: 1rem;
  width: 20px;
  height: 8px;
}

.dog-box::-webkit-scrollbar-track {
  width: 189px;
  height: 8px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.dog-box::-webkit-scrollbar-thumb {
  width: 24px;
  height: 8px;

  background: #fe7798;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  /* border-top: 0.55rem solid #fff; */
}

.dog-box-2::-webkit-scrollbar {
  margin-top: 1rem;
  width: 20px;
  height: 8px;
}

.dog-box-2::-webkit-scrollbar-track {
  width: 189px;
  height: 8px;

  background: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.dog-box-2::-webkit-scrollbar-thumb {
  width: 24px;
  height: 8px;

  background: #fe7798;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  /* border-top: 0.55rem solid #fff; */
}

.dog-slide-home-page {
  position: relative;
  min-width: 205px;
  height: 240px;

  margin-right: 20px;
}

.dog-slide {
  position: relative;
  min-width: 205px;
  height: 140px;

  margin-right: 20px;
}

.dog-slide1 {
  position: relative;
  min-width: 205px;
  height: 140px;

  margin-right: 20px;
}

.dog-slide-desktop {
  position: relative;
  min-width: 500px;
  height: 240px;

  margin-right: 20px;
}

.dog-slide-desktop2 {
  position: relative;
  min-width: 380px;
  min-height: 240px;
}

.dog-slide-desktop-training {
  position: relative;
  min-width: 500px;
}

.dog-slide-desktop-grooming {
  position: relative;
  min-width: 500px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.grooming-dog-slide {
  position: relative;
  min-width: 275px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-family: "DM Sans";
}

.dog-icon-desktop {
  width: 100%;
  height: 100%;

  width: 400px;
  height: 350px;
}

.educational-card-training-desktop {
  background-color: green;
  display: flex;
  flex-direction: column;
  align-items: center;
}

 /*---------------------------started from here--------------------------------*/

.grooming-new-form-section1-main-container{
  color: rgba(114, 114, 114, 1);
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 20px; */
}

.grooming-new-form-section1-main{
  display: flex;
  justify-content: space-between;
  /* align-items:flex-start; */
  width: 100%;
  margin-bottom: 20px;
}
.grooming-new-form-section1-main-last{
  display: flex;
  justify-content: space-between;
  /* align-items:flex-start; */
  width: 100%;
  margin-bottom: 20px;
}

.grooming-new-form-section1-left-head{
  color: rgba(94, 94, 94, 1);
  font-weight: 700;
}

.grooming-new-form-section1-left-container{
  background-color: #F7F8F9;
  border: 1px solid #E8ECF4;
  border-radius: 10px;

  /* height: 18rem; */
  padding: 10px 20px;

  margin-top: 10px;
}

.coupon-apply-button{
  background-color: rgb(255, 94, 149);
  color: white;
  display: flex;
  margin-left: -75px;
  height: 40px;
  border-radius: 0.375rem;
  justify-content: center;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  padding: 5px;
}

.coupon-apply-button-icon{
  color: #ff5E95;
  background-color: white;
  border-radius: 50%;
  /* border-width: 100px; */
  border: 3px solid white;
  font-size: x-large;
}

.coupon-applied-text{
  margin-left: -135px;
  color: #618E41;
  /* vertical-align: middle; */
  /* align-items: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: smaller;
  padding-bottom: 4px;
  text-decoration: underline;
  text-underline-position: under;
}

.coupon-not-valid{
  color: white;
  margin-left: -30px;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
  background-color: red;
  border-radius: 50%;
  padding: 3px;
}

.check-mark{
  border-radius: 50%;
  color: white;
  background-color: #618E41;
  /* width: 70px; */
  /* height: 70px; */
  padding: 3px;
  font-size: 20px;
}

.grooming-new-form-section1-left-choice-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  color: rgba(114, 114, 114, 1);
  /* min-width: 350px; */
  transition: 300ms all;
}
.grooming-new-form-section1-left-choice-container-active{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  color: rgba(255, 94, 149, 1);
  /* min-width: 350px; */
  transition: 300ms all;
}

.grooming-new-form-section1-left-choice-container:hover{
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  background-color: rgba(247, 248, 249, 0);
  color: rgba(255, 94, 149, 1);
}

.grooming-new-form-section1-left-choice-name{
  margin-left: 5px;
}

.grooming-new-form-section1-left-choice-name:hover{
  cursor: pointer;
}

.grooming-new-form-section1-right{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 40%;
}

.grooming-new-form-section3-right{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.grooming-new-form-section3-right-ph-section{
  width: 100%;
}

.grooming-new-form-section3-right-ph-section-2{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.grooming-new-form-section3-right-ph-section-Phone-container{
  width: 100%;
}

.non-verified-button{
  background-color: rgb(222,194,105);
  border-radius: 5px;
  padding: 5px ;
  color: white;
  border: none;
  font-size: 16px;
  margin-top: 10px;
}
.non-verified-button:hover{
  cursor: pointer;
}

.verified-button{
  background-color: rgba(255, 94, 149, 1);
  color: white;
  border-radius: 5px;
  padding: 3px 5px;
  border: none;
}

.otp-input-container-desktop{
  width: 100%;
  background-color: rgba(247, 248, 249, 1);
  border: 1px solid rgba(232, 236, 244, 1);
  border-radius: 5px;
  height: 40px;
}

.otp-input-container-desktop-verified{
  width: 100%;
  background-color: rgba(247, 248, 249, 1);
  border: 2px solid #2b9348;
  border-radius: 5px;
  height: 40px;
}

.enterotp{
  margin-top: 10px;
}

.enterotp-heading{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.send-otp-button-desktop-container{
  border: 2px solid rgba(131, 145, 161, 0.5);
  border-radius: 4px;
  padding: 7px 9px;
  background-color: rgba(247, 248, 249, 1);
  margin-left: 10px;
  height: 40px;
}

.send-otp-button-desktop-container:hover{
  cursor: pointer;
}

.grooming-new-form-section3-right-address-section{
  width: 100%;
  margin-top: 10px;
}

.grooming-new-form-section1-right-include-list-container{
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.grooming-new-form-section1-right-include-container{
  display: flex;
  flex-direction: column;
  float: right;
  width: 100%;
  /* background-color: blue; */
  /* justify-content: space-evenly; */
  padding: 5px;
}

.grooming-new-form-section1-right-include-text{
  color: rgba(94, 94, 94, 1);
  font-weight: 700;
  width: 50%;
  /* background-color: red; */
  text-align: center;
}
.grooming-new-form-section1-right-include-listitem{
  font-size: 15px;
}
.grooming-new-form-section1-right-include-list{
  padding-left: 10px;
  /* background-color: black; */
}

.grooming-new-form-section1-right-next-button{
  border: 2px solid rgb(222,194,105);
  border-radius: 10px;

  /* color: white; */
  /* background-color: rgb(222,194,105); */
  font-size: 20px;
  font-weight: 700;
  padding: 15px 0px;
  /* width:100%; */
  text-align: center;
  transition: 300ms all;
  background: linear-gradient( 132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100% ), rgb(222,194,105);
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;
    animation-name: shine;
    animation-fill-mode: forwards;
}

.grooming-new-form-section1-right-next-button:hover{
  cursor: pointer;
  box-shadow: 2px 2px 4px rgb(100, 100, 100);
}

.grooming-new-form-section1-right-next-button-container{
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.grooming-new-form-section1-right-pay-button-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

   min-width: 400px;
}
.grooming-new-form-section1-right-pay-button-container2{
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;

   min-width: 400px;
}

.grooming-new-form-section2-right-next-button-container{
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.grooming-new-form-section1-right-pay-button{
  border: 2px solid rgb(222,194,105);
  border-radius: 10px;

  color: white;
  font-size: 16px;
  font-weight: 700;
  padding: 15px 0px;
  width: 100%;
  text-align: center;

  margin: 10px 10px;

  /* background-color: rgb(222,194,105); */
  background: linear-gradient( 132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100% ), rgb(222,194,105);
  transition: 300ms all;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;
    animation-name: shine;
    animation-fill-mode: forwards;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flex-container p {
  margin: 0;
}

.flex-container p span {
  margin-right: 5px;
}


.cat-training-page-mobile-view-why-container {
  background-image: url('https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Mobile_Why Choose_Petmojo.png');
  background-repeat: no-repeat;
  background-size:cover;
  margin:20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  .cat-training-page-intro-mobile-view {
    position: relative;
    background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Mobile_Home_Top.png");
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 100vh; */
  
    padding-bottom: 20px;
  }
.grooming-new-form-section1-right-pay-button:hover{
  cursor: pointer;
  box-shadow: 2px 4px 4px rgb(100, 100, 100);
}

.grooming-new-form-section1-right-pay-later-button{
  border: 2px solid rgb(222,194,105);
  border-radius: 10px;

  color: rgb(222,194,105);
  font-size: 16px;
  padding: 15px 0px;
  width: 100%;
  text-align: center;

  margin: 10px 10px;
  transition: 300ms all;
  background-color: white;
}

.grooming-new-form-validation-container-desktop{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.grooming-new-form-section1-right-pay-later-button:hover{
  cursor: pointer;
  box-shadow: 2px 3px 3px rgb(100, 100, 100);
}

.grooming-new-form-section1-carousel-container{
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.grooming-new-form-section1-carousel{
  border: 2px solid rgba(217, 217, 217, 1);
  width: 100%;
  margin: 0px 3px;
}

.grooming-new-form-section1-carousel-active{
  border: 2px solid rgba(255, 94, 149, 1);
  width: 100%;
  margin: 0px 3px;
}

.grooming-new-form-section2-left{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 40%;
}

.new-date-ref-container{
  margin-top: 10px;
}

.grooming-popup-page-send-otp-container{
  margin-top: 10px;
  font-family: "DM Sans";
  text-align: center;
  border-radius: 5px;
  color: rgba(114, 114, 114, 1);
  transition: all 300ms;
}

.enterotp-grooming-popup-page button {
  outline: none;
  height: 56px;
  background: #F7F8F9;
  border: 2px solid rgba(131, 145, 161, 0.5);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 125%;
  padding: 8px 10px;
  color: #727272;
}

.enterotp-grooming-popup-page input[type="number"] {
  width: 40%;
  margin-right: 10px;
}

.enterotp-grooming-popup-page span{
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 125%;
  color: #727272;
}

.grooming-popup-page-send-otp-container:hover{
  box-shadow: 1px 2px 2px rgb(100, 100, 100);
}

.grooming-new-form-section2-right-time-slots{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  font-family: "DM Sans";
  color: rgba(114, 114, 114, 1);
}

.grooming-new-form-section2-right-time-slot{
  border: 1px solid rgba(232, 236, 244, 1);
  background-color: rgba(247, 248, 249, 1);
  border-radius: 5px;
  padding: 10px 5px;
  margin: 0px 5px;
}

.grooming-new-form-section2-right-time-slot:hover{
  cursor: pointer;
}

.grooming-new-form-section2-right-time-slot-active{
  border: 1px solid rgba(255, 94, 149, 1);
  background-color: rgba(247, 248, 249, 1);
  border-radius: 5px;
  padding: 10px 5px;
  margin: 0px 5px;
  color: rgba(255, 94, 149, 1);
}

.grooming-new-form-section2-left-date-text-container{
  margin-top: 30px;
}

.grooming-new-form-desktop-input-container-type1{
  width: 100%;
  background-color: rgba(247, 248, 249, 1);
  border: 1px solid rgba(232, 236, 244, 1);
  border-radius: 5px;
  height: 40px;
}

.grooming-new-form-desktop-input-container-type1-button{
  width: 100%;
  background-color: rgba(247, 248, 249, 1);
  border: 1px solid rgba(232, 236, 244, 1);
  border-radius: 0.5rem;
  border: 4px dotted pink;
  height: 40px;
}

.grooming-new-form-desktop-input-container-type1-verified{
  width: 100%;
  background-color: rgba(247, 248, 249, 1);
  border: 2px solid #2b9348;
  border-radius: 5px;
  height: 40px;
}

.grooming-new-form-desktop-input-container-type1::placeholder{
  color: rgba(131, 145, 161, 0.5);
}

.grooming-new-form-desktop-input-space1{
  margin-top: 10px;
}

.grooming-new-form-section2-left-date-text-container{
  font-size: 28px;
  font-family: "DM Sans";
  margin-top: 50px; 
}

.grooming-new-form-section3-right-ph-input-contact{
  width: 100%;
  margin-top: 5px;

  display: flex;
  /* justify-content: space-between; */
  /* margin-left: 5px; */
  align-items: center;
}

.grooming-page-loading-spinner-container-desktop{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.grooming-new-from-full-width-container{
  width: 100%;
}

.grooming-new-form-section3-left{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
}

.grooming-new-form-section1-left-choice-radio{
  border: 0.5px solid rgba(231, 231, 231, 1);
  border-radius: 50%;
  background-color: rgba(231, 231, 231, 1);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
}

 /*-----------------------------ending here------------------------------------*/

.educational-content-card-training {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid rgba(222, 222, 222, 1);
  border-radius: 20px;

  height: 95%;
  max-width: 280px;
  margin-bottom: 0px;

  padding: 10px 10px;

  font-family: "DM Sans";

  margin-left: 10px;
  margin-right: 10px;

  position: relative;
}

.blogs-page-mobile-view-blog-list-master-container {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-item-mobile-view-master-container {
  display: flex;
  align-items: flex-start;
  /* width: 100%; */
  margin: 10px 0px;

  font-family: "DM Sans";
}

.blog-item-mobile-view-image {
  width: 100%;
  max-width: 200px;

  border-radius: 10px;
}

.blog-item-mobile-view-image-container {
  width: 50%;
}

.educational-content-card-training-head {
  font-size: 24px;
  font-weight: 700;
  color: rgba(94, 94, 94, 1);
}

.blog-item-mobile-view-text-container {
  width: 50%;
  margin-left: 10px;

  color: rgba(62, 62, 62, 1);
}

.blog-item-mobile-view-text-data-views {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #6e6e6e;
}

.blog-item-mobile-view-text-heading {
  color: rgba(62, 62, 62, 1);
  font-weight: 500;
}

.educational-content-card-training-link {
  text-decoration: none;
  margin-top: 10px;
  font-weight: 500;
  color: royalblue;
}

.educational-content-card-training-upper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.educational-content-card-training-image {
  width: 250px;
  border-radius: 20px;
}

.educational-content-card-training-text {
  color: rgba(94, 94, 94, 1);
  margin-top: 10px;
}
.educational-content-card-training-lower {
  margin-top: 20px;
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.testimonial-card-quote-image {
  margin-bottom: 20px;
}

.testimonial-card-review-content {
  text-align: center;
  padding: 0px 15px;
}

.testimonial-card-review-star-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.educational-card-training-upper {
  background-color: #000;
}

.padding-20 {
  /* padding-top: 20px; */
}

.dog-slide-landingBlogs-desktop {
  position: relative;
  min-width: 345px;
  height: 310px;

  margin-right: 20px;
  left: -30px;

  display: flex;
  justify-content: flex-start;
}

.dog-text {
  display: flex;
  position: absolute;
  top: 40px;
  left: 30px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;

  color: #ffffff;
}

.centerAlign {
  background-color: rgba(248, 248, 248, 1);
  padding-bottom: 20px;
}

.dog-text-desktop {
  display: flex;
  position: absolute;
  top: 50px;
  left: 50px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;

  color: #ffffff;
}

.dog-text-desktop2 {
  display: flex;
  position: absolute;
  top: 50px;
  left: 50px;
  right: 50px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  color: #ffffff;
}

.dog-text-desktop-grooming {
  display: flex;
  position: absolute;
  top: 20px;
  left: 50px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  color: #ffffff;
}

.faq-title {
  margin-top: 30px;
}

.faq-title-desktop {
  margin-top: 150px;
  /* margin-bottom: 20px; */
}

.nav-bar-remove {
  display: none;
  visibility: hidden;
}

.nav-dropdown {
  position: absolute;
  right: 0px;
  top: -30px;

  width: 300px;
  height: 100vh;

  background: #ffffff;
  border: 1px solid #cbcbcb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  z-index: 110;
  padding: 0px 10px;
}

.nav-dropdown-visible {
  visibility: visible;
  transform: translateX(-0px);
  transition: transform 0.5s;
}

.nav-mobile-view-download {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */

  position: absolute;
  bottom: 10px;
  background-color: rgb(252, 206, 214);
  padding: 10px 10px;
  border-radius: 20px;
}

.navbar-notch {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  margin-top: 10px;
  margin-bottom: 20px;
}

.goArrow {
  width: 20px;
}

.goArrowLast {
  width: 20px;
  right: 20px;
  margin-left: 90px;
}

.nav-dropdown-main-link {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0px;
  align-items: center;
  font-family: "DM Sans";
  color: #585858;
  font-size: 18px;
}

.petmojo-mobile-nav-img {
  width: 140px;
}

.navigation-mobile-cross {
  width: 24px;
  height: 24px;
}

.nav-dropdown-row-first {
  display: flex;
  flex-direction: row;

  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.nav-dropdown-row {
  display: flex;
  flex-direction: row;

  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 5px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.nav-dropdown-row-last {
  display: flex;
  flex-direction: row;

  width: 100%;
  margin-top: 10px;
}

.no-border {
  border: none;
}

.dropdown-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: rgba(93, 93, 93, 0.8);
  margin-left: 5px;
  margin-right: 5px;
}

.dropdown-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  margin-left: 5px;
  margin-right: 5px;
}

.grooming-meet-btn {
  position: absolute;

  display: flex;
  justify-content: center;

  left: 28%;
  bottom: 20px;
}

/* .dropdown{
  min-width: 214px;

  height: 31px;

  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #FF5E95;
  margin-bottom: 60px;
}

.dropdown-btn{
  min-width: 214px;
  height: 31px;

  outline: none;
  border: 2px solid #FF5E95;
  filter: drop-shadow(0px 4px 4px rgba(255, 94, 149, 0.25));
  box-shadow: 0px 4px 4px rgba(104, 0, 130, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #FF5E95;
  
}

.dropdown-content{
  border: 2px solid #FF5E95;
  filter: drop-shadow(0px 4px 4px rgba(255, 94, 149, 0.25));
  padding: 5px;
  min-width: 214px;
  max-width: 214px;
  margin-bottom: 40px;

  box-shadow: 0px 4px 4px rgba(104, 0, 130, 0.25);
  padding-left: 10px;
  z-index: 100;
  background-color: white;
}

.dropdown-item{
  transition: all 0.2s;
  cursor: pointer;
}

 .dropdown-option{
  padding-left: 10px;
}

.dropdown-icon{
  padding-right: 10px;
} 

.dropdown-item:hover{
  background: #fcfcfc;
} */

.brand-promises {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: auto;

  margin-top: 20px;
}

.make-pet-smile {
  margin-top: 20px;
}

.make-pet-smile-grooming {
  margin-top: 20px;
}

.home-training-package-1 {
  width: 340px;
  height: 205px;
  background: white;
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.home-training-desktop-package-1 {
  width: 340px;
height: 200px;
  background: white;
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.home-training-package-1-desktop {
  width: 460px;
  height: 250px;
  background: linear-gradient(180deg, #fd6b6a 0%, #e60f7d 100%);
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;
  padding: 25px 0px;
}

.home-walking-package-1-desktop {
  width: 440px;
  height: 250px;
  background: linear-gradient(213.04deg, #ffae7f 0.01%, #fe7195 126.55%);
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;

  padding: 20px 0px;
}

.home-training-package-2 {
  width: 340px;
  height: 180px;

  background: linear-gradient(180deg, #730190 0%, #d55195 89.06%);
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;
}

.home-training-package-2-desktop {
  width: 460px;
  height: 250px;

  background: linear-gradient(180deg, #730190 0%, #d55195 89.06%);
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;

  padding: 25px 0px;
}

.home-training-package-2-desktop-revert {
  width: 460px;
  height: 250px;

  background: linear-gradient(0deg, #730190 0%, #d55195 89.06%);
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;

  padding: 25px 0px;
}

.home-walking-package-2-desktop {
  width: 440px;
  height: 250px;

  background: linear-gradient(204.68deg, #ff8a89 13.71%, #ef2a7c 101.82%);
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;

  padding: 25px 0px;
}

.home-training-package-3 {
  width: 340px;
  height: 180px;

  background: linear-gradient(180deg, #00d6bb 0%, #007c81 89.06%);
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;
}

.home-training-package-3-desktop {
  width: 460px;
  height: 250px;

  background: linear-gradient(180deg, #00d6bb 0%, #007c81 89.06%);
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;

  padding: 25px 0px;
}

.home-training-package-4-desktop {
  width: 420px;
  height: 250px;

  background: linear-gradient(180deg, #f8ad3a 0%, #e61c75 89.06%);
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;

  padding: 25px 0px;
}

.home-training-package-5-desktop {
  width: 420px;
  height: 250px;

  background: linear-gradient(180deg, #49ebf1 0%, #9d42e4 89.06%);
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;

  padding: 25px 0px;
}

.home-training-package-4 {
  width: 340px;
  height: 180px;

  background: linear-gradient(180deg, #f8ad3a 0%, #e61c75 89.06%);
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;
}

.home-training-package-5 {
  width: 340px;
  height: 180px;

  background: linear-gradient(180deg, #49ebf1 0%, #9d42e4 89.06%);
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;
}

.home-packages {
  height: 230px !important;
}

.home-packages-desktop {
  height: 270px !important;

  display: flex;
  /* flex-direction: row; */
  align-items: center;
}

.home-page-walking-packages-desktop-container2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-family: "DM Sans";
  font-size: 20px;
  color: #848484;
}
.home-page-walking-packages-desktop-container2 h4{
  font-weight: 400;
}

.home-page-training-packages-subtext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
  font-family: "DM Sans";
  color: rgba(132, 132, 132, 1);
  font-size: 20px;
}

.bold-grey {
  font-weight: bold;
}

.faded-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 15px;

  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.22) 0%,
    rgba(255, 255, 255, 0.54) 38.6%,
    rgba(255, 255, 255, 0) 96.63%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  opacity: 0.8;

  position: absolute;
  left: 30px;
  top: 15px;

  height: 35px;
  padding-top: 5px;
}

.our-customers-desktop {
  font-family: "DM Sans";
  font-size: 50px;

  color: rgba(88, 88, 88, 1);

  margin-top: 20px;

  margin-left: 100px;
}

.unfaded-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 15px;
  color: white;
}

.unfaded-desktop-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: white;
}

.unfaded-title-desktop {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 15px;
  color: #ffffff;

  text-align: left;

  margin-top: 20px;
  margin-left: 20px;
}

.home-training-package-1-header {
  background: linear-gradient(
    180deg,
    rgba(253, 106, 106, 1),
    rgba(230, 17, 124, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.home-training-package-2-header {
  background: linear-gradient(
    180deg,
    rgba(122, 7, 144, 1),
    rgba(202, 72, 148, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.home-training-package-3-header {
  background: linear-gradient(
    180deg,
    rgba(0, 212, 185, 1),
    rgba(0, 132, 134, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.home-training-package-4-header {
  background: linear-gradient(
    180deg,
    rgba(248, 167, 61, 1),
    rgba(231, 39, 113, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}
.home-training-package-5-header {
  background: linear-gradient(
    180deg,
    rgba(232, 48, 110, 1),
    rgba(248, 165, 62, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.home-training-package-7-header {
  background: linear-gradient(180deg, #eccd28 0%, #be9b20 100%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.home-training-desktop-package-5-header {
  background: linear-gradient(
    180deg,
    rgba(232, 48, 110, 1),
    rgba(248, 165, 62, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.home-training-desktop-package-7-header {
  background: linear-gradient(180deg, #eccd28 0%, #be9b20 100%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.home-training-package-6-header {
  background: linear-gradient(
    180deg,
    rgba(78, 226, 240, 1),
    rgba(152, 78, 229, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.home-training-desktop-package-6-header {
  background: linear-gradient(
    180deg,
    rgba(78, 226, 240, 1),
    rgba(152, 78, 229, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.home-walking-package-1-header {
  background: linear-gradient(
    180deg,
    rgba(255, 163, 131, 1),
    rgba(254, 128, 143, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.home-grooming-package-1-header {
  background: linear-gradient(
    180deg,
    rgba(80, 165, 236, 1),
    rgba(117, 99, 215, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.home-grooming-package-2-header {
  background: linear-gradient(
    180deg,
    rgba(229, 70, 147, 1),
    rgba(247, 92, 114, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.home-grooming-package-3-header {
  background: linear-gradient(
    180deg,
    rgba(120, 5, 144, 1),
    rgba(120, 5, 144, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.home-grooming-package-4-header {
  background: linear-gradient(
    180deg,
    rgba(0, 209, 184, 1),
    rgba(0, 131, 133, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.home-walking-package-2-header {
  background: linear-gradient(
    180deg,
    rgba(255, 138, 137, 1),
    rgba(241, 53, 125, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.mini-package-cards-content-container {
  display: flex;
  justify-content: space-between;
  font-family: "DM Sans";
  color: rgba(132, 132, 132, 1);
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 20px;
}

.mini-package-cards-content-left {
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}

.mini-package-cards-content-left-upper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  margin-top: 10px;
}

.mini-package-cards-desktop-content-left-upper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'DM Sans';
font-style: italic;
font-weight: 500;
font-size: 12px;
line-height: 15px;
/* or 125% */


/* Sub Text */

color: #848484;
  margin-top: 10px;
}

.home-package-subtitle {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: white;
}
.home-package-desktop-subtitle {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: white;
}

.home-package-subtitle-desktop {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #ffffff;

  text-align: left;

  margin-left: 20px;
  margin-top: 10px;
}

.arrowIconDesktop {
  position: absolute;
  top: 25px;
  left: 32%;

  width: 40px;
}

.home-page-intro-heading {
  font-family: "DM Sans";
  font-size: 25px;
  position: absolute;
  left: 20px;

  color: rgba(88, 88, 88, 1);
}

.home-walking-package-1 {
  width: 340px;
  height: 180px;

  background: linear-gradient(213.04deg, #ffae7f 0.01%, #fe7195 126.55%);
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;
}

.home-walking-package-2 {
  width: 340px;
  height: 180px;

  background: linear-gradient(204.68deg, #ff8a89 13.71%, #ef2a7c 101.82%);
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  border-radius: 20px;
}

.arrow-box {
  position: absolute;
  bottom: 20px;
  left: 25px;

  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.mini-card-arrow-icon {
  width: 130px;
}

.mini-card-arrow-icon-desktop {
  /* width: 170px; */
  width: 92px;
height: 37px
}

.arrow-box-desktop {
  position: absolute;
  bottom: 10px;
  left: 10px;

  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
}

.home-page-why-choose-container {
  background-color: rgba(248, 248, 248, 1);

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px 0px 20px 120px;

  position: relative;
}

.hero-image-container-desktop-right {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.petmojo-partner-name-desktop {
  position: absolute;
  color: rgba(88, 88, 88, 1);
  font-size: 25px;
  font-family: "DM Sans";
  bottom: 30px;
}

.petmojo-partner-name-desktop-cursive {
  font-family: "Damion", cursive;
}

.home-page-why-choose-container3 {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.home-page-why-choose-container3-img {
  width: 100%;
  margin-bottom: 100px;
  margin-right: 100px;
}

.desktop-footer-buttons-right {
  font-family: "DM Sans";
  font-size: 20px;
  color: rgba(93, 93, 93, 1);

  width: 30%;

  display: flex;
  align-items: center;
}

.home-page-why-choose-container1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.home-page-why-choose-container4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

  width: 350px;
}

.home-page-why-choose-container1-content h4{
  font-weight: 400;
}
.home-page-why-choose-container1-content {
  font-family: "DM Sans";
  font-size: 20px;
  color: rgba(132, 132, 132, 1);
  /* margin-top: 20px; */
}

.fifty-font-size {
  font-size: 50px;
  font-weight: 500;
}

.blogs-header-font-size {
  font-size: 35px;
  font-weight: 500;
}

.home-page-why-choose-container1-heading {
  font-size: 50px;
  color: rgba(88, 88, 88, 1);
  font-family: "DM Sans";
}

.non-bold {
  font-weight: 500;
}

.home-page-why-choose-container1-heading-seo {
  font-size: 50px;
  color: rgba(88, 88, 88, 1);
  font-family: "DM Sans";
  width: 350px;

  position: relative;
}

.arrow-icon {
  width: 92px;
  height: 50px;
  padding: 0;
  margin: 0;
}

.packageSilverHeading {
  font-size: 40px;
  font-family: "DM Sans";

  text-align: left;

  opacity: 80%;
  font-weight: 700;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.22) 0%,
    rgba(255, 255, 255, 0.54) 49.49%,
    rgba(255, 255, 255, 0.12) 96.63%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  margin-left: 20px;
  margin-top: 10px;
}

.arrow-icon-desktop {
  width: 120px;
  height: 68px;
  padding: 0;
  margin: 0;
}

.privacy-terms {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  margin-top: 20px;
}

.footer {
  margin-bottom: 100px;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
}

.footer-logo {
  margin-left: 15px;
  margin-right: 15px;
}

.footer-box {
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 20px;
}

.footer-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;

  display: flex !important;
  justify-content: right !important;
  align-items: right !important;
}

.footer-text-container {
  margin-left: 20px;
  margin-right: 20px;

  display: flex !important;
  flex-direction: column !important;
  justify-content: right !important;
  align-items: right !important;

  width: 50%;
}

.footer-text-div {
  transition: all 500ms;
}

.homepage-grooming-bg {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
}

.footer-support {
  display: flex;
  flex-direction: column;
  margin-left: 18px;
}

.support-container {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.support-container-sp {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  padding-bottom: 3px;
}

.support-text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.support-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #989898;

  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  margin-left: 10px;
  margin-right: 10px;
  transition: all 300ms;
}

.support-text-sp {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #989898;

  padding-bottom: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  margin-left: 10px;
  margin-right: 10px;
  transition: all 300ms;
}

.support-text-pink {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 1000;
  font-size: 12px;
  line-height: 20px;
  color: #ff5e95;

  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  margin-left: 10px;
  margin-right: 10px;
  transition: all 300ms;
}

.support-text:hover {
  color: rgb(252, 103, 128);
  font-weight: 1000;
  font-size: 13px;
}

.call-icon,
.mail-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  width: 25px;
  height: 25px;
}

.footer-title {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  margin-left: auto;
  margin-right: auto;
}

.pink-container {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  /* margin-top: 15px;
  margin-bottom: 15px; */
}

.pink-container-family {
  padding-top: 20px;
}

.groomer-meet {
  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_904_mppxbh.png");
  background-repeat: no-repeat;
  background-position: cover;

  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: "DM Sans";
  color: white;
  padding: 20px 20px;
}

.pink-container-grey {
}

.pink-container-left-box1-line {
  border: 3px solid rgba(254, 119, 152, 1);
  border-radius: 10px;

  width: 150px;

  position: absolute;
  top: 130px;
  left: 20px;
}

.pink-container-left-box2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-size: 20px;
  font-family: "DM Sans";

  color: #848484;

  /* margin-top: 20px; */
}

.pink-container-left-box3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.one-percent {
  color: rgba(254, 119, 152, 1);
}

.pink-container-left-box1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
}

.pink-container-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pink-container-desktop {
  padding: 60px 120px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-container-subtext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;

  margin-left: 35px;
}

.red-text {
  color: rgba(255, 35, 35, 1);
  font-size: 12px;
  font-family: "DM Sans";
  margin-top: 10px;
  font-weight: 700;
  margin-left: 5px;
}

.pink {
  color: rgba(255, 94, 149, 1);
}

.pink-container-right {
  margin-left: 150px;
}

.pink-container-right-head {
  font-family: "DM Sans";
  font-size: 24px;

  text-align: center;
}

.trainer-card-border-desktop {
  width: 150%;
  height: 170%;
}

.trainer-card-border {
}

.trainer-card-border-mobile {
  width: 90%;
}

.pink-container-grooming {
  padding-top: 40px;
}

.pet-bold-text {
  font-weight: 600;
}

.dropdown-link {
  outline: none;
  display: flex;
  flex-direction: row;
  border: none;
  text-decoration: none;
}

.container {
  width: 95%;
  margin: 1rem auto;
  margin-top: 30px;

  background-color: rgba(248, 248, 248, 1);
}
.container-styling {
  width: 100%;
  margin: 1rem auto;
  margin-top: 30px;
  background-color: rgba(248, 248, 248, 1);
}

.container-desktop {
  background-color: rgba(248, 248, 248, 1);
  padding: 60px 120px;
}

.container-desktop-walking {
  padding: 60px 120px;
}

.timeline-container-landing {
  display: flex;
  justify-content: center;
}

.container-home {
  width: 100%;

  background-color: rgba(248, 248, 248, 1);
}

.margin-top-training-started {
  padding-top: 20px;
}

.container-home2 {
  width: 100%;
}

.container-home-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 60px 120px;

  background-color: rgba(248, 248, 248, 1);
}

.get-started-walking-head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.timeline-component-container {
}

.walking-page-trainer-meet-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 60px 120px;
}

.walking-page-trainer-meet-right {
  right: 10px;
  top: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.get-started-walking-content {
  color: #848484;
  font-size: 20px;
  font-family: "DM Sans";
}

.container-home-desktop-subtext {
  color: #848484;
  font-family: "DM Sans";
  font-size: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 20px;

  width: 350px;
}

.twenty-font-size {
  font-size: 20px;
}

.sliderServiceContainer {
}

.sliderServiceContainer-desktop {
  background-color: white;

  padding: 60px 120px;
}

.slider-service-container-desktop {
  padding: 60px 120px;
}

.giveSpace {
  margin-top: 40px;
  background-color: rgba(248, 248, 248, 1);
}

@media screen and (min-width: 500px) {
  .intro-image-trainingPg {
    background-color: #000;
  }
}

@media only screen and (min-width: 500px) {
  .homepage-intro {
    width: 100%;
    margin-top: 50px;
  }

  .intro-container-training {
    transform: scale(1);
    margin-left: 20%;
  }

  .services-container {
    width: 500px;
  }

  .container-home {
    width: 500px;
  }

  .heroImage1 {
    width: 500px;
  }
}

.intro-btn-social {
  position: absolute;

  bottom: 100px;
  left: 10px;
}

.smallGst {
  font-size: 13px;
}

.socials-intro {
  width: 80vw;

  padding-left: 10px;
}

.intro-landing-image {
  width: 80%;
  height: 100%;
  margin-left: 20%;
}

.footerDetailsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 60px 120px 0 120px;
  background-color: rgba(248, 248, 248, 1);

  font-family: "DM Sans";
}

.footerDetailsHeading {
  font-size: 30px;
  color: rgba(88, 88, 88, 1);
  position: relative;
}
.footerDetailsHeading-mobile {
  font-size: 20px;
  color: rgba(88, 88, 88, 1);
  position: relative;
  height: 40px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.footerDetailsLine {
  border: 1px solid rgba(254, 119, 152, 1);
  border-radius: 10px;
  position: absolute;

  width: 130px;
}

.footerDetailsContentContainer {
  margin-top: 20px;

  display: flex;
  flex-direction: column;
}

.footerDetailsContentContainer-mobile {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footerDetailsLink {
  text-decoration: none;
  color: rgba(152, 152, 152, 1);
  font-size: 20px;
  padding: 2px 0px;

  transition: 100ms;
}

.footerDetailsLink-mobile {
  text-decoration: none;
  color: rgba(152, 152, 152, 1);
  font-size: 14px;
  padding-top: 1px;
  transition: 100ms;
}

.footerDetailsLink:hover {
  color: rgb(252, 103, 128);
  font-weight: 700;
}

.footer-image-desktop-container {
  display: flex;
  justify-content: space-between;

  margin: 30px 120px;

  margin-top: 100px;
}

.footer-image-desktop-right {
  display: flex;
  flex-direction: column;
}

.footer-image-desktop {
  margin-left: 120px;
}

.store-desktop-mainImage {
  width: 800px;
}

.store-desktop-petmojoIcon {
  width: 200px;
}

.store-desktop-mainImage-container {
  display: flex;
  justify-content: center;
}

.store-desktop-petmojoIcon-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 220px;
  padding-top: 50px;
}

.store-desktop-petmojoIcon {
  position: absolute;
  right: 220px;
}

.store-desktop-buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 0% 20%;
}

.dropdown-desktop-container {
  position: absolute;
  right: 70px;
  top: 90px;

  border: 2px solid rgb(196, 196, 196);
  border-radius: 10px;
  padding: 15px 25px;

  box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 8px;

  font-family: "DM Sans";
  font-size: 14px;

  color: rgba(88, 88, 88, 1);

  transition: all 1s;
}

.no-decoration {
  text-decoration: none;
}

.dropdown-desktop-item {
  display: flex;
  align-items: center;
  color: rgba(88, 88, 88, 1);

  font-family: "DM Sans";
  font-size: 16px;

  text-decoration: none;

  transition: all 300ms;
}

.dropdown-desktop-item:hover {
  cursor: pointer;
  color: rgb(252, 103, 128);
  font-size: 17px;
}

.dropdown-desktop-item-text {
  padding: 10px 5px;
  padding-left: 10px;
}

.dropdown-desktop-line {
  border-radius: 10px;
}

.grooming-intro-image {
  width: 100%;
}

.landing-page-intro-image-desktop {
  width: 720px;
}

.intro-grooming-right {
  display: flex;
  justify-content: end;
}

.get-started-grooming-page-sub-text-container {
  font-size: 20px;
  font-family: "DM Sans";
  color: #848484;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
}

.intro-grooming-right2 {
  display: flex;
  justify-content: end;
}

.track-session-point {
  display: flex;
  align-items: center;
}

.tick-img {
  width: 25px;
  padding-top: 5px;
}

.tick-img-mobile {
  width: 29px;
  height: 29px;
  object-fit: contain;
  margin-right: 3px;
}

.intro-grooming-container {
  display: flex;
  justify-content: space-between;

  font-family: "DM Sans";
  color: rgba(88, 88, 88, 1);

  margin-top: 80px;

  max-height: 1000px;
  padding-left: 120px;

  padding-right: 0px;
}

.intro-grooming-pink {
  color: rgba(254, 119, 152, 1);
}

.intro-grooming-left {
  display: flex;
  flex-direction: column;
}

.intro-training-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.intro-grooming-left-text {
  font-size: 50px;
}

.intro-grooming-cta {
  position: relative;
}

.our-customers-desktop-container {
  margin-top: 50px;

  margin-left: 120px;
  position: relative;
}

.our-customers-desktop-title {
  text-align: left;

  font-family: "DM Sans";
  font-size: 50px;
  color: rgba(88, 88, 88, 1);
}

.bold-pink-line-desktop-customers-grooming {
  border: 3px solid rgba(254, 119, 152, 1);
  border-radius: 10px;

  width: 150px;
  position: absolute;
}

.youtube-container-desktop {
  width: 100%;
}

.training-page-yt-right {
  border: 24px solid rgba(255, 229, 229, 1);
  border-radius: 5px;
}

.youtube-video-desktop {
  width: 550px;
  height: 400px;
}

.training-pet-points-container {
  display: flex;
}

.socials-page-intro-master {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px 120px;
  height: 80vh;
  padding-top: 50px;
}

.socials-page-intro-right-container {
  width: 420px;
}

.socials-page-intro-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 80%;
}

.socials-page-intro-left-1 {
  font-size: 50px;
  width: 80%;
}

.socials-page-intro-left-2 {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.home-page-desktop-background-image {
  width: 100%;
  background-position: bottom;
  top: 0px;
  left: 0px;
  object-fit: cover;
  height: 100vh;
}

.boarding-page-mobile-view-heart-container {
  padding-bottom: 20px;
  margin-top: 100px;
}

.desktop-head-line-combiner {
  padding: 0px 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 800px;
  margin-top: 20px;
}

.socials-page-desktop-view-card {
  width: 500px;
}

.socials-page-desktop-view-scrap-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0px 120px;
}

.socials-page-desktop-view-sweet-inspirations {
  background-color: rgba(248, 248, 248, 1);
}

.socials-page-desktop-view-connect {
  background-color: rgba(248, 248, 248, 1);
}

.socials-page-desktop-view-scrap-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: "DM Sans";
}

.socials-page-desktop-view-scrap-left-head {
  font-size: 45px;
  font-family: "Damion", cursive;
  color: rgba(88, 88, 88, 1);
}

.socials-page-desktop-view-scrap-left-content {
  font-size: 23px;
  color: rgba(94, 94, 94, 1);
  width: 280px;
  margin-top: 10px;
}

.desktop-view-pink-line {
  border: 3px solid rgba(254, 119, 152, 1);
  border-radius: 10px;
  width: 150px;
}

.socials-page-desktop-view-heading {
  color: rgba(88, 88, 88, 1);
  font-size: 40px;
  font-family: "DM Sans";
}

.socials-page-desktop-background-image {
  width: 100%;
  height: 100vh;
}

.home-page-desktop-background-video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  filter: contrast(1.3);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.testimonial-card-mastercontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  min-width: 350px;
  height: 400px;
  box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 8px;
  padding: 10px 10px;

  font-family: "DM Sans";

  border-radius: 20px;

  transition: all 500ms;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.testimonial-card-mastercontainer-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  width: 60%;
  height: 440px;
  box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 8px;
  padding: 10px 10px;

  font-family: "DM Sans";

  border-radius: 20px;

  transition: all 500ms;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.testimonial-card-mastercontainer-mobile-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  width: 100%;
  height: 440px;
  box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 8px;
  padding: 10px 10px;

  font-family: "DM Sans";

  border-radius: 20px;

  transition: all 500ms;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.star-icon-desktop-image {
  width: 20px;
}

.testimonial-card-mastercontainer-2:hover {
  color: white;
}

.grey-line {
  border: 2px solid rgba(165, 165, 165, 1);
  width: 100px;
  border-radius: 10px;
  margin-top: 20px;
}

.testimonial-card-upper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #848484;

  width: 100%;
}

.testimonial-user-image {
  width: 150px;
}

.testimonial-card-lower-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(41, 49, 60, 1);
}

.testimonial-card-reviewer-name {
  font-size: 20px;
  font-weight: 700;
  color: rgba(41, 49, 60, 1);
}

.testimonial-card-reviewer-profession {
  color: rgba(132, 132, 132, 1);
}

.wrapper-class-intro-desktop-video::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  content: "";
  /* background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 100%
  ); */
}

.wrapper-class-intro-desktop-video-grooming {
  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-vid-compress.mp4");
  background-repeat: no-repeat;
  background-size: cover;
}

.intro-desktop-home-page-master-container {
  position: relative;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  font-family: "DM Sans";

  /* height: 100vh; */
}

.intro-desktop-home-page-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: "DM Sans";
  color: white;

  position: absolute;
  top: 0px;
  left: 0px;
  /* height: 100vh; */
}

.intro-desktop-home-page-header {
  font-size: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.intro-desktop-head-combiner {
  padding: 10px 100px;
  margin-top: 40px;
  border-radius: 20px;
  padding-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.intro-desktop-head-combiner-boarding {
  padding: 10px 100px;
  margin-top: 40px;
  border-radius: 20px;
  padding-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.intro-desktop-head-combiner-grooming {
  padding: 0px 100px;
  padding-bottom: 10px;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.home-page-desktop-intro-form-contents {
  width: 100%;
  color: rgba(114, 114, 114, 1);
  font-size: 20px;
}

.intro-desktop-home-page-points-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;

  margin-top: 0px;
}
.intro-desktop-walking-page-points-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  /* margin-top: 40px; */
}

.intro-desktop-boarding-page-points-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  margin-top: 0px;
}

.home-page-desktop-intro-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  font-family: "DM Sans";
  width: 90%;

  margin-top: 40px;

  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;

  border: 3px solid #d3d3d3;
}

.home-page-desktop-intro-form-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  font-family: "DM Sans";
  width: 80%;

  margin-top: 20px;

  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;

  border: 3px solid #d3d3d3;
}

.home-page-desktop-intro-form-container-home-page-grooming {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  font-family: "DM Sans";
  background-color: rgba(255, 255, 255, 1);

  width: 100%;
  font-size: 16px;
}

.selection-circle-desktop {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #E7E7E7;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.grooming-new-form-section1-left-choice-name-container{
  display: flex;
  align-items: center;
}

.selection-circle-desktop .dot {
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #FF5E95;

}

.social-page-mobile-view-card-subtext {
  font-family: "DM Sans";
  color: rgba(85, 85, 85, 1);
  font-size: 12px;
}

.intro-desktop-home-page-points-p1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 25px;
}



.intro-desktop-help-page-points-p1 {
  display: flex;
  align-items: center;
  font-size: 25px;
  gap: 15px;
  align-items: center;
}

.socials-page-desktop-disc {
  color: rgba(241, 76, 76, 1);
  font-size: 12px;
  text-align: center;

  font-weight: 700;
}

.socials-page-intro-left-3 {
  margin-top: 20px;
}

.intro-desktop-social-page-points-p1 {
  display: flex;
  align-items: center;
  font-size: 25px;
}

.home-page-desktop-intro-form-section1 {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.home-page-desktop-intro-form-section2 {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-top: 30px;
}

.grooming-form-tagline-text{
  color:black;
  font-size: 15px;
  margin-top: 20px;
}   

.home-page-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  align-items: center;
  padding: 10px 30px;
  width: 100%;
}

.home-page-desktop-intro-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  font-size: 25px;

  background-color: rgba(247, 248, 249, 1);
  border-radius: 20px 20px 0px 0px;
  color: rgba(88, 88, 88, 1);
}

.walking-page-desktop-intro-form-header {
  text-align: center;

  width: 100%;

  font-size: 25px;

  background-color: rgba(247, 248, 249, 1);
  border-radius: 20px 20px 0px 0px;
  color: rgba(88, 88, 88, 1);
  padding: 15px;
}

.walking-form-input {
  width: 150px;
  color: rgba(114, 114, 114, 1);
  height: 40px;
  border: 1px solid rgba(131, 145, 161, 0.5);
  border-radius: 10px;
  margin-top: 5px;
  padding: 5px 10px;
  text-align: left;

  background-color: #f7f8f9;
}

.walking-form-input::placeholder {
  color: rgba(131, 145, 161, 0.5);
}

.walking-form-input-phone {
  width: 350px;
  color: rgba(114, 114, 114, 1);
  height: 40px;
  border: 1px solid rgba(131, 145, 161, 0.5);
  border-radius: 10px;
  margin-top: 5px;
  padding: 5px 10px;
  text-align: left;

  background-color: #f7f8f9;
}

.walking-form-input-phone::placeholder {
  color: rgba(131, 145, 161, 0.5);
}

.walking-form-input-phone {
  width: 350px;
  color: rgba(114, 114, 114, 1);
  height: 40px;
  border: 1px solid red(131, 145, 161, 0.5);
  border-radius: 10px;
  margin-top: 5px;
  padding: 5px 10px;
  text-align: left;

  background-color: #f7f8f9;
}

.walking-form-input-location {
  width: 400px;
  color: rgba(114, 114, 114, 1);
  height: 100%;
  border: 1px solid rgba(131, 145, 161, 0.5);
  border-radius: 10px;
  margin-top: 5px;
  padding: 5px 10px;
  text-align: left;

  background-color: #f7f8f9;
}

.walking-form-input-location::placeholder {
  color: rgba(131, 145, 161, 0.5);
  position: absolute;
  top: 10px;
}

.home-page-form-inner-container-right {
  height: 100%;
}

.home-page-desktop-intro-form-head1 {
  text-align: center;
}

.home-page-desktop-intro-form-head1-container {
  width: 40%;
}

.home-page-desktop-intro-form-head2-container {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.home-page-desktop-intro-form-head1-first {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  border-radius: 0px 0px 0px 0px;
}

.home-page-desktop-intro-form-head1-first:hover {
  cursor: pointer;
}

.home-page-desktop-intro-form-head1-first-train {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  border-radius: 20px 0px 0px 0px;
}

.home-page-desktop-intro-form-head1-first-train:hover {
  cursor: pointer;
}

.home-page-desktop-intro-form-head1:hover {
  cursor: pointer;
}

.home-page-desktop-intro-form-head1-last:hover {
  cursor: pointer;
}

.home-page-desktop-intro-form-head1 {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
}

.home-page-desktop-intro-form-head1-last {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  border-radius: 0px 20px 0px 0px;
}

.active-form {
  background-color: rgba(255, 94, 149, 1);
  color: white;
  box-shadow: inset 0px 4px 15px rgba(0, 0, 0, 0.25);
}

.home-page-form-inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 170px;
}

.home-page-form-inner-container-left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.home-page-form-bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  width: 100%;
}

.grooming-page-form-bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.grooming-page-form-bottom-container-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.home-page-intro-bottom-text {
  color: rgba(255, 94, 149, 1);
  font-size: 18px;
}

.home-page-intro-bottom-btn {
  /* padding-right: 75px; */
}

.home-page-form-container-grooming {
  display: flex;
  flex-direction: column;
  font-family: "DM Sans";
}

.home-page-form-grooming-upper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  height: 100%;
}

.home-page-form-grooming-upper-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
  height: 100%;
}

.home-page-form-grooming-upper-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.home-page-form-grooming-gr-upper-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.grooming-page-form-grooming-upper-left {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.home-page-form-grooming-upper-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 10px;
  width: 50%;
  height: 100%;
  margin-left: 20px;
}

.grooming-page-desktop-session-date-container {
}

.grooming-page-desktop-session-date-container2 {
  flex: 1;
}

.home-page-form-grooming-upper-right-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0px;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.home-page-form-grooming-upper-right-datentime {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.home-page-form-grooming-date {
  width: 150px;
  height: 40px;
  border: 1px solid rgba(232, 236, 244, 1);
  border-radius: 10px;
  color: rgba(131, 145, 161, 0.5);
  text-align: center;
}
.home-page-form-grooming-date2 {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(232, 236, 244, 1);
  border-radius: 10px;
  color: rgba(131, 145, 161, 0.5);
  text-align: left;
  padding: 0 10px;
}

.home-page-form-grooming-date::placeholder {
  color: red;
}
.walking-form-input-location-grooming {
  border: 1px solid rgba(232, 236, 244, 1);
  border-radius: 10px;
  color: rgba(88, 88, 88, 1);

  width: 100%;
  height: 40px;
  padding-left: 10px;
}

.walking-form-input-location-grooming-1 {
  border: 1px solid rgba(232, 236, 244, 1);
  border-radius: 10px;
  color: rgba(88, 88, 88, 1);

  width: 100%;
  height: 40px;
  padding-left: 10px;
}

.walking-form-input-location-grooming::placeholder {
  color: rgba(131, 145, 161, 0.5);
}
.intro-form-home-page-address-grooming {
  margin-top: 10px;
}

.intro-form-home-page-address-grooming-1 {
  width: 100%;
  margin-top: 10px;
  margin-left: 30px;
}

.intro-form-home-page-phone-grooming {
  margin-top: 10px;
}

.intro-form-home-page-email-grooming {
  width: 100%;
  margin-left: 20px;
}
.intro-form-home-page-email-grooming2 {
  /* width: 100%; */
  flex: 1;
  margin-left: 20px;
}

.walking-page-desktop-intro-form-contents-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  padding: 30px;
}

.boarding-page-desktop-intro-form-contents-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  padding: 30px;
}

.walking-page-desktop-intro-form-contents-upper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.boarding-page-desktop-intro-form-contents-master {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.boarding-page-desktop-intro-form-contents-submit-button {
  margin-top: 20px;
}

.boarding-page-desktop-intro-form-contents-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.boarding-page-desktop-intro-form-contents-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  height: 100%;
  width: 50%;
}

.boarding-page-desktop-view-date-text {
  color: rgba(88, 88, 88, 1);
  font-size: 20px;
}

.walking-page-desktop-intro-form-contents-upper-left-address {
  margin-top: 10px;
}

.boarding-page-desktop-intro-form-contents-right-upper {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
}

.walking-page-desktop-intro-form-contents-lower {
  width: 100%;
  text-align: center;
  margin-top: 30px;

  color: #ff5e95;
}

.walking-page-desktop-intro-form-contents-upper-left {
  width: 50%;
  font-family: "DM Sans";
  color: #585858;
  font-size: 20px;
}

.walking-page-phone-input {
  width: 350px;
  height: 50px;
  border: 1px solid rgba(232, 236, 244, 1);
  border-radius: 10px;
  background-color: #f7f8f9;
  margin-top: 10px;

  padding-left: 20px;
}

.walking-page-phone-input::placeholder {
  color: rgba(131, 145, 161, 0.5);
}

.walking-page-margin-top {
  background-color: rgba(248, 248, 248, 1);
  padding-top: 20px;
}

.training-page-mobile-view-packageIcon {
  width: 100px;
  margin: auto;
}

.grooming-page-mobile-view-packageIcon {
  transform: scale(0.6);
  position: absolute;
  top: -20px;
  left: 0px;
}

.training-page-mobile-view-package-container {
  min-width: 300px;
  height: 33rem;
  margin-right: 10px;

  border-radius: 20px;
  position: relative;
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  margin-left: 10px;
  margin-top: 10px;
  font-family: "DM Sans";
}

.grooming-page-mobile-view-package-container {
  min-width: 300px;
  height: 33rem;
  margin-right: 10px;

  border-radius: 20px;
  position: relative;
  box-shadow: 10px 5px 10px rgba(201, 201, 201, 0.7);
  margin-left: 10px;
  margin-top: 10px;
  font-family: "DM Sans";
}

.grooming-page-mobile-view-package-container-12 {
  min-width: 280px;
  height: 32rem;
  margin-right: 10px;

  border-radius: 20px;
  position: relative;
  box-shadow: 10px 0px 25px rgba(201, 201, 201, 0.7);
  margin-left: 10px;
  margin-top: 10px;
  font-family: "DM Sans";
}

.grooming-page-mobile-view-tick-head {
  color: rgba(132, 132, 132, 1);
  font-size: 14px;
}

.grooming-page-mobile-view-tick-content {
  color: rgba(132, 132, 132, 0.5);
  font-size: 14px;
}

.grooming-page-mobile-view-tick-container {
  position: absolute;
  right: 15px;
  top: 190px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.training-page-mobile-view-package-sec-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 75px;
  bottom: 15px;
}

.training-page-mobile-view-package-sec-2 {
  background: linear-gradient(180deg, #fe8788 0%, #ea2179 100%);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.training-page-mobile-view-package-standard-sec-2 {
  background-color: rgba(253, 107, 106, 1);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.walking-page-mobile-view-package-sec-2 {
  background: linear-gradient(180deg, #fe9887 0%, #fe7195 100%);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}
.walking-page-mobile-view-package-sec-2-golden {
  background:linear-gradient(180deg, #EFCB26 0%, #C38C63 100%);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.walking-page-mobile-view-package-sec-2-two {
  background: linear-gradient(180deg, #fc7a87 0%, #ef297c 100%);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.grooming-page-mobile-view-package-sec-2-four {
  background: linear-gradient(180deg, #00baa4 0%, #006d70 100%);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
  margin-top: 117px;
}

.grooming-page-mobile-view-package-sec-2-three {
  background: linear-gradient(180deg, #67047e 0%, #cc4a94 100%);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.grooming-page-mobile-view-package-sec-2-two {
  background: linear-gradient(180deg, #df1f74 0%, #f8586d 100%);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.grooming-page-mobile-view-package-sec-2-one {
  background: linear-gradient(180deg, #48a0df 0%, #865be2 100%);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.training-page-mobile-view-package-sec-2-2 {
  /* background: linear-gradient(180deg, #7e0a90 0%, #ce4c95 100%); */
  /* background-color: #F8C817; */
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.training-page-mobile-view-package-standard-sec-2-2 {
  background-color: rgba(115, 1, 144, 1);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.walking-page-mobile-view-packages-master-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: rgba(248, 248, 248, 1);
}

.training-page-mobile-view-package-sec-2-3 {
  background: linear-gradient(180deg, #00c4b0 0%, #008084 100%);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.training-page-mobile-view-package-standard-sec-2-3 {
  background-color: rgba(0, 164, 155, 1);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.training-page-mobile-view-package-sec-2-7 {
  background: linear-gradient(180deg, #4bed48 0%, #1f951d 100%);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.training-page-mobile-view-package-sec-2-4 {
  background: linear-gradient(180deg, #f69c42 0%, #e72472 100%);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.training-page-mobile-view-package-sec-2-5 {
  background: linear-gradient(180deg, #34d0f6 0%, #9948e4 100%);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.training-page-mobile-view-package-sec-2-6 {
  background: linear-gradient(180deg, #edce28 0%, #b5921f 100%);
  color: white;
  padding: 5px 0px;
  font-size: 20px;
}

.training-page-mobile-view-package-sec-3 {
}

.training-page-mobile-view-package-sec-3-o {
  padding: 3px;
}

.training-page-mobile-view-package-sec-3-p {
  padding: 3px;
  color: rgba(62, 62, 62, 1);
}

.training-package-agression-disclaimer {
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.training-page-mobile-view-package-section3 {
  padding: 10px 20px;
  text-align: left;
  font-size: 14px;
  font-family: "DM Sans";
  color: rgba(132, 132, 132, 1);
}

.training-page-mobile-view-package-sec-3-1 {
  color: rgba(88, 88, 88, 1);
  font-size: 14px;
  margin-top: 10px;
}

.grooming-page-mobile-view-package-sec-3-1 {
  background: linear-gradient(180deg, #e7127c 0%, #fc696a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 700;
}

.package-bold {
  font-weight: 700;
  color: rgba(62, 62, 62, 1);
}

.margin-for-desktop-training {
  background-color: rgba(248, 248, 248, 1);
}

.home-page-desktop-phone-validation-text {
  color: red;
  font-size: 12px;
}
.home-page-desktop-input-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.select-city-container {
  display: flex;
  align-items: center;
}
.select-city-container img {
  width: 15px;
  height: 15px;
  margin-left: 2px;
}
.home-page-form-inner-container-left-subcontainer {
}

.invalid {
  border: 1px solid red;
}

.main-intro-image-mobile {
  width: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  height: 70vh;
}



.home-page-intro-mobile-view-test {
  position: relative;
  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/homepagemobile.webp");
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100vh; */
  padding-bottom: 20px;
}


.home-page-intro-mobile-view {
  position: relative;
  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/homepage-mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  padding-bottom: 20px;
}

.walking-page-intro-mobile-view {
  position: relative;

  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/walkingmobile.webp");
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100vh; */

  padding-bottom: 20px;
}

.social-page-intro-mobile-view {
  position: relative;

  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/socials-mobile-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  padding-bottom: 20px;
}

.social-page-mobile-section-container {
  font-family: "DM Sans";
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 80px;
  padding: 0px 20px;
}

.social-page-mobile-section-main-header {
  font-size: 25px;
  color: rgba(93, 93, 93, 1);
  text-align: center;
  font-family: "DM Sans";
  margin-top: 40px;
  padding: 0px 10px;
}

.social-page-mobile-section-image {
  width: 100%;
  max-width: 350px;
  /* max-width:  */
}

.social-page-mobile-cursive-header {
  color: rgba(88, 88, 88, 1);
  font-size: 35px;
  font-family: "Damion", cursive;
}

.social-page-mobile-cursive-content {
  text-align: center;
  padding-top: 10px;
  width: 250px;
  font-size: 18px;
  color: rgba(94, 94, 94, 1);
}

.training-page-intro-mobile-view {
  /* position: relative; */
  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/trainingmobile.webp");
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100vh; */
padding-top: -1rem;
  padding-bottom: 20px;
  /* top:0; */
}
.delete-page-intro-mobile-view {
  /* position: relative; */
  background-image: url('../images/DeleteBgMob.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
padding-top: -1rem;
  padding-bottom: 20px;
  /* top:0; */
}
.grooming-page-intro-mobile-view-new {
  position: relative;
  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/groomingmobile.webp");
  background-repeat: no-repeat;
  background-size: contain;
  /* height: 100vh; */

  padding-bottom: 20px;
}

.grooming-page-intro-mobile-view-new-uc {
  position: relative;
  /* background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/groomingmobile.webp"); */
  background-repeat: no-repeat;
  background-size: contain;
  /* height: 100vh; */

  padding-bottom: 20px;
}



.boarding-page-intro-mobile-view {
  position: relative;

  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boardingmobile.webp");
  background-repeat: no-repeat;
  background-size: contain;
  /* height: 100vh; */
}

.boarding-page-mobile-view-viewport {
  /* height: 100vh; */
}

.grooming-page-intro-mobile-view {
  position: relative;
  background-image: url("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-mobile-compressed2.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100vh; */
  padding-bottom: 20px;
}

.home-page-intro-mobile-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 500px !important; */
  font-family: "DM Sans";
  margin-top: 20px;
  /* margin-bottom: 0px; */
  
}

.home-page-intro-mobile-view-container-2 {
  display: flex;
  flex-direction: column;
  align-items: center;  
  font-family: "DM Sans";
  margin-top: 30px;
}

.boarding-page-intro-mobile-view-container-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "DM Sans";
}

.react-typewriter-text{
  font-size: 30px;
  display: inline;
}

.home-page-intro-mobile-view-heading {
  color: white;
  width: 80%;
  text-align: center;
  font-size: 30px;
}

.home-page-intro-mobile-view-heading-test {
  /* color: #fff; */
  width: 99%;
  text-align: center;
  font-size: 30px;
}


.home-page-desktop-heading {
  font-size: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.intro-heading-mobile-view-header {
  font-size: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.intro-heading-mobile-view-header div{
  font-size: 30px;
}
.home-page-intro-mobile-view-heading-training {
  color: white;
  width: 100%;
  text-align: center;
  font-size: 30px;
}

.home-page-intro-mobile-view-points {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  color: white;
}

.home-page-intro-mobile-view-points-training {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.boarding-page-intro-mobile-view-points-training {
  display: flex;
  flex-direction: column;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.home-page-intro-mobile-view-point {
  display: flex;
  align-items: top;
  /* font-size: 20px; */
  padding-top: 10px;
}

.home-page-intro-mobile-view-point-boarding {
  display: flex;
  align-items: top;
  font-size: 19px;
  padding-top: 10px;
}

.mobile-view-nav-dropdown {
  width: 50px;
}

.landing-page-mobile-view-heart-container {
  /* margin-top: 240px; */
  margin-top: 40px;
  background-color: rgba(248, 248, 248, 1);
}

@media screen and (min-width: 454px) {
  .landing-page-mobile-view-heart-container {
    /* margin-top: 150px; */
  }
}
@media screen and (min-width: 670px) {
  .landing-page-mobile-view-heart-container {
    /* margin-top: 110px; */
  }
}

.home-page-mobile-form-container {
  z-index: 100;
  width: 100%;
}

.mobile-view-footer-1 {
  display: flex;
  justify-content: space-between;
  font-family: "DM Sans";
  background-color: rgba(248, 248, 248, 1);
  padding-top: 10px;
}

.mobile-view-footer-1-left-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-view-footer-1-header {
  color: rgba(94, 94, 94, 1);
  font-size: 20px;
  position: relative;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.santa-hat{
  position: absolute;
  top:0;
  right:-20px;
}
.santa-hat-more{
  position: absolute;
  top:0;
  right:20px;
}


.mobile-view-tel-icon {
  width: 20px;
}

.mobile-view-mail-icon {
  width: 20px;
}

.mobile-view-footer-1-content-left {
  padding-left: 10px;
  color: #848484;
  font-size: 14px;
}

.mobile-view-footer-phone-container {
  display: flex;
  align-items: center;
}

.mobile-view-footer-mail-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.mobile-view-footer-phone-text {
  color: #848484;
}

.mobile-view-footer-mail-text {
  color: #848484;
}

.mobile-view-footer-1-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 10px;
}

.mobile-view-footer-1-right-content {
  font-size: 14px;
  text-align: right;

  width: 100%;
}
.mobile-view-footer-page-link {
  color: #848484;
}

.mobile-view-footer-2 {
  background-color: rgba(248, 248, 248, 1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #848484;
  padding: 20px 10px;
  font-family: "DM Sans";
  font-size: 14px;
}

.mobile-view-footer-2-left {
}

.mobile-view-links-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mobile-view-footer-2-right-header {
  font-size: 20px;
  color: rgba(94, 94, 94, 1);
  text-align: center;
  position: relative;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.mobile-view-footer-2-tnc {
  text-align: right;
}

.mobile-view-footer-3 {
      grid-template-columns: 1fr 1fr 1fr;
      /* background-color: red; */
      display: grid;
      justify-content: space-between;
      align-items: flex-end;
      padding: 10px 20px;
}
.mobile-view-footer-3-btn1{
  border-radius: 5px; 
   /* padding: 5px 5px;  */
   border-radius: 5px;   
  font-size: 13px;
  height: 33px;
  
}
.mobile-view-footer-3-btn2{
  border-radius: 5px; 
   /* padding: 5px 5px;  */
   border-radius: 5px;   
  font-size: 13px;
  height: 33px;
}
.mobile-view-footer-3-btn3 {
  display: flex;
  align-items: center;
  background-color: #ff5e95;
  color: white;
  font-family: "DM Sans";
  border-radius: 5px; 
   /* padding: 5px 5px;     */
  font-size: 13px;
  height: 33px;
  position: relative;
  /* bottom: 2px; */
}

.mobile-view-footer-appStore-icon {
  width: 100px;
}

.mobile-view-footer-4 {
  background-color: rgba(248, 248, 248, 1);
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "DM Sans";
  color: rgba(94, 94, 94, 1);
  font-size: 11px;
  padding-bottom: 20px;
}

.home-link-icon {
  width: 30px;
  margin-bottom: -10px;
}

.service-link-icon {
  width: 30px;
  margin-bottom: 0px;
}

.calling-icon-button {
  width: 60px;
}

@media screen and (max-height: 720px) {
  .intro-desktop-head-combiner {
    padding: 0px 100px;
    margin-top: 0px;
    border-radius: 20px;
    /* padding-bottom: 40px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .intro-desktop-head-combiner-boarding {
    padding: 0px 100px;
    margin-top: -30px;
    border-radius: 20px;
    /* padding-bottom: 40px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .walking-page-desktop-intro-form-contents-upper-left-address {
    margin-top: 0px;
  }

  .walking-page-desktop-intro-form-contents-upper-left {
    margin-top: 0px;
  }

  .walking-page-phone-input {
    width: 370px;
    height: 40px;
    border: 1px solid rgba(232, 236, 244, 1);
    border-radius: 5px;
    background-color: #f7f8f9;
    margin-top: 10px;

    padding-left: 20px;
  }

  .walking-page-phone-input::placeholder {
    color: rgba(131, 145, 161, 0.5);
  }

  .intro-desktop-home-page-header {
    font-size: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .home-page-desktop-background-video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    filter: contrast(1.3);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .intro-desktop-home-page-master-container {
    position: relative;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    font-family: "DM Sans";

    background-color: #000;
  }

  .intro-desktop-home-page-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: "DM Sans";
    color: white;

    position: absolute;
    top: 0px;
    left: 0px;
  }

  .intro-desktop-walking-page-points-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    margin-top: 0px;
  }

  .intro-desktop-home-page-points-p1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
  }

  .home-page-desktop-heading {
    font-size: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .intro-desktop-home-page-header1 {
    font-size: 40px;
  }

  .walking-page-desktop-intro-form-contents-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    padding: 10px 30px;
  }

  .home-page-desktop-intro-form-contents {
    width: 100%;
    padding: 10px 30px;

    color: rgba(114, 114, 114, 1);
    font-size: 20px;
  }

  .grooming-page-form-bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .walking-page-desktop-intro-form-header {
    text-align: center;

    width: 100%;

    font-size: 25px;

    background-color: rgba(247, 248, 249, 1);
    border-radius: 20px 20px 0px 0px;
    color: rgba(88, 88, 88, 1);
    padding: 10px;
  }

  .walking-form-input-location {
    width: 400px;
    color: rgba(114, 114, 114, 1);
    height: 100%;
    border: 1px solid rgba(131, 145, 161, 0.5);
    border-radius: 10px;
    margin-top: 5px;
    padding: 5px 10px;
    text-align: left;

    background-color: #f7f8f9;
  }

  .walking-form-input-location::placeholder {
    color: rgba(131, 145, 161, 0.5);
    position: absolute;
    top: 10px;
  }

  .home-page-form-inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 110px;
  }

  /* .home-page-desktop-input-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  } */

  .home-page-form-grooming-upper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 100%;
  }

  .walking-page-desktop-intro-form-contents-lower {
    width: 100%;
    text-align: center;
    margin-top: 0px;

    color: #ff5e95;
  }

  .screen-height-manager-container {
  }
}

@media screen and (max-width: 1370px) {
  .home-page-desktop-intro-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    font-size: 22px;
    font-weight: 700;

    background-color: rgba(247, 248, 249, 1);
    border-radius: 20px 20px 0px 0px;
    color: rgba(88, 88, 88, 1);
  }
}

.social-icons,
.social-icons-mobile {
  display: flex;
  gap: 12px;
  margin-top: 15px;
}

.social-icons img {
  width: 37px;
  height: 37px;
}

.social-icons-mobile img {
  width: 21px;
  height: 21px;
}

.points_div{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin:12px 0;
  gap:5px;
}
.points_img{
  width:30px;
  height: 30px;
}
.point_box{
  display:flex;
  gap:4px;
  align-items: center;
  justify-content: flex-start;
}
.point_text{
  margin:0;
font-family: 'DM Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 27px;
color: #f3f3f3;
}

.styling-help-text{
  font-family: 'DM Sans';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
color: #848484;
text-align: center;
}
.pink-text{
  color:#FF5E95;
}
.top-stylist{
  font-family: 'DM Sans';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
text-align: center;
text-align: center;
color: #585858;
margin:0;
}
.slider-landing-page-mobile::-webkit-scrollbar,.home-packages::-webkit-scrollbar,.home-packages-desktop::-webkit-scrollbar{
  display: none;
}
@keyframes scroll-div1 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-15%); }
}

@keyframes scroll-div2 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-750%); }
}
@keyframes scroll-div3 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-300%); }
}

@keyframes scroll-div4 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-350%); }
}
@keyframes scroll-div5 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-300%); }
}

@keyframes scroll-div6 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-350%); }
}


.loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
    
}
/* Creating the dots */
.loader>span{
  height: 25px;
  width: 25px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: green;
  animation: loading 1s linear infinite;
}
/* Creating the loading animation*/
@keyframes loading {
  0%{
   transform: translateX(0);
  }
  25%{
   transform: translateX(15px);
  }
  50%{
   transform: translateX(-15px);
  }
  100%{
   transform: translateX(0);
  }
    
}
.loader>span:nth-child(1){
 animation-delay: 0.1s;
}
.loader>span:nth-child(2){
 animation-delay: 0.2s;
}
.loader>span:nth-child(3){
 animation-delay: 0.3s;
}
.loader>span:nth-child(4){
 animation-delay: 0.4s;
}
.loader>span:nth-child(5){
 animation-delay: 0.5s;
}

.training-page-mobile-view-package-container::-webkit-scrollbar{
  display: none;
}

.booksession-2 {
  -webkit-animation: linear;
  animation: linear;
  animation-duration: 3s;
  /* animation-iteration-count: 1; */
  animation-iteration-count: infinite;

  background: linear-gradient(
      132deg, #D6B75C 0%, #E8D078 39.06%, #FAEC95 63.09%, #D8BE6B 100%
    ),
    rgb(222,194,105);
  /* background: linear-gradient(127.2deg, #00FFBF -10.69%, #FFFFFF 102.32%); */
  padding: 5px 10px;
  /* height: 37.4305305480957px;
  width: 140px; */
  border-radius: 0px;
  outline: none;
  border: none;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;

  /* margin-top: 40px; */

  /* color: #fff; */

  box-shadow: 0px 3px 8px rgb(100, 100, 100);

  animation-name: shine;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  border-radius: 10px;

  width: 20rem;
  height: 48px;
}


@media (max-width: 1369px) {
  .grooming-new-form-section1-left-choice-price {
    font-size: 10px;
    padding-bottom: 4rem;
  }
  .grooming-new-form-section1-left-choice-name {
    font-size: 10px;
  }
  .selection-circle-desktop .dot{
    height: 3px;
  width: 3px;
  }
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
  width: 100%;
  background-color: rgba(247, 248, 249, 1);
  border: 2px solid #2b9348;
  border-radius: 5px;
  height: 40px;
}
.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}