.image{
    width: 100%;
    height: 100%;
}

.smallImage{
    width: 100%;
    height: 100%;
}

.smallImageContainer{
    background-color: #5E5E5E;
    height: 70vh;
}

.fullOverviewTitle{
    color: #5E5E5E;
    font-size: 1.4rem;
    margin-top: 10%;
}

.imageContainer{
}

.readAllContainer{
    width: 100%;

    display: flex;
    justify-content: center;

    position: relative;
    bottom: 70px;
}

.readMoreBtn{
    font-family: "DM Sans";
    font-size: 13px;
    
    background-color: white;
    color: black;
    text-align: center;

    position: relative;
    z-index: 100;

    width: 100px;
    height: 40px; 

    border-radius: 20px;
    padding-top: 10px;

}
.overview{
    font-family: "DM Sans";

    margin: 0% 7%;
}

.overviewTitle{
    color: #5E5E5E;
    font-size: 1.4rem;
}

.overviewDate{
    text-align: end;
    color: #5E5E5E;

    font-size: 0.7rem;
    padding: 10px 0px 15px 0px;
}

.overviewContent{
    color: #5E5E5E;
    font-size: 1rem;
}

.overviewArrow{
    color: black;
    margin-left: 5px;
}

.slider{
    display: flex;

    overflow-x: scroll;
    overflow-y: hidden;
    height: 240px;
}

.item{
    width: 90%;
    text-align: center;
    background-color: #F8F8F8;
}

.cardText{
    position: relative;
    bottom: 220px;
    left: 20px;

    font-family: "DM Sans";
    color: white;
    width: 70%;
    font-size: 20px;
    padding-left: 10px;
}

.similarBlogsContainer{
    margin-left: 7%;
    margin-top: 40px;

}

.similarBlogsHeading{
    font-family: "DM Sans";
    font-size: 1.5rem;
}

.underline{
    width: 133px;
    text-align: start;
    border: 1px solid #fe7798;
    margin-top: 10px;
    margin-bottom: 15px;
    color: #fe7798;
    background-color: #fe7798;

    margin-left: 10%;
}