@keyframes spinner{
    0%{
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loadingSpinner{
    border: 7px solid #FE7798;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border-top: 7px solid #ff3f6f;
    border-bottom: 7px solid #ff3f6f;
    animation: spinner 1.5s linear infinite;
}   